import React, { useEffect, useState } from 'react'
import "./sustainabilitydashboard.css"

import Select from 'react-select';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import CountUp from 'react-countup';

// Icons
import LeafIcon from '../../assets/icons/leaf.svg'
// import TreesSavedImg from '../../assets/icons/trees_saved_img.png'
import Co2HeaderIcon from '../../assets/icons/co2_header_icon.svg'
import SavingsForestImg from '../../assets/icons/savings_forest_img.png'
import PlantImg from '../../assets/icons/plant_img.png'
import TotalTripsIcon from '../../assets/icons/total_trips_icon.svg'
import TotalDistanceIcon from '../../assets/icons/total_distance_icon.svg'
import TotalEmissionIcon from '../../assets/icons/total_emission_icon.svg'
import TotalTreesIcon from '../../assets/icons/total_trees_icon.svg'
import RouteAnalyticsChart from './RouteAnalyticsChart'
import CO2EmissionChart from './CO2EmissionChart'
import EmissionTypesChart from './EmissionTypesChart'
// import AgeOfTrucksChart from './AgeOfTrucksChart'
import coEmissionImage from '../../assets/icons/GLEC_image.png'
import RailActiveIcon from '../../assets/icons/rail_icon_active.svg'
import RailInactiveIcon from '../../assets/icons/rail_icon_inactive.svg'
import RoadActiveIcon from '../../assets/icons/road_icon_active.svg'
import RoadInactiveIcon from '../../assets/icons/road_icon_inactive.svg'

var redirectURL = require('../redirectURL');
var moment = require('moment');

const SustainabilityDashboard = () => {
  const [selectedFilter, setSelectedFilter] = useState({ label: "Vehicle", value: 'vehicle_type_emissions' });
  // const [startDate, setStartDate] = useState(moment().startOf('month').format("YYYY-MM-DD"));
  const [startDate, setStartDate] = useState(moment().subtract(30, 'days').format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [show, setShow] = useState(false);
  const [basicTitle, setBasiTitle] = useState("");
  const [basicType, setBasicType] = useState("default");
  const [loadshow, setLoadShow] = useState("show-n");
  const [sustainabilityDashboardData, setSustainabilityDashboardData] = useState([]);
  const [RailResponseData, setRailResponseData] = useState([]);
  const [sustainabilityDashboardDataForCharts, setSustainabilityDashboardDataForCharts] = useState([]);
  const [co2EmissionData, setCO2EmissionData] = useState([]);
  const [railCO2EmissionData, setRailCO2EmissionData] = useState([]);
  const [emissionTypesData, setEmissionTypesData] = useState([]);
  const [emissionsByTruckAgeData, setEmissionsByTruckAgeData] = useState([]);
  const [treesSavedData, setTreesSavedData] = useState([]);
  const [railTreesSavedData, setRailTreesSavedData] = useState([]);
  const [lastUpdatedTime, setLastUpdatedTime] = useState("");
  const [startDateFromAPI, setStartDateFromAPI] = useState("");
  const [endDateFromAPI, setEndDateFromAPI] = useState("");
  const [shouldFetchData, setShouldFetchData] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [chartData, setChartData] = useState({
    categories: [],
    series: []
  });
  const [mode, setMode] = useState("ROAD"); // New state for mode of transport
  const [railCO2DashboardMonthWiseData, setRailCO2DashboardMonthWiseData] = useState([]); // New state for mode of transport
  const [filterOptions, setFilterOptions] = useState([
    {
      label: "Vehicle",
      value: "vehicle_type_emissions",
      default: true
    },
    {
      label: "Fuel",
      value: "fuel_type_emissions"
    },
    {
      label: "Region",
      value: "region_emissions"
    },
    {
      label: "Plant",
      value: "plant_emissions"
    }
  ])

  // Runs when component mounts
  useEffect(() => {
    fetchData();
  }, []);

  // Runs when start and end date gets reset and shouldFetchData is true
  useEffect(() => {
    if (shouldFetchData) {
      fetchData();
      setShouldFetchData(false); // Reset to false after data is fetched
    }
  }, [shouldFetchData]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      setLoadShow("show-m");

      // Run all API calls concurrently using Promise.all
      await Promise.all([
        getSustainabilityDashboardData(),
        getTotalEmissionsCalculations(),
        getEmissionsBasedOnVehicleType(),
        getRailCO2DashboardMonthWiseData(),
        getRailEmissionsSaved(),
        // getEmissionsByTrucksAge(),
        getTreesSavedCount()
      ]);

      const time = moment(new Date(), "hh:mm A").format("hh:mm A");
      // console.log("Time: ", time);
      setLastUpdatedTime(time);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
      setLoadShow("show-n");
    }
  };

  // Code for updating filter options on mode change
  useEffect(() => {
    const roadFilters = [
      { label: "Vehicle", value: "vehicle_type_emissions", default: true },
      { label: "Fuel", value: "fuel_type_emissions" },
      { label: "Region", value: "region_emissions" },
      { label: "Source", value: "plant_emissions" },
      { label: "CO2 Emission", value: "emission_last_6months" }
    ];
  
    const railFilters = [
      { label: "Region", value: "region_emissions", default: true },
      { label: "Source", value: "plant_emissions" },
      { label: "CO2 Emission", value: "rail_emission_last_6months" }
    ];
  
    if (mode === "ROAD") {
      setFilterOptions(roadFilters);
      setSelectedFilter({ label: "CO2 Emission", value: 'emission_last_6months' });
    } else if (mode === "RAIL") {
      setFilterOptions(railFilters);
      setSelectedFilter({ label: "CO2 Emission", value: 'rail_emission_last_6months' });
    }
  }, [mode]);


  // Code for Emission Type chart data
  useEffect(() => {
    if (selectedFilter && selectedFilter.value) {
     
      const filteredData = emissionTypesData[selectedFilter.value];

      // console.log("Filtered Data: ", filteredData);

      if (filteredData && filteredData.categories && filteredData.series) {
        setChartData({
          categories: filteredData.categories,
          series: filteredData.series
        });
      }
    }
  }, [selectedFilter, emissionTypesData]);

  const getSustainabilityDashboardData = async () => {
    const params = {
      start_date: startDate,
      end_date: endDate
    }
    await redirectURL.post("/dashboard/getSustainabilityDashboardData", params).then(response => {
      // console.log("Response: ", response.data);
      if (response && response.status && response.status === 200 && response.data && response.data.data) {
        const originalData = JSON.parse(response.data.data);

        const transformedData = {
          categories: ["Trips Completed", "Distance Travelled (km)", "CO<sub>2</sub> emitted"],
          series: [
            {
              name: "With Optimized Routes",
              data: [
                originalData.Trips_Completed_with_optimized_routes,
                originalData.Distance_Travelled_kms_with_optimized_routes,
                originalData.co2_emitted_with_optimized_routes
              ]
            },
            {
              name: "Without Optimized Routes",
              data: [
                originalData.Trips_Completed_without_optimized_routes,
                originalData.Distance_Travelled_kms_without_optimized_routes,
                originalData.co2_emitted_without_optimized_routes
              ]
            }
          ]
        };
        // console.log("Formatted Data: ", transformedData);
        setSustainabilityDashboardData(originalData);
        setSustainabilityDashboardDataForCharts(transformedData);
        if (originalData.start_date && originalData.end_date) {
          setStartDateFromAPI(originalData.start_date);
          setEndDateFromAPI(originalData.end_date);
        }
      }
    })
  }

  const getTotalEmissionsCalculations = async () => {
    const data = await getSustainabilityDashboardCounter("emission_last_6months");
    const railData = await getSustainabilityDashboardCounter("rail_emission_last_6months");
    console.log("CO2 Data: ", data);
    // Helper function to safely extract and format data based on key
    const formatData = (response, dataKey) => {
      if (response && response[dataKey]) {
        const data = response[dataKey];
        return {
          categories: Object.keys(data),
          series: [
            {
              name: "Emissions",
              data: Object.values(data)
            }
          ]
        };
      } else {
        console.error(`Data missing or malformed for ${dataKey}`);
        return { categories: [], series: [] };
      }
    };

    const CO2Emissions = formatData(data, "month_emissions");
    const railCO2Emissions = formatData(railData, "month_emissions");

    setCO2EmissionData(CO2Emissions);
    setRailCO2EmissionData(railCO2Emissions);


    // const params = {
    //   start_date: startDate,
    //   end_date: endDate
    // }
    // await redirectURL.post("/dashboard/getTotalEmissionsCalculations", params).then(response => {
    //   // console.log("Response: ", response.data);
    //   if (response && response.status && response.status === 200 && response.data && response.data.data) {
    //     // console.log("Response: ", JSON.parse(response.data.data))
    //     setCO2EmissionData(JSON.parse(response.data.data))
    //   }
    // })
  }

  const getTreesSavedCount = async () => {
    const data = await getSustainabilityDashboardCounter("trees_saved");
    const railData = await getSustainabilityDashboardCounter("rail_trees_saved");

    if (data && data.total_tress_saved) {
      setTreesSavedData(parseFloat(data.total_tress_saved))
    }

    if (railData && railData.total_tress_saved) {
      setRailTreesSavedData(parseFloat(railData.total_tress_saved))
    }
    
    // const params = {
    //   start_date: startDate,
    //   end_date: endDate
    // }
    // await redirectURL.post("/dashboard/getTreesSavedCount", params).then(response => {
    //   // console.log("Response: ", response.data);
    //   if (response && response.status && response.status === 200 && response.data && response.data.data) {
    //     // console.log("Response: ", JSON.parse(response.data.data))
    //     const treesCount = JSON.parse(response.data.data);
    //     setTreesSavedData(parseInt(treesCount));
    //   }
    // })
  }

  const getEmissionsBasedOnVehicleType = async () => {
    try {
      // Fetch data from all APIs
      const vehicleResponse = await getSustainabilityDashboardCounter("emission_vehicle_types");
      const fuelResponse = await getSustainabilityDashboardCounter("emission_fuel_types");
      const regionResponse = await getSustainabilityDashboardCounter("emission_region_types");
      const plantResponse = await getSustainabilityDashboardCounter("emission_plant_types");
      const railPlantResponse = await getSustainabilityDashboardCounter("rail_plant_wise_emissions");
      const railRegionResponse = await getSustainabilityDashboardCounter("rail_region_wise_emissions");
      const fuel_Co2Emission = await getSustainabilityDashboardCounter("emission_last_6months");
      const rail_Co2Emission = await getSustainabilityDashboardCounter("rail_emission_last_6months");
      // Helper function to safely extract and format data based on key
      const formatData = (response, dataKey) => {
        if (response && response[dataKey]) {
          const data = response[dataKey];
          return {
            categories: Object.keys(data),
            series: [
              {
                name: "Emissions",
                data: Object.values(data)
              }
            ]
          };
        } else {
          console.error(`Data missing or malformed for ${dataKey}`);
          return { categories: [], series: [] };
        }
      };

      // Format data for each emission type based on its specific key
      const vehicleTypeEmissions = formatData(vehicleResponse, "vehicle_emissions");
      const fuelTypeEmissions = formatData(fuelResponse, "fuel_type_emissions");
      const regionEmissions = formatData(regionResponse, "region_wise_emissions");
      const plantEmissions = formatData(plantResponse, "plant_wise_emissions");
      const railPlantEmissions = formatData(railPlantResponse, "plant_wise_emissions");
      const railRegionEmissions = formatData(railRegionResponse, "region_wise_emissions");
      const fuelCo2Emission = formatData(fuel_Co2Emission, "month_emissions");
      const railCo2Emission = formatData(rail_Co2Emission, "month_emissions");

      // console.log("Formatted Data: ", vehicleTypeEmissions);

      // Combine all formatted data into a single object
      const formattedData = {
        fuel_type_emissions: fuelTypeEmissions,
        vehicle_type_emissions: vehicleTypeEmissions,
        region_emissions: regionEmissions,
        plant_emissions: plantEmissions,
        rail_plant_emissions: railPlantEmissions,
        rail_region_emissions: railRegionEmissions,
        emission_last_6months: fuelCo2Emission,
        rail_emission_last_6months: railCo2Emission
      };

      setEmissionTypesData(formattedData);
    } catch (error) {
      console.error("Error fetching and formatting emission data:", error);
    }

    // const params = {
    //   start_date: startDate,
    //   end_date: endDate
    // }
    // await redirectURL.post("/dashboard/getEmissionsBasedOnVehicleType", params).then(response => {
    //   if (response && response.status && response.status === 200 && response.data && response.data.data) {
    //     const originalData = JSON.parse(response.data.data);
    //     console.log("Original Data: ", originalData);
    //     setEmissionTypesData(originalData);
    //   }
    // })
  }

  const getEmissionsByTrucksAge = async () => {
    const params = {
      start_date: startDate,
      end_date: endDate
    }
    await redirectURL.post("/dashboard/getEmissionsByTrucksAge", params).then(response => {
      if (response && response.status && response.status === 200 && response.data && response.data.data) {
        setEmissionsByTruckAgeData(JSON.parse(response.data.data))
      }
    })
  }

  const handlerStartDateTime = (event, currentDate, selectedDate) => {
    const startDate = moment(event._d).format("YYYY-MM-DD");
    // console.log(startDate, 'startDate');

    if (endDate && endDate !== "") {
      const startDateObj = moment(startDate);
      const endDateObj = moment(endDate);

      if (startDateObj.isAfter(endDateObj)) {
        // Handle invalid date range (startDate is after endDate)
        setShow(true);
        setBasiTitle("To Date should be greater than From Date");
        setBasicType("danger");
        setLoadShow("show-n");
      } else {
        setStartDate(startDate);
      }
    } else {
      setStartDate(startDate);
    }
  }

  const handlerEndDateTime = (event, currentDate, selectedDate) => {
    const endDate = moment(event._d).format("YYYY-MM-DD");
    // console.log(endDate, 'endDate');

    if (startDate && startDate !== "") {
      const startDateObj = moment(startDate);
      const endDateObj = moment(endDate);

      if (startDateObj.isAfter(endDateObj)) {
        // Handle invalid date range (startDate is after endDate)
        setShow(true);
        setBasiTitle("To Date should be greater than From Date");
        setBasicType("danger");
        setLoadShow("show-n");
      } else {
        setEndDate(endDate);
      }
    } else {
      setEndDate(endDate);
    }
  }

  const onClickGetData = () => {
    if (startDate && endDate) {
      // fetchData();
      setShouldFetchData(true);
    }
  }

  const onClickResetFilters = () => {
    // console.log("Reset Clicked");
    // setStartDate(moment().startOf('month').format("YYYY-MM-DD"));
    setStartDate(moment().subtract(30, 'days').format("YYYY-MM-DD"));
    setEndDate(moment().format("YYYY-MM-DD"));

    setShouldFetchData(true);
  }

  const closeAlert = () => {
    setShow(false);
  }

  const getCurrentFinancialYear = () => {
    const currentDate = moment();
    const startYear = currentDate.month() >= 3 ? currentDate.year() : currentDate.year() - 1;
    const endYear = startYear + 1;

    const startOfFinancialYear = moment(`${startYear}-04-01`);
    const endOfFinancialYear = moment(`${endYear}-03-31`);

    return { startOfFinancialYear, endOfFinancialYear };
  };

  const { startOfFinancialYear, endOfFinancialYear } = getCurrentFinancialYear();

  const getFYText = () => {
    const currentYear = moment().year();
    const nextYear = currentYear + 1;
    return `FY ${currentYear.toString().slice(-2)}-${nextYear.toString().slice(-2)}`;
  }

  // Format function for y values
  const safeToLocalString = (value) => {
    const val = Number(value);
    return val != null ? val.toLocaleString('en-IN') : 0;
  }

  // Function to check if a value is numeric
  const isNumeric = (value) => {
    return !isNaN(parseFloat(value)) && isFinite(value);
  };

  const getSustainabilityDashboardCounter = async (counter) => {
    const params = {
      fy_year: "24-25",
      counter: counter,
    };

    try {
      const response = await redirectURL.post("/dashboard/getSustainabilityDashboardCounter", params);
      if (response && response.status === 200 && response.data) {
        const dashboardData = response.data[0];
        // console.log("Dashboard Data: ", dashboardData);
        return dashboardData; // Return the data directly
      } else {
        // Handle errors or empty response
        console.error("Error fetching data or empty response");
        return null; // Or return a default value
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      return null; // Or return a default value
    }
  };

  const getRailCO2DashboardMonthWiseData = async () => {
    const params = {
      start_date: startDate,
      end_date: endDate
    }
    await redirectURL.post("/dashboard/getRailCO2DashboardMonthWiseData", params).then(response => {
      // console.log("Response: ", response.data);
      if (response && response.status && response.status === 200 && response.data && response.data.data) {
        // console.log("Response: ", JSON.parse(response.data.data))
        const railCO2DashboardMonthWiseData = JSON.parse(response.data.data);
        // console.log("RAIL Dashboard: ", railCO2DashboardMonthWiseData);
        setRailCO2DashboardMonthWiseData(railCO2DashboardMonthWiseData);
      }
    })
  }

const getRailEmissionsSaved = async () => {
    const params = {
      start_date: startDate,
      end_date: endDate
    }
    await redirectURL.post("/dashboard/railEmissionsSaved", params).then(response => {
      console.log("Response: ", response.data);
      if (response && response.status && response.status === 200 && response.data && response.data.data) {
        // console.log("Response: ", JSON.parse(response.data.data))
        const RailResponseData = JSON.parse(response.data.data);
        console.log("RAIL Dashboard: ", RailResponseData);
        setRailResponseData(RailResponseData);
      }
    })
  }
  
  // Handler function for mode tabs
  const handleModeClick = (mode) => {
    setMode(mode)
    getEmissionsBasedOnVehicleType();
    getRailCO2DashboardMonthWiseData();
    getRailEmissionsSaved();
  }

  // Centralized object to map each mode's data source
  const dataMapping = {
    ROAD: {
      Total_Trips: sustainabilityDashboardData && sustainabilityDashboardData.Total_Trips ? sustainabilityDashboardData.Total_Trips : 0,
      Total_Distance: sustainabilityDashboardData && sustainabilityDashboardData.Total_Distance ? sustainabilityDashboardData.Total_Distance : 0,
      Total_Emissions: sustainabilityDashboardData && sustainabilityDashboardData.Total_Emissions ? sustainabilityDashboardData.Total_Emissions : 0,
      Trees_Saved: treesSavedData || 0,
    },
    RAIL: {
      Total_Trips: railCO2DashboardMonthWiseData && railCO2DashboardMonthWiseData.Rail_Total_Trips ? railCO2DashboardMonthWiseData.Rail_Total_Trips : 0,
      Total_Distance: railCO2DashboardMonthWiseData && railCO2DashboardMonthWiseData.Rail_Total_Distance ? railCO2DashboardMonthWiseData.Rail_Total_Distance : 0,
      Total_Emissions: railCO2DashboardMonthWiseData && railCO2DashboardMonthWiseData.Rail_Total_Emissions_kgs ? railCO2DashboardMonthWiseData.Rail_Total_Emissions_kgs : 0,
      Trees_Saved: railTreesSavedData || 0,
    },
    // Add more modes like AIR and SEA here
  };

  // Select data based on the current mode
  const selectedData = dataMapping[mode] || dataMapping.ROAD;

  let emissionsData;
  let co2EmissionChartData;
  let sourceWiseEmission;

  console.log("Emission Types: ", emissionTypesData)

  switch (mode) {
    case "ROAD":
      emissionsData = emissionTypesData["region_emissions"];
      co2EmissionChartData = emissionTypesData["emission_last_6months"];
      sourceWiseEmission = emissionTypesData["plant_emissions"];
      break;
    case "RAIL":
      emissionsData = emissionTypesData["rail_region_emissions"];
      co2EmissionChartData = emissionTypesData["rail_emission_last_6months"];
      sourceWiseEmission = emissionTypesData["rail_plant_emissions"];
      break;
    // Add more cases for additional modes as needed
    default:
      emissionsData = null;
      co2EmissionChartData = null;
      sourceWiseEmission = null;
      break;
  }

  console.log("Emission Data: ", emissionsData)

  return (
    <>
      <div className={"dataLoadpage " + (loadshow)}>
      </div>
      <div className={"dataLoadpageimg " + (loadshow)} style={{ top: "20%" }}>
        <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
      </div>
      <SweetAlert
        show={show}
        type={basicType}
        title={basicTitle}
        onConfirm={closeAlert}
      >
      </SweetAlert>
      <div className="sustainability_dashboard_header">
        <div className="sustainability_dashboard_header_left">
          <img style={{ width: '90px' }} src={coEmissionImage} alt="" />
          <h1>CO<sub>2</sub> Emission Sustainability</h1>
          <img src={Co2HeaderIcon} alt="" />
        </div>
        <div className="sustainability_dashboard_header_right">
          <div className="sustainability_last_updated"><p>Last Updated: {lastUpdatedTime}</p></div>
        </div>
      </div>
      <div className="d-flex mb-3">
        <div className="btn-group" role="group" aria-label="Mode Switcher">
          <button
            type="button"
            className={`btn btn-lg btn-outline-green ${mode === "ROAD" ? "active" : ""}`}
            onClick={() => handleModeClick("ROAD")}
          >
            <img src={mode === "ROAD" ? RoadActiveIcon : RoadInactiveIcon} alt="" style={{ marginRight: "5px", width: "20px" }} />
            Road
          </button>
          <button
            type="button"
            className={`btn btn-lg btn-outline-green ${mode === "RAIL" ? "active" : ""}`}
            onClick={() => handleModeClick("RAIL")}
          >
            <img src={mode === "RAIL" ? RailActiveIcon : RailInactiveIcon} alt="" style={{ marginRight: "5px", width: "24px" }} />
            Rail
          </button>
        </div>
      </div>
      <div className="sustainability_date_picker_container">
        <div className="row col-xl-7 col-lg-7">
          <div className=" card">
            <div className="row card-body" style={{ padding: "10px 30px" }}>
              <div className="col-xl-6 col-lg-6 form-group">
                <label>From Date</label>
                <Datetime value={startDate}
                  disableCloseOnClickOutside={false}
                  closeOnSelect={true}
                  inputProps={{ placeholder: 'From Date', name: 'startDate', autoComplete: 'off', readOnly: true, required: true }}
                  dateFormat="YYYY-MM-DD"
                  name="startDate"
                  onChange={handlerStartDateTime}
                  timeFormat={false}
                  isValidDate={current => {
                    return current.isSameOrAfter(startOfFinancialYear, 'day') && current.isSameOrBefore(endOfFinancialYear, 'day');
                  }}
                // isValidDate={current => {
                //   return current.isSameOrBefore(moment(), 'day');
                // }} 
                />
              </div>

              <div className="col-xl-6 col-lg-6 form-group">
                <label>To Date</label>
                <Datetime value={endDate}
                  disableCloseOnClickOutside={false}
                  closeOnSelect={true}
                  inputProps={{ placeholder: 'To Date', name: 'endDate', autoComplete: 'off', readOnly: true, required: true }}
                  dateFormat="YYYY-MM-DD"
                  onChange={handlerEndDateTime}
                  timeFormat={false}
                  isValidDate={current => {
                    return current.isSameOrAfter(startOfFinancialYear, 'day') && current.isSameOrBefore(endOfFinancialYear, 'day');
                  }}
                // isValidDate={current => {
                //   return current.isSameOrBefore(moment(), 'day');
                // }}
                />
              </div>
              <div className="form-group col-xl-12 col-lg-12" style={{ textAlign: "left", display: 'flex' }}>
                <div>
                  <button type="button" style={{ margin: "0 auto" }} className="btn btn-success" disabled={false} onClick={onClickGetData}>Submit</button>
                  &nbsp;<button type="button" style={{ margin: "0 auto" }} className="btn btn-info" disabled={false} onClick={onClickResetFilters}>Reset</button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div className="container-fluid card" style={{ padding: "20px", gap: "20px", borderRadius: "7px" }}>
        <div className="emission_sustainability_row_1">
          <div className="emission_data_container">
            <div className="emission_data_header">
              <div className="emission_data_text">
                <h6>Emission</h6>
                {/* <p>Current Month</p> */}
              </div>
              <div className="emission_data_img">
                <img src={LeafIcon} alt="Leaf-Icon" />
              </div>
              {(startDateFromAPI && endDateFromAPI) && <span className="date-range-specifier">{`( ${moment(startDateFromAPI).format("DD/MM/YY")} - ${moment(endDateFromAPI).format("DD/MM/YY")} )`}</span>}
            </div>
            <div className="emission_data_cards">
              <div className="emission_data_card">
                <div className="emission_data_card_icon">
                  <img src={TotalTripsIcon} alt="Total Trips Icon" />
                </div>
                <h6 className="total_trips_count">{isNumeric(selectedData.Total_Trips) ?
                  safeToLocalString(selectedData.Total_Trips) :
                  0
                }</h6>
                {/* <h6 className="total_trips_count"><CountUp end={(sustainabilityDashboardData.Total_Trips) ? sustainabilityDashboardData.Total_Trips : 0} /></h6> */}
                <p className="total_trips_title">Total Trips</p>
              </div>
              <div className="emission_data_card">
                <div className="emission_data_card_icon">
                  <img src={TotalDistanceIcon} alt="Total Distance Icon" />
                </div>
                <h6 className="total_distance_count">{isNumeric(selectedData.Total_Distance) ?
                  safeToLocalString(selectedData.Total_Distance) :
                  0
                } <span>KM</span></h6>
                {/* <h6 className="total_distance_count"><CountUp end={(sustainabilityDashboardData.Total_Distance) ? sustainabilityDashboardData.Total_Distance : 0} /> <span>km</span></h6> */}
                <p className="total_distance_title">Total Distance</p>
              </div>
              <div className="emission_data_card">
                <div className="emission_data_card_icon">
                  <img src={TotalEmissionIcon} alt="Total Emission Icon" />
                </div>
                {/* <h6 className="total_trips_count">{sustainabilityDashboardData.Total_Emissions || 0}</h6> */}
                <div className="emission_data_row">
                  <h6 className="total_emission_count">{isNumeric(selectedData.Total_Emissions) ?
                    safeToLocalString(selectedData.Total_Emissions) :
                    0
                  }</h6>
                  {/* <h6 className="total_emission_count"><CountUp end={(sustainabilityDashboardData.Total_Emissions) ? sustainabilityDashboardData.Total_Emissions : 0} /></h6> */}
                  <p className="total_emission_subtitle" style={{ color: "#3F3F40", fontStyle: "italic", fontSize: "0.75vw" }}>MTCO<sub>2</sub>e</p>
                </div>
                <p className="total_emission_title">Total Emission</p>
              </div>
              <div className="emission_data_card">
                <div className="emission_data_card_icon">
                  <img src={TotalTreesIcon} alt="Total Trees Icon" />
                </div>
                <h6 className="total_trees_count">{isNumeric(selectedData.Trees_Saved) ?
                  safeToLocalString(selectedData.Trees_Saved) :
                  0
                }</h6>
                {/* <h6 className="total_trees_count"><CountUp end={(treesSavedData) ? treesSavedData : 0} /></h6> */}
                <p className="total_trees_title">Trees Saved {getFYText()}</p>
              </div>
            </div>
          </div>
          {/* <div className="trees_saved_container">
            <div className="trees_saved_img">
              <img src={PlantImg} alt="" />
            </div>
            <div className="trees_saved_text">
              <h6 className="trees_saved_count">{parseInt(treesSavedData) || ""}</h6>
              <p className="trees_saved_title">Trees Saved</p>
              <p className="trees_saved_subtitle">FY 24-25</p>
            </div>
          </div> */}
        </div>
        <div className="emission_sustainability_row_2">
          <div className="emission_sustainability_row_2_left">
            <div className="total_savings_container">
              <div className="total_savings_header">
                {/* <h6 className="total_savings_title">Total Savings <span className="date-range-specifier">{`( ${moment(startDate).format("DD/MM/YY")} - ${moment(endDate).format("DD/MM/YY")} )`}</span></h6> */}
                <h6 className="total_savings_title">Total Savings {(startDateFromAPI && endDateFromAPI) && <span className="date-range-specifier">{`( ${moment(startDateFromAPI).format("DD/MM/YY")} - ${moment(endDateFromAPI).format("DD/MM/YY")} )`}</span>}</h6>
                {mode === "ROAD" && <p className="total_savings_subtitle">By CNG Trips</p>}
              </div>
              <div className="total_savings_row_1">
              {mode === "ROAD" ? 
              (
                <>
              <div className="total_savings_trips">
              <p className="total_savings_trips_count">{isNumeric(sustainabilityDashboardData.Total_trips_CNG) ?
                safeToLocalString(sustainabilityDashboardData.Total_trips_CNG) :
                0
              }</p>
             
             
              <p className="total_savings_trips_text">Total Trips</p>
            </div>
            <div className="total_savings_distance">
              <p className="total_savings_distance_count">
                {isNumeric(sustainabilityDashboardData.Total_CNG_Trips_Distance) ?
                  safeToLocalString(sustainabilityDashboardData.Total_CNG_Trips_Distance) :
                  0
                } <span>km</span>
              </p>
              <p className="total_savings_distance_text">Total Distance</p>
            </div> 
            </>
             ) : 
             ( <>
             <div style={{display:'flex',flexDirection:'row',width:"100%",justifyContent:'space-between'}}> 
              <div>
              <div className="total_savings_trips">
                  <p className="total_savings_trips_count">{isNumeric(RailResponseData.Trailer_needed) ?
                    safeToLocalString(RailResponseData.Trailer_needed) :
                    0
                  }</p>
                  <p className="total_savings_trips_text">Road Total Trips</p>
                </div>
               
               
                <div className="total_savings_trips">
                  <p className="total_savings_trips_count">{isNumeric(RailResponseData.rail_total_trips) ?
                    safeToLocalString(RailResponseData.rail_total_trips) :
                    0
                  }</p>
                  <p className="total_savings_trips_text">Rail Total Trips</p>
                </div>
                </div>
                <div>
                <div className="total_savings_distance">
                  <p className="total_savings_distance_count">
                    {isNumeric(RailResponseData.total_road_distance_if_transpoted_by_road) ?
                      safeToLocalString(RailResponseData.total_road_distance_if_transpoted_by_road) :
                      0
                    } <span>km</span>
                  </p>
                  <p className="total_savings_distance_text">Road Total Distance</p>
                </div>
                <div className="total_savings_distance">
                  <p className="total_savings_distance_count">
                    {isNumeric(RailResponseData.Rail_Total_Distance) ?
                      safeToLocalString(RailResponseData.Rail_Total_Distance) :
                      0
                    } <span>km</span>
                  </p>
                  <p className="total_savings_distance_text">Rail Total Distance</p>
                </div>
                </div>
                </div>
                </>
                )

                  }
                
              </div>
              <div className="total_savings_row_2">
                <div className="total_savings_diesel">
                {mode === "ROAD" ? (
                    <>
                      <p className="total_savings_diesel_title">Diesel</p>
                      <p className="total_savings_diesel_count">
                        {isNumeric(sustainabilityDashboardData.Total_co2_emission_by_DIESEL) ? 
                          safeToLocalString(sustainabilityDashboardData.Total_co2_emission_by_DIESEL) : 
                          0
                        } <span>MTCO<sub>2</sub>e</span>
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="total_savings_diesel_title">If Transported By Road</p>
                      <p className="total_savings_diesel_count">
                        {isNumeric(RailResponseData.total_roadco2_emission_MTCO2) ? 
                          safeToLocalString(RailResponseData.total_roadco2_emission_MTCO2) : 
                          0
                        } <span>MTCO<sub>2</sub>e</span>
                      </p>
                    </>
                  )}
                  <p className="total_savings_diesel_text">CO<sub>2</sub> Emitted</p>
                </div>
                <div className="total_savings_cng">
                  {mode === "ROAD" ? (
                  <>
                  <p className="total_savings_cng_title">CNG</p> 
                  <p className="total_savings_cng_count">{isNumeric(sustainabilityDashboardData.Total_co2_emission_by_CNG) ?
                    safeToLocalString(sustainabilityDashboardData.Total_co2_emission_by_CNG) :
                    0
                  } <span>MTCO<sub>2</sub>e</span></p>
                  </>
                  )
                  :(
                    <>
                   <p className="total_savings_cng_title">Rail</p>
                  <p className="total_savings_cng_count">{isNumeric(RailResponseData.total_railco2_emission_MTCO2) ?
                    safeToLocalString(RailResponseData.total_railco2_emission_MTCO2) :
                    0
                  } <span>MTCO<sub>2</sub>e</span></p>
                  
                  <p className="total_savings_cng_text">CO<sub>2</sub> Emitted</p>
                  </>
                )}
                </div>
              </div>
              <div className="total_savings_saved">
                <div className="total_savings_img">
                  <img src={SavingsForestImg} alt="" />
                </div>
                <div className="total_savings_text">
                  {/* <h6>Saved <span>{parseInt(sustainabilityDashboardData.Total_co2_emission_by_DIESEL - sustainabilityDashboardData.Total_co2_emission_by_CNG) || 0}</span></h6> */}
                  {mode === "ROAD" ? 
                  <h6>Saved <span>{isNumeric(sustainabilityDashboardData.Total_co2_emission_savings) ?
                    safeToLocalString(sustainabilityDashboardData.Total_co2_emission_savings) :
                    0
                  }</span></h6> 
                :
                <h6>Saved <span>{isNumeric(RailResponseData.rail_co2_saved) ?
                  safeToLocalString(RailResponseData.rail_co2_saved) :
                  0
                }</span></h6> 
                }
                  {/* <h6>Saved <span>{sustainabilityDashboardData.Total_co2_emission_savings || 0}</span></h6> */}
                  <p>MTCO<sub>2</sub>e</p>
                </div>
              </div>
            </div>
            <div className="co2_emission_container">
             
             <div className="card">
                <div className="card-body" style={{ padding: "20px" }}>
                  <div className="co2_emission_header">
                    <h6>CO<sub>2</sub> Emission <span className="date-range-specifier">{`( Last 6 months )`}</span></h6>
                  </div>
                  {co2EmissionChartData ? (
                  <CO2EmissionChart
                  categories={co2EmissionChartData.categories || []}
                  yAxis={"MTCO<sub>2</sub>e"}
                  series={co2EmissionChartData.series || []}
                />
                ):
               ( <CO2EmissionChart
                  categories={[]}
                  yAxis={"MTCO<sub>2</sub>e"}
                  series={[]}
                />)
                }
                </div>
              </div> 
            </div>
          </div>
          <div className="emission_sustainability_row_2_right">
            {
              mode === "ROAD" ? 
              <div className="route_analysis_container">
              <div className="route_analysis_header">
                <h6>Route Analysis {(startDateFromAPI && endDateFromAPI) && <span className="date-range-specifier">{`( ${moment(startDateFromAPI).format("DD/MM/YY")} - ${moment(endDateFromAPI).format("DD/MM/YY")} )`}</span>}</h6>
              </div>
              <RouteAnalyticsChart
                categories={['Trips Completed', 'Distance Travelled (km)', 'CO<sub>2</sub> emitted (MTCO<sub>2</sub>e)'] || []}
                yAxis={""}
                series={sustainabilityDashboardDataForCharts.series || []}
              // onColumnClick={handleColumnClick}
              />
            </div>
             : 
             <div className="emission_types_container">
                  <div className="emission_types_header">
                    <h6>Region-wise Emission <span className="date-range-specifier">{`( For ${getFYText()} )`}</span></h6>
                  </div>
                  {emissionsData ? (
                    <EmissionTypesChart
                      categories={emissionsData.categories || []}
                      yAxis={"MTCO<sub>2</sub>e"}
                      series={emissionsData.series || []}
                    />
                  ) : (
                    <EmissionTypesChart
                      categories={[]}
                      yAxis={"MTCO<sub>2</sub>e"}
                      series={[]}
                    /> // Placeholder until data is available
                  )}
                </div>
            }
          
            {
              mode === "ROAD" ? <div className="emission_types_container">
                <div className="emission_types_header">
                  <h6>Vehicle Types Emission <span className="date-range-specifier">{`( For ${getFYText()} )`}</span></h6>
                </div>
                {emissionTypesData["vehicle_type_emissions"] ? (
                  <EmissionTypesChart
                    categories={emissionTypesData["vehicle_type_emissions"].categories || []}
                    yAxis={"MTCO<sub>2</sub>e"}
                    series={emissionTypesData["vehicle_type_emissions"].series || []}
                  />
                ) : (
                  <EmissionTypesChart
                    categories={[]}
                    yAxis={"MTCO<sub>2</sub>e"}
                    series={[]}
                  /> // Placeholder until data is available
                )}
              </div>
                : ""
              }
              {
                mode === "ROAD" ?
                <div className="emission_types_container">
                  <div className="emission_types_header">
                    <h6>Region-wise Emission <span className="date-range-specifier">{`( For ${getFYText()} )`}</span></h6>
                  </div>
                  {emissionsData ? (
                    <EmissionTypesChart
                      categories={emissionsData.categories || []}
                      yAxis={"MTCO<sub>2</sub>e"}
                      series={emissionsData.series || []}
                    />
                  ) : (
                    <EmissionTypesChart
                      categories={[]}
                      yAxis={"MTCO<sub>2</sub>e"}
                      series={[]}
                    /> // Placeholder until data is available
                  )}
                </div>
                :''
              }
             {mode === "RAIL" ? 
              <div className="emission_types_container">
              <div className="emission_types_header">
                <h6>Source-wise Emission <span className="date-range-specifier">{`( For ${getFYText()} )`}</span></h6>
              </div>
              {sourceWiseEmission ? (
                <EmissionTypesChart
                  categories={sourceWiseEmission.categories || []}
                  yAxis={"MTCO<sub>2</sub>e"}
                  series={sourceWiseEmission.series || []}
                />
              ) : (
                <EmissionTypesChart
                  categories={[]}
                  yAxis={"MTCO<sub>2</sub>e"}
                  series={[]}
                /> // Placeholder until data is available
              )}
            </div>
            :''
             } 
            
          </div>
        </div>
        {mode === "ROAD" && <div className="emission_sustainability_row_2_left">
          <div className="emission_types_container">
            <div className="emission_types_header">
              <h6>Fuel Types Emission <span className="date-range-specifier">{`( For ${getFYText()} )`}</span></h6>
            </div>
            {emissionTypesData["fuel_type_emissions"] ? (
              <EmissionTypesChart
                categories={emissionTypesData["fuel_type_emissions"].categories || []}
                yAxis={"MTCO<sub>2</sub>e"}
                series={emissionTypesData["fuel_type_emissions"].series || []}
              />
            ) : (
              <EmissionTypesChart
                categories={[]}
                yAxis={"MTCO<sub>2</sub>e"}
                series={[]}
              /> // Placeholder until data is available
            )}
          </div>
          {/* <div className="emission_types_container">
            <div className="emission_types_header">
              <h6>Region-wise Emission <span className="date-range-specifier">{`( For ${getFYText()} )`}</span></h6>
            </div>
            {emissionTypesData["region_emissions"] ? (
              <EmissionTypesChart
                categories={emissionTypesData["region_emissions"].categories || []}
                yAxis={"MTCO<sub>2</sub>e"}
                series={emissionTypesData["region_emissions"].series || []}
              />
            ) : (
              <EmissionTypesChart
                categories={[]}
                yAxis={"MTCO<sub>2</sub>e"}
                series={[]}
              /> // Placeholder until data is available
            )}
          </div> */}
          {/* {mode === "RAIL" ?
          <div className="emission_sustainability_row_2_right">
          <div className="emission_types_container">
            <div className="emission_types_header">
              <h6>Source-wise Emission <span className="date-range-specifier">{`( For ${getFYText()} )`}</span></h6>
            </div>
            {sourceWiseEmission ? (
              <EmissionTypesChart
                categories={sourceWiseEmission.categories || []}
                yAxis={"MTCO<sub>2</sub>e"}
                series={sourceWiseEmission.series || []}
              />
            ) : (
              <EmissionTypesChart
                categories={[]}
                yAxis={"MTCO<sub>2</sub>e"}
                series={[]}
              /> // Placeholder until data is available
            )}
          </div>
        </div>
        :''
          }  */}
        </div>
        }
        {mode == "ROAD" ?
         <div className="emission_sustainability_row_3">
         <div className="emission_types_container">
           <div className="emission_types_header">
             <h6>Source-wise Emission <span className="date-range-specifier">{`( For ${getFYText()} )`}</span></h6>
           </div>
           {sourceWiseEmission ? (
             <EmissionTypesChart
               categories={sourceWiseEmission.categories || []}
               yAxis={"MTCO<sub>2</sub>e"}
               series={sourceWiseEmission.series || []}
             />
           ) : (
             <EmissionTypesChart
               categories={[]}
               yAxis={"MTCO<sub>2</sub>e"}
               series={[]}
             /> // Placeholder until data is available
           )}
         </div>
       </div>
       :''
        }
        {/* {
          mode === "ROAD" &&
          <div className="emission_sustainability_row_3">
            <div className="age_of_trucks_container">
              <div className="age_of_trucks_header">
                <h6>CO<sub>2</sub> Emission by Age of Trucks & Trailers <span className="date-range-specifier">{`( For ${getFYText()} )`}</span></h6>
              </div>
              <AgeOfTrucksChart
                categories={emissionsByTruckAgeData.categories || []}
                yAxis={"MTCO<sub>2</sub>e"}
                xAxis={"Age of Trucks"}
                series={emissionsByTruckAgeData.series || []}
              />
            </div>
          </div>
        } */}
      </div>
    </>
  )
}

export default SustainabilityDashboard