'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import CSVFileValidator from 'csv-file-validator';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import * as Datetime from 'react-datetime';
import $ from 'jquery';
import CountUp from 'react-countup';
import { getDDMMYYYY, getHyphenYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenDDMMMYYYY, getHyphenYYYYMMDDHHMMSS, getHyphenDDMMYYYYHHMMSS, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import Modal from 'react-responsive-modal';
import WeatherMapComponent from "./weathermap";
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

export default class LspCeoDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gps_NA_in_transit_return: 0,
            freightAmount: 0,
            eventAction: null,
            show: false,
            fleet_data: 0,
            basicTitle: '',
            basicType: "default",
            overly: "show-n",
            loadshow: "show-n",
            eventGridAction: "gridAction",
            optionlist: [],
            sliderRouteTranslate: "",
            transporterList: [],
            transporters: { label: "", value: "" },
            trucksCount: "",
            fy_target_value: 0,
            fy_achievement_value: 0,
            fy_pending_pods: 0,
            fy_ontime_delivery: 0,
            late_trips: 0,
            total_trips: 0,
            ontime_trips: 0,
            fy_transit_damage: 0,
            pod_processed_count: 0,
            damages_count: 0,
            fy_transit_returns: 0,
            fy_freight_payment: 0,
            last_month_target_percentage: 0,
            month: "",
            bulkslidebulk: '',
            bulkslidebulk1: '',
            scrollData: '',
            scrollData1: '',
            invoicetoexitavghr: 0,
            chart1target: 0,
            chart1achievement: 0,
            chart2achievement: 0,
            pendingpodmorethen30days_nontvp: 0,
            pendingpodmorethen30days_tvp: 0,
            tvpOutStandingAmount: 0,
            violationsCount: 0,
            ticketsCountforabnormalities: 0,
            criticalDelay1to2andgreaterthan2: 0

        }

    }

    async componentDidMount() {

        redirectURL.post("/consignments/scrollingheaderdata").then((response) => {
            console.log(response.data[0].scroll_data, 'scroll data');
            const updatedOn = new Date(response.data[0].updated_on);
            const currentDate = new Date();
            const diffTime = Math.abs(currentDate - updatedOn);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            const scrollData = diffDays < 30 ? response.data[0].scroll_data : "";
            this.setState({
                scrollData1: scrollData
            });
        });
        await redirectURL.post("/consignments/gettransporterslistforceodashboard").then((response) => {
            var resp = response.data
            var transporterList = []
            transporterList.push({
                label: "All Transporters",
                value: "ALL"
            })
            resp.map((e) => {
                transporterList.push({
                    label: e.transporter_name,
                    value: e.transporter_code,
                });
            })
            this.setState({
                transporterList: transporterList,
            })
        })
        var params = {}
        if (localStorage.getItem('value') != undefined && localStorage.getItem('value') != null && localStorage.getItem('value') != "" && localStorage.getItem('label') != undefined && localStorage.getItem('label') != null && localStorage.getItem('label') != "") {
            this.setState({
                transporters: { label: localStorage.getItem('label'), value: localStorage.getItem('value') },
            })
            var transporter_code = localStorage.getItem('value')
        } else {
            if (localStorage.getItem("user_type") == "TRANSPORTER") {
                this.setState({
                    transporters: { label: localStorage.getItem('firstname'), value: localStorage.getItem('firstname') },
                })
                var transporter_code = localStorage.getItem('transportercode')
                transporter_code = (JSON.parse(transporter_code))
                transporter_code = transporter_code[0]
            } else {
                var transporter_code = "ALL"
                this.setState({
                    transporters: { label: "All Transporters", value: "ALL" },
                })
            }
        }
        if (transporter_code != undefined && transporter_code != null && transporter_code != "") {
            this.setState({
                overly: "show-m",
                loadshow: "show-m",
            })

            // transporter_code = (JSON.parse(transporter_code))
            // transporter_code=transporter_code[0]
            params.transporter_code = transporter_code
            console.log(params, 'params')
            await redirectURL.post("/consignments/getinitialtargetvsachievementdata", params).then(async (response) => {
                console.log(response.data)
                // await redirectURL.post("/consignments/getinitialpendingpoddata", params).then(async (response1) => {
                //     var pod_data = response1.data.data.total_pending_pods_except_last_month
                await redirectURL.post("/consignments/getontimedeliverydata", params).then(async (response2) => {
                    await redirectURL.post("/consignments/gettransitdamagedata", params).then(async (response3) => {
                        await redirectURL.post("/consignments/gettransitreturnsdata", params).then(async (response4) => {
                            await redirectURL.post("/consignments/getfreightpaymentdata", params).then(async (response5) => {
                                await redirectURL.post("/consignments/getFleetDetailsForLSPCEO", params).then(async (response6) => {
                                    await redirectURL.post("/consignments/fetchNoGpsForIntransit", params).then(async (response7) => {
                                        // await redirectURL.post("/consignments/invoicetoexitMountDataforDashboard", params).then(async (response8) => {
                                        //     console.log(response8.data,'response8888')
                                        //     const aggregatedData = {}
                                        //     response8.data.forEach((ele) => {
                                        //     const year = ele.month;
                                        //     const [month, yearRange] = year.split(' ');
                                        //     if (!aggregatedData[year]) {
                                        //         aggregatedData[year] = {
                                        //             month: year,
                                        //             data: [],
                                        //         };
                                        //     }                        
                                        //     aggregatedData[year].data.push(ele.invoice_to_exit_hrs);
                                        //         });                                              
                                        //      const aggregatedDataArray = Object.values(aggregatedData);  
                                        //     console.log(aggregatedDataArray, 'aggregatedDataArray1');
                                        // const invoicePercentageData = aggregatedDataArray.map((ele) => {
                                        //         if (ele.data && ele.data.length > 0) {
                                        //           const sum = ele.data.reduce((total, value) => total + value, 0);
                                        //           const average = sum/ele.data.length ; 
                                        //           return parseFloat(average.toFixed(1));
                                        //         }
                                        //     })

                                        await redirectURL.post("/consignments/getdrivertrainingsfirstchart", params)
                                            .then(async (response9) => {
                                                let fyData;
                                                console.log(response9.data, "135236");
                                                if (response9.data.getcategoryData && Array.isArray(response9.data.getcategoryData)) {
                                                    fyData = response9.data.getcategoryData.map((month, index) => ({
                                                        month,
                                                        target: response9.data.getgraphstargets[index],
                                                        achievement: response9.data.getgraphsachievements[index],
                                                    })).filter(item => item.month === "FY 24-25");
                                                }

                                                // Ensure that fyData[0] exists before attempting to calculate percentage
                                                var percentage_IDTR = 0;
                                                if (fyData.length > 0 && fyData[0].target !== undefined && fyData[0].achievement !== undefined) {
                                                    percentage_IDTR = (fyData[0].achievement / fyData[0].target) * 100;
                                                    console.log(`Percentage IDTR: ${percentage_IDTR}%`);
                                                }
                                                await redirectURL.post("/consignments/getdrivertrainingssecondchart", params)
                                                    .then(async (response10) => {
                                                        var fyData;
                                                        console.log(response10.data, "135236");
                                                        if (response10.data.getcategoryData && Array.isArray(response10.data.getcategoryData)) {
                                                            fyData = response10.data.getcategoryData.map((month, index) => ({
                                                                month,
                                                                target: response10.data.getgraphstargets[index],
                                                                achievement: response10.data.getgraphsachievements[index],
                                                            })).filter(item => item.month === "FY 24-25");
                                                        }
                                                        //     var percentage_DEC =( ([fyData[0].achievement]/[fyData[0].target])*100)
                                                        // console.log(percentage_DEC,'percentage_DEC')
                                                        var percentage_DEC = 0;
                                                        if (fyData.length > 0 && fyData[0].target !== undefined && fyData[0].achievement !== undefined) {
                                                            percentage_DEC = (fyData[0].achievement / fyData[0].target) * 100;
                                                            console.log(`Percentage IDTR: ${percentage_DEC}%`);
                                                        }
                                                        await redirectURL.post("/consignments/getPodPendingData", { transporter_code: transporter_code, source: "ALL" })
                                                            .then(async (response11) => {
                                                                var podmore30days = 0;
                                                                if (response11 && response11.data && response11.data.data && response11.data.data.graph_data) {
                                                                    podmore30days = response11.data.data.graph_data.greater_than_30_days_count;
                                                                    //     var podmore30days = 0;
                                                                    //     response11.data.data.graph_data.forEach((ele) => {
                                                                    //         if (!isNaN(ele.greater_than_30_days_count)) {
                                                                    //             podmore30days += ele.greater_than_30_days_count;
                                                                }
                                                                //     })}
                                                                // console.log(podmore30days, 'podmore30days');
                                                                await redirectURL.post("/consignments/fetchTvpPendingPodsTripData", { transporter_code: transporter_code, source: "ALL" })
                                                                    .then(async (response12) => {
                                                                        var podmore30daystvp = 0;
                                                                        if (response12 && response12.data && response12.data.data && response12.data.data.tvp_graph_data) {
                                                                            podmore30daystvp = response12.data.data.tvp_graph_data.greater_than_30_days_count;
                                                                            //     response12.data.data.tvp_graph_data.forEach((ele) => {
                                                                            //         if (!isNaN(ele.greater_than_30_days_count)) {
                                                                            //             podmore30daystvp += ele.greater_than_30_days_count;
                                                                        }
                                                                        //     });
                                                                        //     // Further processing with podmore30daystvp
                                                                        // }
                                                                        console.log(podmore30daystvp, 'podmore30daystvp');

                                                                        await redirectURL.post("/consignments/paymentsandviolationsforlsps", params)
                                                                            .then(async (response13) => {
                                                                                console.log(response13, 'response13')

                                                                                await redirectURL.post('/tms/TmsForLsp', params)
                                                                                    .then(async (response14) => {
                                                                                        console.log(response14.data.recordsCount, 'response14')
                                                                                        this.setState({
                                                                                            fy_freight_payment: 0,
                                                                                            fy_transit_returns: response4.data.transit_return_count,
                                                                                            fy_transit_damage: (response3.data.transit_damage_count == "NaN" ? 0 : response3.data.transit_damage_count),
                                                                                            damages_count: (response3.data.damages_count >= 0 ? response3.data.damages_count : 0),
                                                                                            pod_processed_count: (response3.data.pod_processed_count >= 0 ? response3.data.pod_processed_count : 0),
                                                                                            fy_ontime_delivery: (response2.data.pendingpod_count == "NaN" ? 0 : response2.data.pendingpod_count),
                                                                                            total_trips: (response2.data.total_trips > 0 ? response2.data.total_trips : 0),
                                                                                            late_trips: (response2.data.late_trips > 0 ? response2.data.late_trips : 0),
                                                                                            ontime_trips: (response2.data.total_trips > 0 ? response2.data.total_trips - response2.data.late_trips : 0),
                                                                                            // fy_pending_pods: pod_data,
                                                                                            last_month_target_percentage: (response.data.target_percentage == "NaN" ? 0 : response.data.target_percentage),
                                                                                            month: response.data.month,
                                                                                            fleet_data: (response6.data.data.length > 0 ? response6.data.data.length : 0),
                                                                                            gps_NA_in_transit_return: (response7.data.data.no_of_trucks > 0 ? response7.data.data.no_of_trucks : 0),
                                                                                            // chart1target: [fyData[0].target],
                                                                                            chart1achievement: percentage_IDTR || 0,
                                                                                            chart2achievement: percentage_DEC || 0,
                                                                                            pendingpodmorethen30days_nontvp: podmore30days || 0,
                                                                                            pendingpodmorethen30days_tvp: podmore30daystvp || 0,
                                                                                            tvpOutStandingAmount: response13.data[0].sec_payment || 0,
                                                                                            violationsCount: response13.data[0].violations || 0,
                                                                                            freightAmount: response13.data[0].freight_amount || 0,
                                                                                            invoicetoexitavghr: response13.data[0].invoice_to_exit || 0,
                                                                                            criticalDelay1to2andgreaterthan2: response13.data[0].transit_delay_days || 0,
                                                                                            ticketsCountforabnormalities: response14.data.recordsCount || 0,
                                                                                        })
                                                                                    })
                                                                                // })
                                                                            })
                                                                    })
                                                            })
                                                    })
                                            })
                                    })
                                })
                            })
                        })
                    })
                })
            })
            this.setState({
                overly: "show-n",
                loadshow: "show-n",
            })
        }

    }

    handleClickOutside = (event) => {
        if (!event.target.closest(".slide-r")) {
            this.onClickHideAll();
        }
    };

    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    newFilter = async (filterType, selOption) => {
        console.log(filterType, selOption, 'selected name');
        this.setState({ [filterType]: selOption }, async () => {
            console.log(this.state.transporters, 'transporters000');

            localStorage.setItem('label', selOption.label);
            localStorage.setItem('value', selOption.value);

            this.setState({
                overly: 'show-m',
                loadshow: 'show-m',
            });

            var params = {
                "transporter_code": selOption.value
            };

            try {
                const response = await redirectURL.post("/consignments/getinitialtargetvsachievementdata", params);
                // const response1 = await redirectURL.post("/consignments/getinitialpendingpoddata", params);
                const response2 = await redirectURL.post("/consignments/getontimedeliverydata", params);
                const response3 = await redirectURL.post("/consignments/gettransitdamagedata", params);
                const response4 = await redirectURL.post("/consignments/gettransitreturnsdata", params);
                const response5 = await redirectURL.post("/consignments/getfreightpaymentdata", params);
                const response6 = await redirectURL.post("/consignments/getFleetDetailsForLSPCEO", params);
                const response7 = await redirectURL.post("/consignments/fetchNoGpsForIntransit", params);
                // var pod_data = response1.data.data.total_pending_pods_except_last_month;
                var ontime_trips = response2.data.total_trips - response2.data.late_trips
                // const response8 = await redirectURL.post("/consignments/invoicetoexitMountDataforDashboard", params);
                //     console.log(response8.data,'response8888')
                //     const aggregatedData = {}
                //     response8.data.forEach((ele) => {
                //     const year = ele.month;
                //     const [month, yearRange] = year.split(' ');
                //     if (!aggregatedData[year]) {
                //         aggregatedData[year] = {
                //             month: year,
                //             data: [],
                //         };
                //     }                        
                //     aggregatedData[year].data.push(ele.invoice_to_exit_hrs);
                //         });                                              
                //      const aggregatedDataArray = Object.values(aggregatedData);  
                //     // console.log(aggregatedDataArray, 'aggregatedDataArray1');
                // var invoicePercentageData = aggregatedDataArray.map((ele) => {
                //         if (ele.data && ele.data.length > 0) {
                //           const sum = ele.data.reduce((total, value) => total + value, 0);
                //           const average = sum/ele.data.length ; 
                //           return parseFloat(average.toFixed(1));
                //         }
                //     })
                // console.log(invoicePercentageData,'invoicePercentageDatainvoicePercentageData2')
                var response9 = await redirectURL.post("/consignments/getdrivertrainingsfirstchart", params);
                // .then((response9) => {
                var fyData;
                console.log(response9.data, "135236");
                if (response9.data.getcategoryData && Array.isArray(response9.data.getcategoryData)) {
                    fyData = response9.data.getcategoryData.map((month, index) => ({
                        month,
                        target: response9.data.getgraphstargets[index],
                        achievement: response9.data.getgraphsachievements[index],
                    })).filter(item => item.month === "FY 24-25");
                }
                var percentage_IDTR = 0;
                if (fyData.length > 0 && fyData[0].target !== undefined && fyData[0].achievement !== undefined) {
                    percentage_IDTR = (fyData[0].achievement / fyData[0].target) * 100;
                    console.log(`Percentage IDTR: ${percentage_IDTR}%`);
                }
                // var percentage_IDTR =( ([fyData[0].achievement]/[fyData[0].target])*100)
                // console.log(fyData, 'fyDatafyData',percentage_IDTR,'percentage_IDTR');
                var response10 = await redirectURL.post("/consignments/getdrivertrainingssecondchart", params)
                // .then(async(response10) => {
                var fyData;
                console.log(response10.data, "135236");
                if (response10.data.getcategoryData && Array.isArray(response10.data.getcategoryData)) {
                    fyData = response10.data.getcategoryData.map((month, index) => ({
                        month,
                        target: response10.data.getgraphstargets[index],
                        achievement: response10.data.getgraphsachievements[index],
                    })).filter(item => item.month === "FY 24-25");
                }
                var percentage_DEC = 0;
                if (fyData.length > 0 && fyData[0].target !== undefined && fyData[0].achievement !== undefined) {
                    percentage_DEC = (fyData[0].achievement / fyData[0].target) * 100;
                    console.log(`Percentage IDTR: ${percentage_DEC}%`);
                }
                var response11 = await redirectURL.post("/consignments/getPodPendingData", { transporter_code: selOption.value, source: "ALL" })
                var podmore30days = 0;
                if (response11 && response11.data && response11.data.data && response11.data.data.graph_data) {
                    podmore30days = response11.data.data.graph_data.greater_than_30_days_count
                    // var podmore30days = response11.data.data.graph_data.greater_than_30_days_count.forEach((ele) => {
                    //     if (!isNaN(ele.greater_than_30_days_count)) {
                    //         podmore30days += ele.greater_than_30_days_count;
                }
                // });
                // Further processing with podmore30days
                // }
                // console.log(podmore30days, 'podmore30days3');
                var response12 = await redirectURL.post("/consignments/fetchTvpPendingPodsTripData", { transporter_code: selOption.value, source: "ALL" })
                var podmore30daystvp = 0;
                if (response12 && response12.data && response12.data.data && response12.data.data.tvp_graph_data) {
                    var podmore30daystvp = response12.data.data.tvp_graph_data.greater_than_30_days_count;
                    // response12.data.data.tvp_graph_data.forEach((ele) => {
                    //     if (!isNaN(ele.greater_than_30_days_count)) {
                    //         podmore30daystvp += ele.greater_than_30_days_count;
                    //     }
                    // });
                    // Further processing with podmore30daystvp
                }
                const response13 = await redirectURL.post("/consignments/paymentsandviolationsforlsps", params);
                console.log(response13, 'response13')
                const Abnormalities = await redirectURL.post('/tms/TmsForLsp', params);
                console.log(Abnormalities, 'Abnormalities')

                await this.setState({
                    fy_freight_payment: 0,
                    fy_transit_returns: response4.data.transit_return_count,
                    fy_transit_damage: response3.data.transit_damage_count || 0,
                    damages_count: response3.data.damages_count || 0,
                    pod_processed_count: response3.data.pod_processed_count || 0,
                    fy_ontime_delivery: response2.data.pendingpod_count || 0,
                    late_trips: response2.data.late_trips || 0,
                    total_trips: response2.data.total_trips || 0,
                    ontime_trips: ontime_trips || 0,
                    // fy_pending_pods: pod_data,
                    last_month_target_percentage: response.data.target_percentage || 0,
                    month: response.data.month,
                    fleet_data: response6.data.data.length || 0,
                    gps_NA_in_transit_return: response7.data.data.no_of_trucks || 0,
                    chart1achievement: percentage_IDTR || 0,
                    chart2achievement: percentage_DEC || 0,
                    pendingpodmorethen30days_nontvp: podmore30days || 0,
                    pendingpodmorethen30days_tvp: podmore30daystvp || 0,
                    tvpOutStandingAmount: response13.data[0].sec_payment || 0,
                    violationsCount: response13.data[0].violations || 0,
                    freightAmount: response13.data[0].freight_amount || 0,
                    invoicetoexitavghr: response13.data[0].invoice_to_exit || 0,
                    criticalDelay1to2andgreaterthan2: response13.data[0].transit_delay_days || 0,
                    ticketsCountforabnormalities: Abnormalities.data.recordsCount || 0,

                });
            } catch (error) {
                // Handle errors here
                console.error("Error fetching data:", error);
            } finally {
                this.setState({
                    overly: 'show-n',
                    loadshow: 'show-n',
                });
            }
        });
    };

    onCloseRouteDiv = (event) => {
        this.setState({
            sliderRouteTranslate: "",
            overly: "show-n"
        })
    }
    changeHandler = (event) => {
        this.setState({
            sliderRouteTranslate: "slider-translate-60p",
            overly: "show-m"
        })
    }

    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            bulkslide: "",
            bulkslidebulk: '',
            bulkslidebulk1: '',
        });

    }
    onclickMovetopage(pageValue) {
        if (localStorage.getItem("user_type") == "TRANSPORTER") {
            var transporterCode = localStorage.getItem("transportercode")
            transporterCode = (JSON.parse(transporterCode))
            transporterCode=transporterCode[0]
            if (pageValue == "1" || pageValue == 1) {
                var encryptedString = window.btoa(transporterCode)
                window.location.href = "/fleetdetails/" + transporterCode
            }
            if (pageValue == "2" || pageValue == 2) {
                var encryptedString = window.btoa(transporterCode)
                window.location.href = "/targetvsachievement/" + transporterCode
            }
            if (pageValue == "3" || pageValue == 3) {
                var encryptedString = window.btoa(transporterCode)
                window.location.href = "/pendingPods/" + transporterCode
            }
            if (pageValue == "4" || pageValue == 4) {
                var encryptedString = window.btoa(transporterCode)
                window.location.href = "/ontimedelivery/" + transporterCode
            }
            if (pageValue == "5" || pageValue == 5) {
                var encryptedString = window.btoa(transporterCode)
                window.location.href = "/transitdamages/" + transporterCode
            }
            if (pageValue == "6" || pageValue == 6) {
                var encryptedString = window.btoa(transporterCode)
                window.location.href = "/transitreturns/" + transporterCode
            }
            if (pageValue == "7" || pageValue == 7) {
                var encryptedString = window.btoa(transporterCode)
                window.location.href = "/freightpayments/" + transporterCode
            }
            if (pageValue == "8" || pageValue == 8) {
                var encryptedString = window.btoa(transporterCode)
                window.location.href = "/violations/" + encryptedString
            }
            if (pageValue == "9" || pageValue == 9) {
                var encryptedString = window.btoa(transporterCode)
                window.location.href = "/drivertrainings/" + encryptedString
            }
            if (pageValue == "10" || pageValue == 10) {
                var encryptedString = window.btoa(transporterCode)
                window.location.href = "/criticaldelay/" + transporterCode
            }
            if (pageValue == "11" || pageValue == 11) {
                var encryptedString = window.btoa(transporterCode)
                window.location.href = "/gpsstatus/" + transporterCode
            }
            if (pageValue == "12" || pageValue == 12) {
                var encryptedString = window.btoa(transporterCode)
                window.location.href = "/invoicetoexitdlp/" + transporterCode
            }
            if (pageValue == "13" || pageValue == 13) {
                var encryptedString = window.btoa(transporterCode)
                window.location.href = "/loadchanginginfo/" + encryptedString
            }
            if (pageValue == "14" || pageValue == 14) {
                var encryptedString = window.btoa(transporterCode)
                window.location.href = "/tvpfreightpaymentpending/" + transporterCode
            }
            if (pageValue == "15" || pageValue == 15) {
                var encryptedString = window.btoa(transporterCode)
                window.location.href = "/freightamount/" + transporterCode
            }
            if (pageValue == "16" || pageValue == 16) {
                var encryptedString = window.btoa(transporterCode)
                window.location.href = "/liveabnormalities/" + transporterCode
            }
        } else {
            console.log(this.state.transporters, "222")
            if (this.state.transporters.value != undefined && this.state.transporters.value != null && this.state.transporters.value != "") {
                var transporterCode = this.state.transporters.value
                if (pageValue == "1" || pageValue == 1) {
                    var encryptedString = window.btoa(transporterCode)
                    window.location.href = "/fleetdetails/" + transporterCode
                }
                if (pageValue == "2" || pageValue == 2) {
                    var encryptedString = window.btoa(transporterCode)
                    window.location.href = "/targetvsachievement/" + transporterCode
                }
                if (pageValue == "3" || pageValue == 3) {
                    var encryptedString = window.btoa(transporterCode)
                    window.location.href = "/pendingPods/" + transporterCode
                }
                if (pageValue == "4" || pageValue == 4) {
                    var encryptedString = window.btoa(transporterCode)
                    window.location.href = "/ontimedelivery/" + transporterCode
                }
                if (pageValue == "5" || pageValue == 5) {
                    var encryptedString = window.btoa(transporterCode)
                    window.location.href = "/transitdamages/" + transporterCode
                }
                if (pageValue == "6" || pageValue == 6) {
                    var encryptedString = window.btoa(transporterCode)
                    window.location.href = "/transitreturns/" + transporterCode
                }
                if (pageValue == "7" || pageValue == 7) {
                    var encryptedString = window.btoa(transporterCode)
                    window.location.href = "/freightpayments/" + transporterCode
                }
                if (pageValue == "8" || pageValue == 8) {
                    var encryptedString = window.btoa(transporterCode)
                    window.location.href = "/violations/" + encryptedString
                }
                if (pageValue == "9" || pageValue == 9) {
                    var encryptedString = window.btoa(transporterCode)
                    window.location.href = "/drivertrainings/" + encryptedString
                }
                if (pageValue == "10" || pageValue == 10) {
                    var encryptedString = window.btoa(transporterCode)
                    window.location.href = "/criticaldelay/" + transporterCode
                }
                if (pageValue == "11" || pageValue == 11) {
                    var encryptedString = window.btoa(transporterCode)
                    window.location.href = "/gpsstatus/" + transporterCode
                }
                if (pageValue == "12" || pageValue == 12) {
                    var encryptedString = window.btoa(transporterCode)
                    window.location.href = "/invoicetoexitdlp/" + transporterCode
                }
                if (pageValue == "13" || pageValue == 13) {
                    var encryptedString = window.btoa(transporterCode)
                    window.location.href = "/loadchanginginfo/" + encryptedString
                }
                if (pageValue == "14" || pageValue == 14) {
                    var encryptedString = window.btoa(transporterCode)
                    window.location.href = "/tvpfreightpaymentpending/" + transporterCode
                }
                if (pageValue == "15" || pageValue == 15) {
                    var encryptedString = window.btoa(transporterCode)
                    window.location.href = "/freightamount/" + transporterCode
                }
                if (pageValue == "16" || pageValue == 16) {
                    var encryptedString = window.btoa(transporterCode)
                    window.location.href = "/liveabnormalities/" + transporterCode
                }
            } else {
                this.setState({
                    show: true,
                    basicTitle: "Please Select Transporter",
                    basicType: "warning"
                })
            }
        }

    }
    
    
    showUpload_loi() {
        this.setState({
            overly: "show-m",
            bulkslidebulk: "slider-translate"
        })
    }
    ferightAmount() {
        this.setState({
            overly: "show-m",
            bulkslidebulk1: "slider-translate"
        })
    }
    resetUpload = () => {
        this.setState({
            bulkslidebulk: '',
            bulkslidebulk1: '',
            overly: 'show-n',
            file: '',
            bulkMarkData: [],
            bulkMarkData1: []
        })
        $('#upform')[0].reset();
        document.getElementById("upform").reset();
        $('#upform1')[0].reset();
        document.getElementById("upform1").reset();
    }
    async uploadBulkFormHandler(e) {
        e.preventDefault();
        var dataType = this.state.dataType;
        var fileData = this.state.bulkMarkData;
        var flag = 0;

        fileData.map((ele) => {
            console.log(ele, '697----')
            if (ele.transporter_code == "") {
                flag = 1;
            } else if (ele.loi_number == "") {
                flag = 1;
            } else if (ele.loi_issue_date == "") {
                flag = 1;
            } else if (ele.no_of_carriers == "") {
                flag = 1;
            } else if (ele.loi_type == "") {
                flag = 1;
            } else if (ele.expiry_date == "") {
                flag = 1;
            }
        })

        if (flag == 0) {
            if (fileData.length > 0) {
                var dateObject = new Date();
                var day = String(dateObject.getDate()).padStart(2, '0');
                var month = String(dateObject.getMonth() + 1).padStart(2, '0');
                var year = dateObject.getFullYear();
                var formattedDate = `${day}-${month}-${year}`;


                var reqParams = {
                    csvData: fileData,
                }
                console.log(reqParams, 'paramsforbulk')

                this.setState({
                    loadshow: 'show-m',
                    overly: "show-m"
                });

                redirectURL.post("/consignments/bulkuploadloistatus", reqParams)
                    .then((response) => {

                        //   console.log(response,'resp09876', JSON.parse(response.config.data[0].csvData))
                        //   this.setState({
                        //     LoiData:JSON.parse(response.config.data)
                        //   })

                        this.setState({
                            loadshow: 'show-n',
                            overly: 'show-n',
                            basicType: "success",
                            basicTitle: "Successfully Uploaded",
                            show: true,
                            bulkslidebulk: '',
                        })


                        this.componentDidMount()
                    })
                    .catch((e) => {
                        console.log(e);
                    });


            } else {
                this.setState({
                    basicTitle: "Invalid Data",
                    basicType: "warning",
                    show: true,
                    file: "",
                    bulkMarkData: []
                });
                $('#upform')[0].reset();
            }
        } else {
            $("#bulkUploadID").val();
            this.setState({
                basicTitle: "Fields Should not be Empty",
                basicType: "warning",
                show: true,
                file: "",
                bulkMarkData: []
            });
            $('#upform')[0].reset();
        }
    };

    async onsubmitferightamount(e) {
        e.preventDefault();
        // var dataType = this.state.dataType;
        var fileData = this.state.bulkMarkData1;
        var flag = 0;
        console.log(fileData, 'fileDatafileData')
        fileData.map((ele) => {
            console.log(ele, '697----')
            if (ele.batch_from == "") {
                flag = 1;
            } else if (ele.batch_to == "") {
                flag = 1;
            } else if (ele.tpt_code == "") {
                flag = 1;
            } else if (ele.total_freight == "") {
                flag = 1;
            }
        })

        if (flag == 0) {
            if (fileData.length > 0) {
                var dateObject = new Date();
                var day = String(dateObject.getDate()).padStart(2, '0');
                var month = String(dateObject.getMonth() + 1).padStart(2, '0');
                var year = dateObject.getFullYear();
                var formattedDate = `${day}-${month}-${year}`;


                var reqParams = {
                    csvData: fileData,
                }
                console.log(reqParams, 'paramsforbulk')

                this.setState({
                    loadshow: 'show-m',
                    overly: "show-m"
                });

                redirectURL.post("/consignments/lspfrieghtamount", reqParams)
                    .then((response) => {

                        this.setState({
                            loadshow: 'show-n',
                            overly: 'show-n',
                            basicType: "success",
                            basicTitle: "Successfully Uploaded",
                            show: true,
                            bulkslidebulk1: '',
                        })
                        $("#bulkUploadID1").val();
                        $('#upform1')[0].reset();
                        redirectURL.post("/consignments/updateFrieghtAmountForLsp", reqParams)
                            .then((response) => {
                                console.log(response, 'responssss')
                            })
                        // this.componentDidMount()
                    })
                    .catch((e) => {
                        console.log(e);
                    });


            } else {
                this.setState({
                    basicTitle: "Invalid Data",
                    basicType: "warning",
                    show: true,
                    file: "",
                    bulkMarkData1: []
                });
                $('#upform1')[0].reset();
            }
        } else {
            $("#bulkUploadID1").val();
            this.setState({
                basicTitle: "Fields Should not be Empty",
                basicType: "warning",
                show: true,
                file: "",
                bulkMarkData1: []
            });
            $('#upform1')[0].reset();
        }
    };
    changeFileHandler = async (e) => {
        var dt = '';
        const config = {
            headers: [
                {
                    name: 'transporter_code',
                    inputName: 'transporter_code',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'loi_number',
                    inputName: 'loi_number',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'loi_issue_date',
                    inputName: 'loi_issue_date',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'no_of_carriers',
                    inputName: 'no_of_carriers',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'loi_type',
                    inputName: 'loi_type',
                    required: false,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'expiry_date',
                    inputName: 'expiry_date',
                    required: false,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
            ]
        }

        var data = CSVFileValidator(e.target.files[0], config)
            .then((csvData) => {
                let csvList = csvData.data;
                var flag = 0;

                if (flag == 0) {
                    this.setState({
                        bulkMarkData: csvList
                    });
                }

            })
            .catch(err => {
                console.log(err, "error")
            })
        // console.log("e.target.files[0]",e.target.result);
        if (e.target.files[0] != undefined) {
            var out = new Promise(function (reject, resolve) {
                var reader = new FileReader();
                reader.onload = async function (e) {
                    var contents = await e.target.result;
                    resolve(contents);
                };
                var tt = reader.readAsText(e.target.files[0]);
            });
            this.setState({
                file: e.target.files[0]
            });
            // console.log("e.target.files[0].type ", e.target.files[0]);

            if (e.target.files[0].type == '' || e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
                // console.log(fileData);
                // console.log(typeof(fileData))

            }
            else {
                e.target.value = null;
                this.setState({
                    uploadFile: '',
                    show: true,
                    basicType: 'danger',
                    basicTitle: 'Please upload file having extensions .csv only.',
                });
            }
        }

    }
    changeFileHandler1 = async (e) => {
        var dt = '';
        const config = {
            headers: [
                {
                    name: 'batch_from',
                    inputName: 'batch_from',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'batch_to',
                    inputName: 'batch_to',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'tpt_code',
                    inputName: 'tpt_code',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'total_freight',
                    inputName: 'total_freight',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                }
            ]
        }

        var data = CSVFileValidator(e.target.files[0], config)
            .then((csvData) => {
                let csvList = csvData.data;
                var flag = 0;

                if (flag == 0) {
                    console.log(csvList, 'csvListcsvList')
                    this.setState({
                        bulkMarkData1: csvList
                    });
                }

            })
            .catch(err => {
                console.log(err, "error")
            })
        // console.log("e.target.files[0]",e.target.result);
        if (e.target.files[0] != undefined) {
            var out = new Promise(function (reject, resolve) {
                var reader = new FileReader();
                reader.onload = async function (e) {
                    var contents = await e.target.result;
                    resolve(contents);
                };
                var tt = reader.readAsText(e.target.files[0]);
            });
            console.log(e.target.files[0], 'e.target.files[0]')
            this.setState({
                file: e.target.files[0]
            });
            // console.log("e.target.files[0].type ", e.target.files[0]);

            if (e.target.files[0].type == '' || e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
                // console.log(fileData);
                // console.log(typeof(fileData))

            }
            else {
                e.target.value = null;
                this.setState({
                    uploadFile: '',
                    show: true,
                    basicType: 'danger',
                    basicTitle: 'Please upload file having extensions .csv only.',
                });
            }
        }

    }


    submitScrollData = async () => {
        if (this.state.scrollData != undefined && this.state.scrollData != "" && this.state.scrollData != null && this.state.scrollData != "undefined") {
            var params = {
                type: 'update',
                scroll_data: this.state.scrollData,
                updated_by: localStorage.getItem("username")
            }
            await redirectURL.post("/consignments/scrollingheaderdata", params).then((response) => {
                console.log(response.data, 'scroll data1')
                this.setState({
                    scrollData1: (response.data[0].scroll_data ? response.data[0].scroll_data : ""),
                    scrollData: ''
                })
            })
        } else {
            this.setState({
                show: true,
                basicType: 'danger',
                basicTitle: 'Enter something to display',
            })
        }
    }

    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({ [name]: value });
    };

    render() {
        const deptCode = (localStorage.getItem("dept_code") || "[]");
        const userType = (localStorage.getItem("user_type") || "[]");
        const isSNDG = (["AD", "SNDG"]).includes(...(JSON.parse(deptCode)));
        const isNotTransporter = !userType.includes("TRANSPORTER");
        const isNandaKishore = localStorage.getItem("username") === "Nanda Kishore";

        const chart1achievement = Number(this.state.chart1achievement) || 0;
        const chart2achievement = Number(this.state.chart2achievement) || 0;
        console.log(isSNDG, isNotTransporter, deptCode, userType, "1033")
        return (
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)} style={{ position: "fixed", top: "43%" }}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
                <div>
                    <h5 style={{ marginTop: '15px' }}><i className="icofont icofont-dashboard f30 mt-5p"></i>&nbsp;LSP CEO Dashboard
                        {((isSNDG && isNotTransporter) || isNandaKishore) ?
                            <span className="float-right">
                                <button className="btn btn-success"
                                    onClick={this.showUpload_loi.bind(this)}
                                >LOI Bulk Upload</button>
                            </span>
                            : ""}

                        {localStorage.getItem("user_type") == "TRANSPORTER" ?
                            ""
                            :
                            <span style={{ marginTop: '-2px' }} className="lspceo">
                                {/* <label className="col-xl-12 col-lg-12"> Transporter* : </label> */}
                                <Select
                                    placeholder={this.state.transporters.label == "undefined" ? "All Transporters" : ''}
                                    closeMenuOnSelect={true}
                                    value={this.state.transporters}

                                    onChange={this.newFilter.bind(this, "transporters")}
                                    style={{ borderRadius: "0px" }}
                                    options={this.state.transporterList} required
                                />
                            </span>
                        }

                        <span>
                            {((isSNDG && isNotTransporter) || isNandaKishore) ? <div style={{ display: 'flex', flexDirection: 'row', float: 'right', marginRight: '15px' }}>
                                <input
                                    type="text"
                                    style={{ width: '200px', marginLeft: '7px' }}
                                    value={this.state.scrollData}
                                    name="scrollData"
                                    id="scrollData"
                                    placeholder='Enter Scroll Bar Title'
                                    className="form-control"
                                    autoComplete="off"
                                    onChange={this.handleChange}
                                    required
                                />
                                <button type="button" className="btn btn-success" style={{ width: '100px' }} onClick={this.submitScrollData}>Submit</button>
                            </div>
                                : ""
                            }
                        </span>
                        &nbsp;&nbsp;&nbsp;
                        {((isSNDG && isNotTransporter) || isNandaKishore) ?
                            <span className="float-right">
                                <button className="btn btn-success"
                                    onClick={this.ferightAmount.bind(this)}
                                >TPT Freight Payment</button>
                            </span>
                            : ''}
                    </h5>

                </div>
                {/* {console.log(isSNDG,'isSNDG',isNotTransporter,'isNotTransporter',isNandaKishore,'isNandaKishore')} */}


                <div className="scrolling-text" style={{backgroundColor:'#f0f0f0', color:'#333'}}>
                    <h5 style={{ marginTop: '10px' }}>{this.state.scrollData1} &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                        {this.state.scrollData1} &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                        {this.state.scrollData1} &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                    </h5>
                </div>


                <div className="row mt-30p">
                    <div className="col-sm-4 h115">
                        <div className="card h115" style={{borderRadius:'10px'}}>
                            <div className="card-body ltxtcenter hvr" style={{ background: "#171c8f", padding: '40px',borderRadius:'10px'}}>
                                <div className="stat-widget-dashboard">
                                    <h6 class="txt-dark" >
                                        <span style={{ fontSize: "18px", color: "#FFFFFF" }}>
                                            {this.state.transporters.label != ('null') ? this.state.transporters.label : ""}
                                        </span>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-4 h115">
                        <div className="card h115 dcursr" style={{borderRadius:'10px'}}>
                            <div className="card-body ltxtcenter hvr" id='buttonStyle1_' onClick={this.onclickMovetopage.bind(this, "16")} style={{borderRadius:'10px'}} >
                                <div className="stat-widget-dashboard">
                                    <h6 class="txt-dark" style={{ fontSize: '18px' }} >
                                        Immediate Action Required
                                    </h6>
                                </div>
                                <div className='row'>
                                    <div className='col-xl-6 col-lg-6'>
                                        <p style={{ fontSize: "16px", fontWeight: "bold" }}><span>Truck Delays:  </span>
                                            <span >{this.state.criticalDelay1to2andgreaterthan2 > 0 ? this.state.criticalDelay1to2andgreaterthan2 : 0}</span></p>
                                    </div>
                                    <div className='col-xl-6 col-lg-6'>
                                        <p style={{ fontSize: "16px", marginLeft: '-19px', fontWeight: "bold" }}><span>Active Tickets &nbsp;: </span>
                                            <span >{this.state.ticketsCountforabnormalities > 0 ? this.state.ticketsCountforabnormalities : 0}</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-4 h115">
                        <div className="card h115 dcursr" style={{borderRadius:'10px'}}>
                            <div className="card-body ltxtcenter hvr" id='buttonStyle1_' onClick={this.onclickMovetopage.bind(this, "1")} style={{borderRadius:'10px'}}>
                                <div className="stat-widget-dashboard">
                                    <h6 class="txt-dark" style={{ fontSize: '18px' }} >
                                        Fleet Details
                                    </h6>
                                </div>
                                <div>
                                    <p style={{ fontSize: "16px", fontWeight: "bold" }}><span>Total Fleet Count &nbsp;: </span>
                                        <span >{this.state.fleet_data.toLocaleString('en-IN')}</span></p>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* <div className="col-xl-3 col-lg-6 col-sm-6 h115">
                            <div className="card h115 dcursr">
                                <div className="card-body ltxtcenter hvr" id ='buttonStyle1_' onClick={this.onclickMovetopage.bind(this,"")} >
                                    <div className="stat-widget-dashboard">
                                        <h6 class="txt-dark" >
                                            Pending Freight Amount
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-xl-3 col-lg-6 col-sm-6 h115">
                            <div className="card h115 dcursr">
                                <div className="card-body ltxtcenter hvr" id ='buttonStyle1_' onClick={this.onclickMovetopage.bind(this,"")} >
                                    <div className="stat-widget-dashboard">
                                        <h6 class="txt-dark" >
                                            Left for BG Expiry
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                </div>

                <div className="lspceo_blk col-xl-12 col-lg-12 col-md-12 mt-20p">
    <h4 style={{ textAlign: "center", marginBottom: "15px" }}>
        <span style={{ fontWeight: '600', fontSize: "18px", color: 'black' }}>Key KPIs</span>
    </h4>

    <div className="row ltxtcenter ltxtcenterD dcursr pd20">

    {/* Target vs Achievement */}
    <div className="col-sm-4" style={{marginBottom:'15px'}} onClick={this.onclickMovetopage.bind(this, "2")}>
        <div className="achievement-card" style={{
            borderRadius: '10px',
            padding: '10px',
            textAlign: 'center',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            border: this.state.last_month_target_percentage >= 100 ? '1px solid #28a745' : '1px solid #ff7f7f',
            background: this.state.last_month_target_percentage >= 100 
                ? 'linear-gradient(-20deg, transparent 68%, rgb(194, 233, 194) 100%)' 
                : 'linear-gradient(-20deg, transparent 68%, rgba(255, 127, 127, 0.4) 100%)'
        }}>
            <p style={{
                fontWeight: 'bold',
                fontSize: '14px',
                color: '#333'
            }}>Target Vs Achievement</p>

            <p style={{
                fontWeight: 'bold',
                fontSize: '36px',
                color: this.state.last_month_target_percentage >= 100 ? '#28a745' : '#ff0000'
            }}>
                {this.state.last_month_target_percentage}%
            </p>

            <p style={{
                fontSize: '14px',
                color: '#666'
            }}>Achievement for Current FY</p>
        </div>
    </div>

    {/* Pending PODs */}
    <div className="col-sm-4" onClick={this.onclickMovetopage.bind(this, "3")}>
        <div className="achievement-card" style={{
            borderRadius: '10px',
            padding: '10px',
            textAlign: 'center',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            border: '1px solid #ff7f7f',
            background: 'linear-gradient(-20deg, transparent 68%, rgba(255, 127, 127, 0.4) 100%)'
        }}>
            <p style={{
                fontWeight: 'bold',
                fontSize: '14px',
                color: '#333',
                marginBottom: '10px'
            }}>Pending POD's {'>'} 30 Days</p>

            <div className='row'>
                <div className='col-6' style={{ borderRight: '1px solid #ff7f7f' }}>
                    <p style={{ fontSize: "32px", fontWeight: "bold", color: "#ff0000", margin: 0 }}>
                        {this.state.pendingpodmorethen30days_nontvp.toLocaleString('en-IN')}
                    </p>
                    <p style={{ fontSize: "12px", color: "#666", margin: 0 }}>Non-TVP POD's</p>
                </div>
                <div className='col-6'>
                    <p style={{ fontSize: "32px", fontWeight: "bold", color: "#ff0000", margin: 0 }}>
                        {this.state.pendingpodmorethen30days_tvp.toLocaleString('en-IN')}
                    </p>
                    <p style={{ fontSize: "12px", color: "#666", margin: 0 }}>TVP POD's</p>
                </div>
            </div>
        </div>
    </div>

    {/* On-Time Delivery */}
    <div className="col-sm-4" onClick={this.onclickMovetopage.bind(this, "4")}>
        <div className="achievement-card" style={{
            borderRadius: '10px',
            padding: '10px',
            textAlign: 'center',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            border: this.state.fy_ontime_delivery < 99.65 ? '1px solid #ff7f7f' : '1px solid #28a745',
            background: this.state.fy_ontime_delivery < 99.65
                ? 'linear-gradient(-20deg, transparent 68%, rgba(255, 127, 127, 0.4) 100%)'
                : 'linear-gradient(-20deg, transparent 68%, rgb(194, 233, 194) 100%)'
        }}>
            <p style={{
                fontWeight: 'bold',
                fontSize: '14px',
                color: '#333',
                marginBottom: '10px'
            }}>On-Time Delivery %</p>

            <div>
                <p style={{ fontSize: "32px", fontWeight: "bold", color: this.state.fy_ontime_delivery < 99.65 ? "#ff0000" : "#2d7a2d", margin: 0 }}>
                    {this.state.fy_ontime_delivery === 'NaN' || this.state.fy_ontime_delivery === 0 ? 0 : this.state.fy_ontime_delivery}%
                </p>
                <p style={{ fontSize: "12px", color: "#666", margin: 0 }}>
                    Delayed Trips: <strong>{this.state.late_trips > 0 ? this.state.late_trips.toLocaleString('en-IN') : 0}</strong>
                </p>
            </div>
        </div>
    </div>

    {/* Transit Damage */}
    <div className="col-sm-4" onClick={this.onclickMovetopage.bind(this, "5")}>
        <div className="damage-card" style={{
            borderRadius: '10px',
            padding: '10px',
            textAlign: 'center',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            border: this.state.fy_transit_damage > 0 ? '1px solid #ff7f7f' : '1px solid #28a745',
            background: this.state.fy_transit_damage > 0
                ? 'linear-gradient(-20deg, transparent 68%, rgba(255, 127, 127, 0.4) 100%)'
                : 'linear-gradient(-20deg, transparent 68%, rgb(194, 233, 194) 100%)'
        }}>
            <p style={{
                fontWeight: 'bold',
                fontSize: '14px',
                color: '#333',
                marginBottom: '10px'
            }}>Transit Damage % for current FY</p>

            <div>
                <p style={{ fontSize: "32px", fontWeight: "bold", color: this.state.fy_transit_damage > 0 ? "#ff0000" : "#2d7a2d", margin: 0 }}>
                    {this.state.fy_transit_damage > 0 ? this.state.fy_transit_damage : 0}%
                </p>
                <p style={{ fontSize: "12px", color: "#666", margin: 0 }}>
                    Damaged VINS/Total VINs (24-25): <strong style={{ color: this.state.fy_transit_damage > 0 ? "#ff0000" : "#2d7a2d" }}>
                        {this.state.damages_count.toLocaleString('en-IN')}/{this.state.pod_processed_count.toLocaleString('en-IN')}
                    </strong>
                </p>
            </div>
        </div>
    </div>

    {/* Transit Returns */}
    <div className="col-sm-4" onClick={this.onclickMovetopage.bind(this, "6")}>
        <div className="returns-card" style={{
            borderRadius: '10px',
            padding: '10px',
            textAlign: 'center',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            border: this.state.fy_transit_returns > 0 ? '1px solid #ff7f7f' : '1px solid #28a745',
            background: this.state.fy_transit_returns > 0
                ? 'linear-gradient(-20deg, transparent 68%, rgba(255, 127, 127, 0.4) 100%)'
                : 'linear-gradient(-20deg, transparent 68%, rgb(194, 233, 194) 100%)'
        }}>
            <p style={{
                fontWeight: 'bold',
                fontSize: '14px',
                color: '#333',
                marginBottom: '10px'
            }}>Transit Returns</p>

            <div>
                <p style={{ fontSize: "32px", fontWeight: "bold", color: this.state.fy_transit_returns > 0 ? "#ff0000" : "#2d7a2d", margin: 0 }}>
                    {this.state.fy_transit_returns > 0 ? this.state.fy_transit_returns + '%' : '0%'}
                </p>
                <p style={{ fontSize: "12px", color: "#666", margin: 0 }}>
                    Transit Return % for current FY
                </p>
            </div>
        </div>
    </div>

    {/* TVP Freight Payment Pending */}
    <div className="col-sm-4" onClick={this.onclickMovetopage.bind(this, "14")}>
        <div className="payment-pending-card" style={{
            borderRadius: '10px',
            padding: '10px',
            textAlign: 'center',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            border: this.state.tvpOutStandingAmount > 0 ? '1px solid #ff7f7f' : '1px solid #28a745',
            background: this.state.tvpOutStandingAmount > 0
                ? 'linear-gradient(-20deg, transparent 68%, rgba(255, 127, 127, 0.4) 100%)'
                : 'linear-gradient(-20deg, transparent 68%, rgb(194, 233, 194) 100%)'
        }}>
            <p style={{
                fontWeight: 'bold',
                fontSize: '14px',
                color: '#333',
                marginBottom: '10px'
            }}>TVP Freight Payment Pending</p>

            <div>
                <p style={{ fontSize: "32px", fontWeight: "bold", color: "#ff0000", margin: 0 }}>
                {`${Number((this.state.tvpOutStandingAmount / 10000000).toFixed(2)).toLocaleString('en-IN')} Cr`}
                </p>
                <p style={{ fontSize: "12px", color: "#666", margin: 0 }}>
                    {'>'}15 Days
                </p>
            </div>
        </div>
    </div>

</div>


</div>


<div className="row mt-20p" style={{ justifyContent: "space-between", marginLeft: "0px", marginRight: '0px', display: 'flex' }}>
  
  {/* GPS Linked Violations */}
  {this.state.violationsCount > 0 ? (
    <div style={{width:'20%'}} onClick={this.onclickMovetopage.bind(this, "8")}>
      <div id='buttonStyle2_' className="kpi-box" style={{
        border: '1px solid #FF7F7F',
        borderRadius: '10px',
        padding: '10px',
        textAlign: 'center',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        background: 'linear-gradient(-20deg, transparent 68%, rgba(255, 127, 127, 0.4) 100%)'
      }}>
        <p style={{ fontWeight: 'bold', fontSize: '14px', color: '#333' }}>
          GPS Linked Violations
        </p>
        <div>
          <p style={{ fontSize: "32px", fontWeight: "bold", color: "#ff0000"}}>
            {this.state.violationsCount.toLocaleString('en-IN')}
          </p>
          <p style={{ fontSize: "14px", color: "#666" }}>Current FY</p>
        </div>
      </div>
    </div>
  ) : (
    <div style={{width:'20%'}} onClick={this.onclickMovetopage.bind(this, "8")}>
      <div id='buttonStyle2_' className="kpi-box" style={{
        border: '1px solid #95d5b2',
        borderRadius: '10px',
        padding: '10px',
        textAlign: 'center',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        background: 'linear-gradient(-20deg, transparent 68%, rgb(194, 233, 194) 100%)'
      }}>
        <p style={{ fontWeight: 'bold', fontSize: '14px', color: '#333'}}>
          GPS Linked Violations
        </p>
        <div>
          <p style={{ fontSize: "32px", fontWeight: "bold", color: "#2d7a2d" }}>
            {this.state.violationsCount.toLocaleString('en-IN')}
          </p>
          <p style={{ fontSize: "14px", color: "#666" }}>Current FY</p>
        </div>
      </div>
    </div>
  )}

  {/* Driver Trainings */}
  {(this.state.chart1achievement > 100 && this.state.chart2achievement > 100) ? (
    <div style={{width:'20%'}} onClick={this.onclickMovetopage.bind(this, "9")}>
      <div className="kpi-box" style={{
        border: '1px solid #95d5b2',
        borderRadius: '10px',
        padding: '10px',
        textAlign: 'center',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        background: 'linear-gradient(-20deg, transparent 68%, rgb(194, 233, 194) 100%)'
      }}>
        <p style={{ fontWeight: 'bold', fontSize: '14px', color: '#333'}}>Driver Trainings</p>
        <div className="row">
          <div className="col-6">
            <p style={{ fontSize: "28px", fontWeight: "bold", color: "#2d7a2d" }}>
              {this.state.chart1achievement.toFixed(1)}%
            </p>
            <p style={{ fontSize: "12px", color: "#666"}}>IDTR Achievements</p>
          </div>
          <div className="col-6">
            <p style={{ fontSize: "28px", fontWeight: "bold", color: "#2d7a2d"}}>
              {this.state.chart2achievement.toFixed(1)}
            </p>
            <p style={{ fontSize: "12px", color: "#666" }}>DEC Achievements</p>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div style={{width:'20%'}} onClick={this.onclickMovetopage.bind(this, "9")}>
      <div className="kpi-box" style={{
        border: '1px solid #FF7F7F',
        borderRadius: '10px',
        padding: '10px',
        textAlign: 'center',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        background: 'linear-gradient(-20deg, transparent 68%, rgba(255, 127, 127, 0.4) 100%)'
      }}>
        <p style={{ fontWeight: 'bold', fontSize: '14px', color: '#333' }}>Driver Trainings</p>
        <div className="row">
          <div className="col-6">
            <p style={{ fontSize: "28px", fontWeight: "bold", color: "#ff0000"}}>
              {this.state.chart1achievement.toFixed(1)}%
            </p>
            <p style={{ fontSize: "12px", color: "#666" }}>IDTR Achievements</p>
          </div>
          <div className="col-6">
            <p style={{ fontSize: "28px", fontWeight: "bold", color: "#ff0000" }}>
              {this.state.chart2achievement.toFixed(1)}%
            </p>
            <p style={{ fontSize: "12px", color: "#666"}}>DEC Achievements</p>
          </div>
        </div>
      </div>
    </div>
  )}

  {/* GPS Status */}
  {this.state.gps_NA_in_transit_return > 0 ? (
    <div style={{width:'20%'}} onClick={this.onclickMovetopage.bind(this, "11")}>
      <div className="kpi-box" style={{
        border: '1px solid #FF7F7F',
        borderRadius: '10px',
        padding: '10px',
        textAlign: 'center',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        background: 'linear-gradient(-20deg, transparent 68%, rgba(255, 127, 127, 0.4) 100%)'
      }}>
        <p style={{ fontWeight: 'bold', fontSize: '14px', color: '#333'}}>GPS Status</p>
        <p style={{ fontSize: "28px", fontWeight: "bold", color: "#ff0000"}}>{this.state.gps_NA_in_transit_return}</p>
        <p style={{ fontSize: "12px", color: "#666" }}>Not Available in Transit Carrier</p>
      </div>
    </div>
  ) : (
    <div style={{width:'20%'}} onClick={this.onclickMovetopage.bind(this, "11")}>
      <div className="kpi-box" style={{
        border: '1px solid #95d5b2',
        borderRadius: '10px',
        padding: '10px',
        textAlign: 'center',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        background: 'linear-gradient(-20deg, transparent 68%, rgb(194, 233, 194) 100%)'
      }}>
        <p style={{ fontWeight: 'bold', fontSize: '14px', color: '#333'}}>GPS Status</p>
        <p style={{ fontSize: "28px", fontWeight: "bold", color: "#2d7a2d"}}>{this.state.gps_NA_in_transit_return}</p>
        <p style={{ fontSize: "12px", color: "#666" }}>Not Available in Transit Carrier</p>
      </div>
    </div>
  )}

  {/* Invoice to Exit */}
  {this.state.invoicetoexitavghr > 6 ? (
    <div style={{width:'20%'}} onClick={this.onclickMovetopage.bind(this, "12")}>
      <div className="kpi-box" style={{
        border: '1px solid #FF7F7F',
        borderRadius: '10px',
        padding: '10px',
        textAlign: 'center',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        background: 'linear-gradient(-20deg, transparent 68%, rgba(255, 127, 127, 0.4) 100%)'
      }}>
        <p style={{ fontWeight: 'bold', fontSize: '14px', color: '#333' }}>Invoice to Exit</p>
        <p style={{ fontSize: "28px", fontWeight: "bold", color: "#ff0000"}}>{this.state.invoicetoexitavghr} Hr's</p>
        <p style={{ fontSize: "12px", color: "#666" }}>Avg Time Taken</p>
      </div>
    </div>
  ) : (
    <div style={{width:'20%'}} onClick={this.onclickMovetopage.bind(this, "12")}>
      <div className="kpi-box" style={{
        border: '1px solid #95d5b2',
        borderRadius: '10px',
        padding: '10px',
        textAlign: 'center',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        background: 'linear-gradient(-20deg, transparent 68%, rgb(194, 233, 194) 100%)'
      }}>
        <p style={{ fontWeight: 'bold', fontSize: '14px', color: '#333' }}>Invoice to Exit</p>
        <p style={{ fontSize: "28px", fontWeight: "bold", color: "#2d7a2d"}}>{this.state.invoicetoexitavghr} Hr's</p>
        <p style={{ fontSize: "12px", color: "#666"}}>Avg Time Taken</p>
      </div>
    </div>
  )}

  {/* Freight Payment */}
  {this.state.freightAmount < 0 ? (
    <div style={{width:'20%'}} onClick={this.onclickMovetopage.bind(this, "15")}>
      <div className="kpi-box" style={{
        border: '1px solid #FF7F7F',
        borderRadius: '10px',
        padding: '10px',
        textAlign: 'center',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        background: 'linear-gradient(-20deg, transparent 68%, rgba(255, 127, 127, 0.4) 100%)'
      }}>
        <p style={{ fontWeight: 'bold', fontSize: '14px', color: '#333'}}>Freight Payment</p>
        <p style={{ fontSize: "25px", fontWeight: "bold", color: "#ff0000" }}>
        {`${Number((this.state.freightAmount / 10000000).toFixed(2)).toLocaleString('en-IN')} Cr`}</p>
        <p style={{ fontSize: "12px", color: "#666" }}>Rs Current FY</p>
      </div>
    </div>
  ) : (
    <div style={{width:'20%'}} onClick={this.onclickMovetopage.bind(this, "15")}>
      <div className="kpi-box" style={{
        border: '1px solid #95d5b2',
        borderRadius: '10px',
        padding: '10px',
        textAlign: 'center',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        background: 'linear-gradient(-20deg, transparent 68%, rgb(194, 233, 194) 100%)'
      }}>
        <p style={{ fontWeight: 'bold', fontSize: '14px', color: '#333' }}>Freight Payment</p>
        <p style={{ fontSize: "25px", fontWeight: "bold", color: "#2d7a2d" }}>
    {`${Number((this.state.freightAmount / 10000000).toFixed(2)).toLocaleString('en-IN')} Cr`}
   </p>
        <p style={{ fontSize: "12px", color: "#666" }}>Rs Current FY</p>
      </div>
    </div>
  )}

</div>

                <div className={"slide-r " + (this.state.bulkslidebulk)} style={{ overflow: "hidden" }}>
                    <h3 className="subH">Bulk Upload</h3>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className="container-fluid">
                            <form method="POST" id="upform" className="theme-form"
                                onSubmit={this.uploadBulkFormHandler.bind(this)}
                            >

                                <div className="form-group mt-20p">
                                    <label className="">Upload File</label>
                                    <input type="file" name="uploadFile" id="bulkUploadID"
                                        onChange={this.changeFileHandler} className="form-control" required />
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                    <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                                </div>
                                <p style={{ color: "red" }}>Note: Format should be same as given in sample template***</p>
                            </form>
                            <div className="form-group">
                                <a className="btn btn-primary" href={require('../../assets/json/loi_status.csv')} target="_blank">Sample Template</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"slide-r " + (this.state.bulkslidebulk1)} style={{ overflow: "hidden" }}>
                    <h3 className="subH">Bulk Upload</h3>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className="container-fluid">
                            <form method="POST" id="upform1" className="theme-form"
                                onSubmit={this.onsubmitferightamount.bind(this)}
                            >

                                <div className="form-group mt-20p">
                                    <label className="">Upload File</label>
                                    <input type="file" name="uploadFile" id="bulkUploadID1"
                                        onChange={this.changeFileHandler1} className="form-control" required />
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                    <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                                </div>
                                <p style={{ color: "red" }}>Note: Format should be same as given in sample template***</p>
                            </form>
                            <div className="form-group">
                                <a className="btn btn-primary" href={require('../../assets/json/freightamount_sample.csv')} target="_blank">Sample Template</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    console.log(yearNumber, monthNumber, dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}


function loadDateTimeScript() {
    // alert("timeDate");
    $('.datetimepicker_mask').datetimepicker({
        mask: '39-19-9999 29:59',
        format: 'd-m-Y H:i'
    });
    $('.datetimepicker_date').datetimepicker({
        mask: '39-19-9999',
        format: 'd-m-Y',
        timepicker: false
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}

function DateEditor() { }

// gets called once before the renderer is used
DateEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function () {
    // console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};