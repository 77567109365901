import React from 'react';
import Highcharts, { color } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';



const CO2EmissionChart = ({ series, categories, title, yAxis }) => {
    // Function to format values using Indian locale
    const safeToLocalString = (value) => {
        const val = Number(value);
        return val != null ? val.toLocaleString('en-IN') : 0;
    }

    const options = {
        chart: {
            type: "spline",
            height: 370 // Adjust the height of the chart
        },
        title: {
            text: '',
            align: 'left',
            style: {
                fontSize: '16px',
                fontWeight: "bold"
            },
        },
        subtitle: {
            // text: '15 JAN - 15 JUNE',
            align: 'left',
        },
        xAxis: {
            categories: categories,
        },
        yAxis: {
            title: {
                text: yAxis,
                useHTML: true,
                formatter: function () {
                    return this.value;
                }
            },
            // type: 'logarithmic',
        },
        plotOptions: {
            series: {
                marker: {
                    enabled: true, // Disable markers
                },
                lineWidth: 2, // Adjust line width
                dashStyle: 'Solid', // Solid line style
            },
            spline: {
                lineWidth: 2, // Adjust line width for spline
                marker: {
                    enabled: true, // Disable markers
                },
                color: "#12DD12"
            },
        },
        tooltip: {
            // pointFormat: '<b>{point.y}</b>',
            pointFormatter: function () {
                return `<b>${safeToLocalString(this.y)}</b> MTCO<sub>2</sub>e`;
            },
            backgroundColor: '#FFFFFF',
            useHTML: true,
        },

        credits: {
            enabled: false,
        },
        legend: {
            enabled: false, // Adjust as needed
            align: 'right',
            verticalAlign: 'top',
            layout: 'vertical'
        },
        series: series
    }

    return (

        <HighchartsReact highcharts={Highcharts} options={options} />

    );

}

export default CO2EmissionChart;