
import React, { Component } from 'react';
// import ReactDOM from 'react-dom';
import _ from "lodash";
import SweetAlert from 'react-bootstrap-sweetalert';
import $ from "jquery";
// import axios from 'axios';
// import Multiselect from 'multiselect-dropdown-react';
// import Breadcrumb from '../common/breadcrumb.component';
// import {Redirect} from 'react-router-dom';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
// import { getHyphenYYYYMMDDHHMMSS,getYYYYMMDDHHMMSS } from '../common/utils';
import { getDDMMYYYY, 
	getDDMMYYYYHHMMSS, 
	getYYYYMMDD, 
	getYYYYMMDDHHMMSS, 
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD, 
	getHyphenDDMMYYYYHHMMSS, 
	getHyphenDDMMYYYY,
	getDDMMMYYYYHHMMDefault } from '../common/utils'; 
// import MarkerClusterer from "@google/markerclusterer";
import { MarkerClusterer } from "@googlemaps/markerclusterer";

var redirectURL = require('../redirectURL');
var infoBox = require('../common/google-infowindow');
var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment');
var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749','#F73E3E', '#B7B3B3' ];
var selectedColor;
var colorButtons = {};
var fencingLength=0;
var mapZoom=16;
var geoFencingArea;
var eventcoords;
var coordinates=[];
var geoFencingAreaArr = [];
var selectedRowData="";
var map;
const carrieroptions = [
	  { value: 'On Trip', label: 'On Trip' },
      { value: 'Empty', label: 'Empty' },
      { value: 'Both', label: 'Both' }
	];


export default class EditNoParkingZones extends Component {

    constructor(props) {
        super(props);

        this.state = {
			modules: AllModules,
            // truckData:this.props.truckInfo,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
			},
			rowData:[],
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:50,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			pageTitle: "Emergency Alerts",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			currentDepartmentCode: null,
			currentDepartmentTitle: null,
            routemap:'',
            startAlert:"",
			endAlert:"",
			user_email:"",
			show : false,
			type : "",
			title : "",
            notifyduration : 5,
            notifyfirstto:"",
			notifyfirstcc:"",
			previousScreenPath :"",
        	departments:[],
        	coords:[],
			geo_name:'',
			editedEventID:"",
            geo_type:'',
            endAlertVal:"",
			fence_type:'',
			event:"",
        	dept_code:"",
			notify:'',
			carrier:"",
			oldEvent : "",
			erroMessage:'',
			notify_transporter:"",
			redirectpage:'',
			oldStartAlert:"",
			oldEndAlert:"",
			triptype :{"value":1,"label":"ALL"},
			showtruckopts:"show-n",
			setshow:false,
			truckscoordinates:[],
			plantLocations : [],		
			weatherData: [],
			displayWeatherForecast: false,
			weatherMarkers: [],
			overly:'show-n',    	      
			redirectPath: "/tnpnoparkingzones"
        };
        this.showMap = this.showMap.bind(this);
        this.applyCoords = this.applyCoords.bind(this);
        this.checkHandler = this.checkHandler.bind(this);
        this.checkNotifyHandler = this.checkNotifyHandler.bind(this);
		this.disableevent = this.disableevent.bind(this);
	}
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
    componentDidMount(){
		loadDateTimeScript()
		if (googleAnalytics.page.enableGA) {
			this.logPageView();
		}
		var rowId  = window.atob(this.props.match.params.rowId);
		var reqparams = {
            //dept_code : dept_code,
            rowId : rowId,
        }
        redirectURL.post("/consignments/getNoParkingZoneById",reqparams).then((response)=>{
            console.log(response.data,"editData");
			var coordinates = response.data[0].coordinates;
			
			if(response.data[0].notify_transporter == "yes"){
				$("#notifyTransporter").prop("checked",true);
			}
			if(response.data[0].is_enabled == 1){
				$("#isEnabled").prop("checked",true);
			}	
			var redirectPath = "/tnpnoparkingzones";
			if(response.data[0].dept_code == "LOG-TNP"){
				redirectPath = "/tnpnoparkingzones"
			}
			else if (response.data[0].dept_code ==  "LOG-PRT") {
				redirectPath = "/prtnoparkingzones"
			}
			else if (response.data[0].dept_code ==  "SNDG") {
				redirectPath = "/sndnoparkingzones"
			}	
			// console.log(eventcoords);
			this.setState({
				location_name : response.data[0].location_name,
				editedEventID : response.data[0]._id,
				coordinates : coordinates,
				carrier : response.data[0].carrier_type,
				msil_notification_emails : response.data[0].msil_notification_emails,
				is_enabled : response.data[0].is_enabled,
				dept_code : response.data[0].dept_code,
				redirectPath : redirectPath
            })
        }).catch(function(error){
            console.log(error);
		})
		
        setTimeout(async () => {
			await this.showMap();
			//await this.handleFilteronLoad();
		},2000)
	}
	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
		});
	}
    showMap =() =>{
    	this.renderMap();
    }
    renderMap = () => {
    	
           loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyDxzGDgeuoSs15Y8z-6EFGt2a2QjjPHF3c&libraries=places,drawing&callback=initMap");
           window.initMap = this.initMap
	}
    
    initMap = () => {
		//console.log("coordinates ", this.state.coordinates)
    	// var coordsvalues;
		var geoFencingObj;

		var allcords=[];
		this.state.truckscoordinates.map((marker) => {
			allcords.push(marker);
		});

    	if(this.state.coords != '')
		{
    		
		}
		geoFencingObj = this.state.coordinates[0];
		console.log("here coordinates",geoFencingObj);
		 map = new window.google.maps.Map(document.getElementById('map_canvas'), {
			zoom: mapZoom,
			center: new window.google.maps.LatLng(28.497236,77.062363),
			mapTypeId: window.google.maps.MapTypeId.ROADMAP,
			mapTypeControl: true,
			mapTypeControlOptions: {
				style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
				position: window.google.maps.ControlPosition.TOP_RIGHT
			},            
			disableDefaultUI: true,
			zoomControl: true
		});
		if(geoFencingObj.length > 0)
		{
			map = new window.google.maps.Map(document.getElementById('map_canvas'), {
				zoom: mapZoom,
				center: new window.google.maps.LatLng(geoFencingObj[0].lat,geoFencingObj[0].lng),
				mapTypeId: window.google.maps.MapTypeId.ROADMAP,
				mapTypeControl: true,
				mapTypeControlOptions: {
					style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
					position: window.google.maps.ControlPosition.TOP_RIGHT
				},            
				disableDefaultUI: true,
				draggingCursor: 'pointer',
				drawingMode:null,
				drawingControl : false,
				zoomControl: true
			});
			
			this.setState({
				loadshow:"show-n",
				overlay:"show-n",
			});
		}
		else{
			this.setState({
				loadshow:"show-n",
				overlay:"show-n",
			});
		}
		
  		//added by nanda for places dropdown...
  	    var input = document.getElementById('search');
          var searchBox = new window.google.maps.places.SearchBox(input);
          map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

          // Bias the SearchBox results towards current map's viewport.
          map.addListener('bounds_changed', function() {
        	 
			searchBox.setBounds(map.getBounds());
			map.setZoom(16);
          });
  		
  		var markers = [];
  		 searchBox.addListener('places_changed', function() {
            var places = searchBox.getPlaces();

            if (places.length == 0) {
              return;
            }

            // Clear out the old markers.
            markers.forEach(function(marker) {
              marker.setMap(null);
            });
            markers = [];
  // For each place, get the icon, name and location.
            var bounds = new window.google.maps.LatLngBounds();
            places.forEach(function(place) {
              if (!place.geometry) {
                console.log("Returned place contains no geometry");
                return;
              }
              var icon = {
                url: place.icon,
                size: new window.google.maps.Size(71, 71),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(17, 34),
                scaledSize: new window.google.maps.Size(25, 25)
              };

              // Create a marker for each place.
              markers.push(new window.google.maps.Marker({
                map: map,
                icon: icon,
                title: place.name,
                position: place.geometry.location
              }));

              if (place.geometry.viewport) {
                // Only geocodes have viewport.
            	  
                bounds.union(place.geometry.viewport);
              } else {
            	  
                bounds.extend(place.geometry.location);
              }
            });
            map.fitBounds(bounds);
          });		
		  //End of adding by nanda


				
		  var markers = allcords.map((marker) => {
			// console.log("marker ",marker);
			if(marker.status == 1)
			{
				var truckText =  "Inside Plant";
				var color =  "#333333";
				var image =  require('../../assets/icons/truck_inside_24.png');
			}
			else if(marker.status == 2)
			{
				var truckText =  "Going to Dealer";
				var color =  "#333333";
				var image =  require('../../assets/icons/truck_going_24.png');
			}
			else if(marker.status == 3 || marker.status == 4) 
			{
				var truckText =  "At Dealer Location";
				var color =  "#333333";
				var image =  require('../../assets/icons/truck_atdealer_24.png');
			}
			else if(marker.status == 5)
			{
				var truckText =  "Left Destination Location";
				var color =  "#333333";
				var image =  require('../../assets/icons/truck_leftdealer_24.png');
			}
			else if(marker.status == 6)
			{
				var truckText =  "Return to Plant";
				var color =  "#333333";
				var image =  require('../../assets/icons/truck_return_24.png');
			}
			else if(marker.status == 7)
			{
				var truckText =  "Empty Truck Outside Plant";
				var color =  "#333333";
				var image =  require('../../assets/icons/truck_empty_24.png');
			}
			else{
				var truckText =  marker.truck_no;
				var color =  "#333333";
				var image =  require('../../assets/icons/truck_na_24.png');
			}
			var image =  require('../../assets/icons/truck_na_24.png');
			var contentarr = []
			var headerTitle = truckText
			//contentarr.push({"key":"Truck No", "value":marker.truck_no});
			contentarr.push({"key":"Transporter Name", "value":marker.transporter_name});
			//contentarr.push({"key":"Distance from geofence", "value":marker.distance});
			contentarr.push({"key":"GPS Provider", "value":marker.actual_lspuser});
			contentarr.push({"key":"Last Packet Time", "value":getHyphenDDMMMYYYYHHMM(marker.timestamp)});
			contentarr.push({"key":"Speed (km/h)", "value":Math.ceil(marker.speed)});
			contentarr.push({"key":"Address", "value":marker.area+", "+marker.state});
			
			var contentString = infoBox(image, headerTitle, contentarr, '')

			
			// var contentString = "<table><tr><th>Status: </th><td style='color:"+color+";font-weight:600;text-align: right'>"+truckText+"</td></tr><tr><th>Distance from geofence: </th><td style='text-align: right'>";
			// contentString = contentString + marker.distance+"</td></tr><th>Truck No: </th><td style='text-align: right'>"+marker.truck_no+"</td></tr><tr><th>Name: </th><td style='text-align: right'>";
			// contentString = contentString + marker.transporter_name+"</td></tr><tr><th> LSP Name:</th><td style='text-align: right'>"+marker.lsp_name+"</td></tr><tr><th>Address:</th><td style='text-align: right'>";
			// contentString = contentString  +marker.address+"</td></tr></table>";
			
			//var contentString = "Here "+marker.truck_no;

			//var contentString = infoBox(require('../../assets/icons/truck_inside_24.png'), header, contentarr)

			var infowindow = new window.google.maps.InfoWindow({
				content: contentString
			});
			
			var markerLatlng = new window.google.maps.LatLng(parseFloat(marker.latitude), parseFloat(marker.longitude));
			
			if(marker.status == 1)
			{
				var image =  require('../../assets/icons/truck_inside_24.png');
			}
			else if(marker.status == 2)
			{
				var image =  require('../../assets/icons/truck_going_24.png');
			}
			else if(marker.status == 3 || marker.status == 4) 
			{
				var image =  require('../../assets/icons/truck_atdealer_24.png');
			}
			else if(marker.status == 5)
			{
				var image =  require('../../assets/icons/truck_leftdealer_24.png');
			}
			else if(marker.status == 6)
			{
				var image =  require('../../assets/icons/truck_return_24.png');
			}
			else if(marker.status == 7)
			{
				var image =  require('../../assets/icons/truck_empty_24.png');
			}
			else{
				var image =  require('../../assets/icons/truck_na_24.png');
			}
			var image =  require('../../assets/icons/truck_na_24.png');
			/*
			if(marker.overspeeding_exception == 1)
			{
				var image =  require('../../assets/icons/truck_overspeed.png');
			}
			if(marker.nightdriving_exception == 1)
			{
				var image =  require('../../assets/icons/truck-end.png');
			}
			*/
			
			
			var mark = new window.google.maps.Marker({
                position: markerLatlng,
				map: map,
				title:"Status: "+truckText+" #"+marker.truck_no,
				icon: image
            });
			mark.addListener('click', function() {
				infowindow.open(map, mark);
			  });
			return mark
            mark.setMap(map);
		});
		var eventtrigger = document.getElementsByClassName('truck');
		// var markerCluster = new MarkerClusterer(map, markers,
        //     {imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'});
		const markerCluster = new MarkerClusterer({ map, markers });

		  // var searchBox = new window.google.maps.places.SearchBox(eventtrigger);
		  //map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(eventtrigger);
		if(this.state.plantLocations.length > 0){
			this.state.plantLocations.map(function(params){
				var plant = {
				url: require("../../assets/icons/"+params.icon), // url
				scaledSize: new window.google.maps.Size(32,32), // scaled size
				origin: new window.google.maps.Point(0,0), // origin
				anchor: new window.google.maps.Point(0, 0) // anchor
				};
				var coords = JSON.parse(params.coordinates);
				var plantmarker = new window.google.maps.Marker({
					position: new window.google.maps.LatLng(coords.lat,coords.lng),
					map: map,
					icon: plant,
					title : params.plant_name
				});
				// var cityCircle = new window.google.maps.Circle({
				// strokeColor: '#9fc9b9',
				// strokeOpacity: 0.3,
				// strokeWeight: 2,
				// fillColor: '#9fc9b9',
				// fillOpacity: 0.6,
				// map: map,
				// center:coords,
				// radius: 50 * 1000
				// });
			})
		}
		//   var thunderstorm = {
		// 	url: require("../../assets/icons/thunderstorm.png"), // url
		// 	scaledSize: new window.google.maps.Size(65, 65), // scaled size
		// 	origin: new window.google.maps.Point(0,0), // origin
		// 	anchor: new window.google.maps.Point(0, 0) // anchor
		// };
		  for(var i=0;i<geoFencingObj.length;i++)
		  {
				geoFencingArea = new window.google.maps.Polygon({
					paths: geoFencingObj,
					editable: true,
					strokeColor: '#1D9567',
					strokeOpacity: 0.70,
					strokeWeight: 0,
					fillColor: '#1D9567',
					fillOpacity: 0.35,
					draggable:false,
					draggingCursor:null,
					
					});
					geoFencingAreaArr[i] = new window.google.maps.Polygon({
					paths: geoFencingObj,
					editable: true,
					strokeColor: '#1D9567',
					strokeOpacity: 0.70,
					strokeWeight: 0,
					fillColor: '#1D9567',
					fillOpacity: 0.35,
					draggable:false,
					draggingCursor:null,
					});
				console.log("geoFencingArea[i] ",geoFencingAreaArr[i]);
				//geoFencingArea.setMap(map);
				geoFencingAreaArr[i].setMap(map);
				fencingLength=geoFencingObj;
				console.log("test ",geoFencingArea.getPath());
				// geoFencingAreaArr.push(geoFencingArea);
				console.log("fencingLength ",fencingLength)
				window.google.maps.event.addListener(geoFencingAreaArr[i].getPath(),'insert_at',getPolygonCoords);
				window.google.maps.event.addListener(geoFencingAreaArr[i].getPath(),'set_at', getPolygonCoords);
			
			 
		
		  }
		 	
  		// for(var j=0;j<geoFencingAreaArr.length;j++){
		// 	window.google.maps.event.addListener(geoFencingAreaArr[j].getPath(), 'set_at', getnewPolygonCoords);
		//   }
	      var polyOptions = {
            strokeWeight: 0,
            fillOpacity: 0.70,
            editable: true
          };
          // Creates a drawing manager attached to the map that allows the user to draw
          // markers, lines, and shapes.
          drawingManager = new window.google.maps.drawing.DrawingManager({
			
            drawingMode: null,
            drawingControlOptions: {
              drawingModes: [
                window.google.maps.drawing.OverlayType.POLYGON
			  ],	
			  
            },
            markerOptions: {
              draggable: true
            },
            polylineOptions: {
              editable: true
            },
            rectangleOptions: polyOptions,
            circleOptions: polyOptions,
            polygonOptions: polyOptions,
            map: map
          });

          window.google.maps.event.addListener(drawingManager, 'overlaycomplete', function(e) {
              if (e.type != window.google.maps.drawing.OverlayType.MARKER) {
              // Switch back to non-drawing mode after drawing a shape.
            //   drawingManager.setDrawingMode(null);
			  // To hide:
			//   drawingManager.setOptions({drawingMode: null, drawingControl: false});
              drawingManager.setOptions({
                drawingControl: true
              });

              // Add an event listener that selects the newly-drawn shape when the user
              // mouses down on it.
              var newShape = e.overlay;
              newShape.type = e.type;
              window.google.maps.event.addListener(newShape, 'click', function() {
                setSelection(newShape);
              });
              setSelection(newShape);
              console.log("selectedShape",newShape);
  			  fencingLength = selectedShape.getPath().getLength();
  			  //console.log("fencingLength ", fencingLength)
  				var latlngObj=[];
	  			var htmlStr = "";
	  	        for (var i = 0; i < fencingLength; i++) {
	  				var each_lat_lng = selectedShape.getPath().getAt(i).toUrlValue(5).split(',');
	  				var in_latlng_array={"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
	  				latlngObj.push(in_latlng_array);
				}
				coordinates.push(latlngObj);  
	  			
            }
              
          });
          
        var bounds = new window.google.maps.LatLngBounds();
		var i1;
		for(i1=0;i1<geoFencingObj.length;i1++){
			bounds.extend(new window.google.maps.LatLng(geoFencingObj[i1].lat,geoFencingObj[i1].lng));
		}
		try{
			if(bounds.Za.i != 1 && bounds.Ua.i !== 180 )
			{
				map.fitBounds(bounds);
			}
		}catch(e){console.log(e)}
		
		
		
          // Clear the current selection when the drawing mode is changed, or when the
          // map is clicked.
  		window.google.maps.event.addListener(drawingManager, 'drawingmode_changed', function(){
  			clearSelection()
  		});
  		window.google.maps.event.addListener(map, 'click', function(){
  			clearSelection()
  		});
        
        //   window.google.maps.event.addDomListener(document.getElementById('delete-button'), 'click', function(){
        // 		deleteSelectedShape()
		// });
        
        
  		
      //buildColorPalette();
  		map.addListener('zoom_changed', function() {
            mapZoom=map.getZoom();
          });
  		
  		/*window.google.maps.event.addDomListener(document.getElementById('saveGeo'), 'click', function(){
  			getSelectedShape()
  		});*/
  		
		  console.log("coordinates", coordinates);

    }
    
    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
		   
	}
    
    fenceTypeHandler = (event) => {
    	console.log(event.target)
    }

	changeCarrier = geo_type => {

    	this.setState(
	      { geo_type },
	      () => console.log(`Option selected:`, this.state.carrier)
	    );
	}

    changeHandler = (event) => {
    	this.setState({
		  carrier:event.value
		});
    }
    
    checkHandler = (event) => {
    	
    	let name = event.target.name;
		let value = event.target.value;
    	this.setState({[name]:value});
    }
    changeInputHandler = (event) => {
    	let name = event.target.name;
		let value = event.target.value;
    	this.setState({[name]:value});
    }
    
    
    checkNotifyHandler = (event) => {
    	let name = event.target.name;
		let value = event.target.value;
    	this.setState({[name]:value});
    }
    
    applyCoords =(e) =>{
    	console.log(e);
    }
    
	formHandler = (event) => {
			
		event.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmitted,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		var notifytransporter = $("#notifyTransporter").prop("checked");
		if(notifytransporter){
			notifytransporter = "yes";
		}
		else{
			notifytransporter = "no";
		}
		var isEnabled = $("#isEnabled").prop("checked");
		if(isEnabled){
			isEnabled = 1;
		}
		else{
			isEnabled = 0;
		}
		console.log(coordinates,"coordinates - to be updated")
		if(coordinates.length == 0)
		{
			coordinates = this.state.coordinates;
		}
		if(coordinates.length > 0)
		{
			if(coordinates[0].length >= 3)
			{
				this.setState({
					loadshow:"show-m",
					overlay:"show-m",
				})
				var reqparams = {
					rowId : this.state.editedEventID,
					location_name : this.state.location_name,
					//dept_code : depts,
					coordinates : coordinates,
					carrier_type : this.state.carrier,
					notifytransporter : notifytransporter,
					msil_notification_emails : this.state.msil_notification_emails,
					isEnabled : isEnabled,
					user_email : localStorage.getItem("email")
				}
				// console.log(coordinates);
				redirectURL.post("/consignments/updateNoParkingZones",reqparams).then((response)=>{
					console.log(response.data.message);
					if(response.data.message == "success"){
						this.setState({
							basicType:"success",
							basicTitle : "Data Updated Successfully",
							show : true
						})
						setTimeout(() => {
							window.location.href=this.state.redirectPath;
						}, 2000)
					}
					else
					{
						this.setState({
							basicType:"warning",
							basicTitle : "Failed",
							show : true,
							loadshow:"show-n",
							overlay:"show-n",
						});
					}
				}).catch(function(error){
					console.log("Error");
				});
			}
			else
			{
				this.setState({
					show:true,
					basicType : "danger",
					basicTitle : "Select Atleast 3 Coordinates"
				})
			}			
		}
		else
		{
			this.setState({
				show:true,
				basicType : "danger",
				basicTitle : "Select Atleast One Area" 
			})
		}
		
		
	}
	closeAlert = () => {
        this.setState({
            show: false
        });
	}
	disableevent(){
		var reqparams = {
			event : document.getElementById("event-id").value,
			editedEventID : this.state.editedEventID,
			dept_code : this.state.dept_code
		}
		console.log(reqparams);
		redirectURL.post("/weather/disableEvent",reqparams).then((response)=>{
			if(response.data.message == "success"){
				this.setState({
					basicType : "success",
					basicTitle : "Disabled Event",
					show:true
				});
			}
		}).catch(function(error){
			console.log(error);
		})
	}
	onRowClicked(params) {
		if(params.colDef.headerName == "Event Status")
		{
			// console.log("IN onRowClicked, params = ",params);
			selectedRowData = params.data;
		}
		else{
			console.log("Other cells");
		}
	}

	handleFilteronLoad(){
    	// console.log("filter clicked");
		// e.preventDefault();
		
		this.setState({
			loadshow:'show-m',
		});
		//console.log("Here ",this.props.match.url);
		var dept;
		if(this.props.match.url == '/nearbyprttrucks')
		{
			dept='LOG-PRT'
		}

		if(this.props.match.url == "/nearbysndtrucks")
		{
			dept='SNDG'
		}
		if(this.props.match.url == "/nearbysctrucks")
		{
			dept='LOG-SC'
		}
		
		var transcode = "ALL";
		
		var trip_type = this.state.triptype.value;
		
		var formdata = {
			radius:3000,
			latitude:"20.73568784876339",
			longitude:"79.38999444931353",
			dept_code:this.state.dept_code,
			transporter_code:"ALL",
			status:"0",
			trip_type : trip_type
		}
		// console.log(formdata);
		var rlatitude="20.73568784876339";
		var rlongitude="79.38999444931353";
		redirectURL.post('/vicinity/getRadiusCoordinates',formdata,{
			headers:{
				'Content-Type':'application/json',
				// 'Access-Control-Allow-Origin': '*',
				// 'Access-Control-Allow-Methods': 'PUT, GET, POST, DELETE, OPTIONS',
				'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept, Authorization"

			}
		})
		.then(
			(response) =>{
			//console.log("response", JSON.parse(response.data.body));
		
			var markers = JSON.parse(JSON.parse(response.data.apiData.body).trucks_data);
			console.log(JSON.parse(response.data.apiData.body))

			if(markers.length > 0)
			{
				this.setState({
					weatherData: response.data.weatherData,
					// defaultradius:formdata.radius,
					// truckscoordinates:markers,
					// onTripCounter : JSON.parse(response.data.apiData.body).gps_trucks,
					// mwidth:"col-xl-12 col-lg-12",
					// rowData:markers,
					// radius:formdata.radius,
					loadshow:'show-n',
					showform:'show-n',
				});
				// redirectURL.post("/weather/getPlants").then((rsp)=>{
				// 	this.setState({
				// 		plantLocations : rsp.data,
				// 	});
				// 	console.log(rsp.data);
				// 	this.renderMap();
				// })
				
				
			}
			else{
				// this.setState({
				// 	show: true, basicType:'warning', basicTitle:"No Data found.",
				// 	loadshow:'show-n',
				// 	showform:'show-m',
				// });
				
			}
		})
		.catch(function(error){
			console.log(error);
		});
		
		this.renderMap();
	}
	handleFilter(){
    	// console.log("filter clicked");
		// e.preventDefault();
		
		this.setState({
			loadshow:'show-m'
		});
		//console.log("Here ",this.props.match.url);
		var dept;
		if(this.props.match.url == '/nearbyprttrucks')
		{
			dept='LOG-PRT'
		}

		if(this.props.match.url == "/nearbysndtrucks")
		{
			dept='SNDG'
		}
		if(this.props.match.url == "/nearbysctrucks")
		{
			dept='LOG-SC'
		}
		
		var transcode = "ALL";
		
		var trip_type = this.state.triptype.value;
		
		var formdata = {
			radius:3000,
			latitude:"20.73568784876339",
			longitude:"79.38999444931353",
			dept_code:this.state.dept_code,
			transporter_code:"ALL",
			status:"0",
			trip_type : trip_type
		}
		// console.log(formdata);
		var rlatitude="20.73568784876339";
		var rlongitude="79.38999444931353";
		redirectURL.post('/vicinity/getRadiusCoordinates',formdata,{
			headers:{
				'Content-Type':'application/json',
				// 'Access-Control-Allow-Origin': '*',
				// 'Access-Control-Allow-Methods': 'PUT, GET, POST, DELETE, OPTIONS',
				'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept, Authorization"

			}
		})
		.then(
			(response) =>{
			//console.log("response", JSON.parse(response.data.body));
		
			var markers = JSON.parse(JSON.parse(response.data.apiData.body).trucks_data);
			console.log(JSON.parse(response.data.apiData.body))

			if(markers.length > 0)
			{
				this.setState({
					weatherData: response.data.weatherData,
					defaultradius:formdata.radius,
					truckscoordinates:markers,
					onTripCounter : JSON.parse(response.data.apiData.body).gps_trucks,
					mwidth:"col-xl-12 col-lg-12",
					rowData:markers,
					radius:formdata.radius,
					//loadshow:'show-n',
					showform:'show-n',
					showrefresh:'show-m',
					rlatitude:formdata.latitude,
					rlongitude:formdata.longitude,
				});
				redirectURL.post("/weather/getPlants").then((rsp)=>{
					this.setState({
						plantLocations : rsp.data,
					});
					this.renderMap();
				})
				
				
			}
			else{
				this.setState({
					show: true, basicType:'warning', basicTitle:"No Data found.",
					loadshow:'show-n',
					showform:'show-m',
				});
				
			}
		})
		.catch(function(error){
			console.log(error);
		});
		
		this.renderMap();
	}
	setTripType = triptype =>{
		this.setState(
			{ triptype },
			() => {
				console.log(`Option selected:`, this.state.triptype)
				this.handleFilter()
				
				setTimeout(() => {
					this.setState({
						loadshow:"show-n",
						overlay:"show-n",
					})
				}, 10000)
			}
		  );
	}
	onClickShowTrucks = async () =>{
		// console.log("Here again ", this.state.showtruckopts)
		await this.setState({
			setshow:!this.state.setshow
		})
		await this.onLOadShowTrucks();	
	}
	onLOadShowTrucks(){
		if(this.state.setshow == true)
		{
			console.log(1);
			this.setState({
				showtruckopts:"show-m",
				loadshow:"show-m",
				overlay:"show-m",
			})
			this.handleFilter();
			setTimeout(() => {
				this.setState({
					loadshow:"show-n",
					overlay:"show-n",
				})
			}, 10000)
		}
		else
		{
			console.log(2);

			this.setState({
				showtruckopts:"show-n",
				weatherData: this.state.weatherData,
				defaultradius:this.state.defaultradius,
				plantLocations : [],
				truckscoordinates:[]
			})
			this.renderMap();
		}
	}
	
	onClickWeatherForecast(e){
	
		this.setState({displayWeatherForecast: !this.state.displayWeatherForecast});
		// weather forecast
		if(!this.state.displayWeatherForecast)
		{
			var weatherMarkers = [];
			this.state.weatherData.map(function(params)
			{            
				if(params.address.includes("NA") == false && params.address != "/")
				{
					// bounds.extend(new window.google.maps.LatLng(params.latitude,params.longitude));
					var url="";
					var iconName = "";
					
					if(params.description.includes("Thunderstorm") == true){
						url = require("../../assets/icons/thunderstorm.png")
					}
					else if(params.description.includes("Heavy Rain and Strong Winds") == true){
						url = require("../../assets/icons/wind.png")
					}
					else if(params.description.includes("Heavy Rain") == true){
						url = require("../../assets/icons/Heavyrain.png")
					}
					else if(params.description.includes("Cyclone") == true){
						url = require("../../assets/icons/cyclone.png")
					}
					else if(params.description.includes("Hailstroms") == true){
						url = require("../../assets/icons/hailstorm.png")
					}
					else if(params.description.includes("Sandstorm") == true || params.description.includes("Duststorm") == true){
						url = require("../../assets/icons/sandstorm.png")
					}
					else
					{
					
					}
					var iconName = {
						url: url, // url
						scaledSize: new window.google.maps.Size(32, 32), // scaled size
						origin: new window.google.maps.Point(0,0), // origin
						anchor: new window.google.maps.Point(0, 0) // anchor
					};
					
					var marker = new window.google.maps.Marker({
						position: new window.google.maps.LatLng(params.latitude,params.longitude),
						map: map,
						icon: iconName,
					// title : ""+params.address+","+params.forecast_date+","+params.description
					});
					var infowindow = new window.google.maps.InfoWindow({
						content: "<div class='weather-marker-tooltip'><div class='forecast-date'><label class='forecast-labels'> Forecast Date : </label> "+moment.parseZone(new Date(params.forecast_date)).format("DD-MMM-YYYY")+"</div><div class='address'> <label class='forecast-labels'>Address :</label> "+params.address+"</div><div class='weather-desc'><label class='forecast-labels'> Weather : </label> "+params.description+"</div><div class='last-updated'><label class='forecast-labels'> Last Updated On : </label> "+getHyphenDDMMMYYYYHHMM(params.last_updated_on)+"</div>"
					});
					window.google.maps.event.addListener(marker, 'mouseover', function() {
						infowindow.open(map,marker);
					});
					window.google.maps.event.addListener(marker, 'mouseout', function() {
						infowindow.close();
					});
					marker.setVisible(true)
					weatherMarkers.push({
						"marker": marker,
						"desc": params.description,
						"url": url
					})
				}
			});
			this.setState({
				weatherMarkers: weatherMarkers
			})
		}
		else{
			if(this.state.weatherMarkers.length){
				this.state.weatherMarkers.map((e)=>{
					e.marker.setMap(null);
				})
				this.setState({
					weatherMarkers: []
				})
			}
		}
	}

	
	onClickFilterWeather(clickedType)
	{
		var weatherMarkers = this.state.weatherMarkers;
		if(clickedType == 1){
			weatherMarkers.map((e)=>{
				e.marker.setVisible(true);
			})
		}
		else if(clickedType == 2)
		{
			weatherMarkers.map((e)=>{
				if (e.desc == "Heavy Rain")
				{
					e.marker.setVisible(true);
				}
				else 
				{
					e.marker.setVisible(false);
				}
			})
		}
		else if(clickedType == 3)
		{
			weatherMarkers.map((e)=>{
				if (e.desc.includes("Winds"))
				{
					e.marker.setVisible(true);
				}
				else 
				{
					e.marker.setVisible(false);
				}
			})
		}
		else if(clickedType == 4)
		{
			weatherMarkers.map((e)=>{
				if (e.desc.includes("Thunderstorm"))
				{
					e.marker.setVisible(true);
				}
				else 
				{
					e.marker.setVisible(false);
				}
			})
		}
		else if(clickedType == 5)
		{
			weatherMarkers.map((e)=>{
				if (e.desc.includes("Hailstrom"))
				{
					e.marker.setVisible(true);
				}
				else 
				{
					e.marker.setVisible(false);
				}
			})
		}
		else if(clickedType == 6)
		{
			weatherMarkers.map((e)=>{
				if (e.desc.includes("Cyclone"))
				{
					e.marker.setVisible(true);
				}
				else 
				{
					e.marker.setVisible(false);
				}
			})
		}
		else if(clickedType == 7)
		{
			weatherMarkers.map((e)=>{
				if (e.desc.includes("Sandstorm"))
				{
					e.marker.setVisible(true);
				}
				else 
				{
					e.marker.setVisible(false);
				}
			})
		}
	}

    render(){

    	const dStyles={
				width:'100%',
				height:'550px'
		}
		const wstyles={
			"paddingTop": '12px',
			"paddingRight": '29px',
			"paddingBottom": '2px',
		}
    	
        
        return (
            <div className="container-fluid">
				<SweetAlert
				show={this.state.show}
				type={this.state.basicType}
				title={this.state.basicTitle}
				onConfirm={this.closeAlert}
				>
				</SweetAlert>
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
		            	
			                <div className="card-header">
				       			<h5>
									<i className="icon-map cus-i"></i><span>Edit No Parking Zone</span>
									<a className="btn btn-warning" href={this.state.redirectPath} style={{padding: "9px",float: "right",width: "87px"}}><i className="fa fa-arrow-left"></i> Back</a>
				       			</h5>
				   			</div>
				   			<div className="row weather-alert-card" style={wstyles}>
								<form className=" theme-form col-xl-4 col-lg-4" onSubmit={this.formHandler}>
	                    			<div className="col-xl-12 col-lg-12">
										<div className="form-group"> 
                                            <label className="c-lbl">Location Name *:</label>
                                            <input type="text" name="location_name" id="location_name" value={this.state.location_name} onChangeCapture={this.checkHandler}className="form-control" placeholder="Location Name"  required />
                                        </div>
										
										{/* {(this.state.dept_code == "")?
											<div className="form-group">
												<label>Select Department: </label>
												<Select 
												placeholder={"Select Department"}
												closeMenuOnSelect={true}
												isMulti={false}
												onChange={this.saveDeptState.bind(this)} 
												className={"border-radius-0"}
												style={{borderRadius:"0px"}}
												options={this.selectDept()} />
											</div>
										:""} */}
										<div className="row">
										  	<div className="col-sm-6">
												<div className="form-group">
													<label className="c-lbl">Carrier Type *:</label>
													<Select onChange={this.changeHandler.bind(this)} id="select-carrier-id" value={{value:this.state.carrier,label:this.state.carrier}} style={{borderRadius:"0px"}} options={carrieroptions} required />
												</div>
											</div>
										</div>
                                        <div className="form-group">
					               			<label className="c-lbl">Notify Transporter : </label>
					               			&nbsp;&nbsp;
											
											<input type="checkbox" name="notifyTransporter" id="notifyTransporter" />
											
					               	   </div>
										<div className="form-group">
					               			<label className="c-lbl">Is Enabled : </label>
					               			&nbsp;&nbsp;
											
											<input type="checkbox" name="isEnabled" id="isEnabled" />
											
					               	   </div>
										<div className="form-group"> 
                                            <label className="c-lbl">MSIL Notification Emails *:</label>
                                            <input type="text" name="msil_notification_emails" id="msil_notification_emails" onChange={this.changeInputHandler}  value={this.state.msil_notification_emails} className="form-control" placeholder="MSIL Notification Emails"  required />
											<h5 style={{fontWeight:"bold",color:"red",fontSize:"14px",marginTop:"2%"}}>Note: MSIL Notification Emails should be provided in comma ' , ' delimited only</h5>
                                        </div>
										<div className="form-group">
					               			<button type="submit" className="btn btn-success cs-btn" id="saveevent">Save</button>
					               			<a href={this.state.redirectPath} className="btn btn-info cs-btn">Cancel</a>
					               		</div>
									</div>
									
				           		</form>
				           		<div className="col-xl-8 col-lg-8">
								
									{this.state.erroMessage == '' ? '':
											<div className="alert alert-danger"></div>
									}
									  
				               		<div id="map_canvas" className="col-xl-12 col-lg-12" style={dStyles}></div>
				 	               <div className="col-xl-12 col-lg-12">
				 					  <input type="hidden" name="coords" id="coords" value={this.state.coords} />
				 				   </div>
				               		
			               		</div>
			           		</div>
		           		</div>
	           		</div>
                </div>
				<input type="text" name="search" className="col-xl-6 col-lg-6 form-control mt-5p" id="search" placeholder="Enter Address to search" />
				<div className={"dataLoadpage " +(this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
                
        );
    }
}
function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
                   
function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = Math.floor(hours);
	var minutes = (n) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}                              
var currentinfowindow = null;
function getTimeInfo(marker,timedet,coords)
{
	var timeinfo = new window.google.maps.InfoWindow({
          content: "Time at this location: " + timedet
        });

	marker.addListener('click', function() {
		
				if (currentinfowindow != null) {
					currentinfowindow.close();
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				else
				{
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				
        });
}


 function clearSelection() {
	    if (selectedShape) {
	      selectedShape.setEditable(false);
	      selectedShape = null;
	    }
	  }

	 function setSelection(shape) {
	    clearSelection();
	    selectedShape = shape;
	    //console.log("selectedShape",selectedShape);
	    shape.setEditable(true);
	    
	    selectColor(shape.get('fillColor') || shape.get('strokeColor'));
	  }

  function deleteSelectedShape() {
		fencingLength=0;  
	    if (selectedShape) {
	      selectedShape.setMap(null);
	    }
	    // To show:
	     drawingManager.setOptions({
	       drawingControl: true
	     });
	  }

	  
	 function selectColor(color) {
	      selectedColor = color;
	      for (var i = 0; i < colors.length; ++i) {
	        var currColor = colors[i];
	        //console.log(colorButtons[currColor]);
	        //colorButtons[currColor].style.border = currColor == color ? '1px solid #789' : '1px solid #fff';
	      }

	      // Retrieves the current options from the drawing manager and replaces the
	      // stroke or fill color as appropriate.
	      var polylineOptions = drawingManager.get('polylineOptions');
	      polylineOptions.strokeColor = color;
	      drawingManager.set('polylineOptions', polylineOptions);

	      var rectangleOptions = drawingManager.get('rectangleOptions');
	      rectangleOptions.fillColor = color;
	      drawingManager.set('rectangleOptions', rectangleOptions);

	      var circleOptions = drawingManager.get('circleOptions');
	      circleOptions.fillColor = color;
	      drawingManager.set('circleOptions', circleOptions);

	      var polygonOptions = drawingManager.get('polygonOptions');
	      polygonOptions.fillColor = color;
	      drawingManager.set('polygonOptions', polygonOptions);
	    }

	function setSelectedShapeColor(color) {
	      if (selectedShape) {
	        if (selectedShape.type == window.google.maps.drawing.OverlayType.POLYLINE) {
	          selectedShape.set('strokeColor', color);
	        } else {
	          selectedShape.set('fillColor', color);
	        }
	      }
	    }

	  function makeColorButton(color) {
	      var button = document.createElement('span');
	      button.className = 'color-button';
	      button.style.backgroundColor = color;
	      window.google.maps.event.addDomListener(button, 'click', function() {
	        selectColor(color);
	        setSelectedShapeColor(color);
	      });

	      return button;
	    }

	  function buildColorPalette() {
	       var colorPalette = document.getElementById('color-palette');
	       for (var i = 0; i < colors.length; ++i) {
	         var currColor = colors[i];
	         var colorButton = makeColorButton(currColor);
	         colorPalette.appendChild(colorButton);
	         colorButtons[currColor] = colorButton;
	       }
	       selectColor(colors[0]);
	     }  
	  
	 function getSelectedShape(coords){
		var lt=[]
		//console.log("coordsLatlngs ",coords);
		for (let value of Object.values(coords)) {
		  //console.log("Val is ",value); // John, then 30
		  lt.push(value)
		}
		//console.log("Combine ",lt);
		document.getElementById('coords').value=lt;
		//return lt;
	}


    function loadDateTimeScript(){
        // alert("timeDate");
        $('.datetimepicker_mask').datetimepicker({
            //mask:'39-19-9999 29:59',
            format:'d-m-Y H:i',
        });
        $('.datetimepicker_date').datetimepicker({
            //mask:'39-19-9999',
            format:'d-m-Y',
            timepicker:false
        });
        var index  =window.document.getElementsByTagName("script")[1]
        var script = window.document.createElement("script")
        script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
        //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
        script.async=true
        script.defer = true
        index.parentNode.insertBefore(script,index)
    }

$(document).ready(function(){
    $(document).on("keyup","#notifyFirst",function(e){
        // alert($(this).val());
        if(e.which === 32){
            $(this).val($(this).val()+", ");
        }

	});
	$(document).on("keyup","#notifyFirstcc",function(e){
        // alert($(this).val());
        if(e.which === 32){
            $(this).val($(this).val()+", ");
        }

	});
	$("#addGeoFence").click(function(){
		$(".weather-grid").css("display","none");
		$(".weather-alert-card").css("display","flex");
		$(this).css("display","none");
	});
	$(document).on("keyup","#notify-duration-id",function(){
		if($(this).val() < 1)
		{
			$(this).val("");
		}
	})
});

function getPolygonCoords()
{
	// console.log("function ", geoFencingAreaArr);
	if(geoFencingAreaArr.length > 0)
	{
		coordinates = [];
		for(var g=0; g<geoFencingAreaArr.length;g++)
		{

			var len = geoFencingAreaArr[g].getPath().getLength();
			console.log(len);
			var htmlStr = "";
			var newcoordinates=[];
			for (var i = 0; i < len; i++) {
				// var latlngd = {"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
				// coordinates.push()
				var strlatlng = geoFencingAreaArr[g].getPath().getAt(i).toUrlValue(5);
				var spliting = strlatlng.split(",");
				var latlngd = {"lat":parseFloat(spliting[0]),"lng":parseFloat(spliting[1])};
				newcoordinates.push(latlngd);
			// htmlStr += "new google.maps.LatLng(" + currFencing.getPath().getAt(i).toUrlValue(5) + "), ";
			//Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
			//htmlStr += "" + currFencing.getPath().getAt(i).toUrlValue(5);
			}
			coordinates.push(newcoordinates)
		}
		console.log(coordinates);
	}
	// var len = geoFencingAreaArr.getPath().getLength();
	// console.log(len);
	// var htmlStr = "";
	// var newcoordinates=[];
	// for (var i = 0; i < len; i++) {
	// 	// var latlngd = {"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
	// 	// coordinates.push()
	// 	var strlatlng = geoFencingArea.getPath().getAt(i).toUrlValue(5);
	// 	var spliting = strlatlng.split(",");
	// 	var latlngd = {"lat":parseFloat(spliting[0]),"lng":parseFloat(spliting[1])};
	// 	newcoordinates.push(latlngd);
	// // htmlStr += "new google.maps.LatLng(" + currFencing.getPath().getAt(i).toUrlValue(5) + "), ";
	// //Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
	// //htmlStr += "" + currFencing.getPath().getAt(i).toUrlValue(5);
	// }
	// coordinates.push(newcoordinates)
	// console.log("htmlStr", coordinates);
	
}

function getnewPolygonCoords()
{
	console.log("function");
	var len = geoFencingArea.getPath().getLength();
	console.log(len);
	// 	var htmlStr = "";
	var newcoordinates=[];
	for (var i = 0; i < len; i++) {
		// var latlngd = {"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
		// coordinates.push()
		var strlatlng = geoFencingArea.getPath().getAt(i).toUrlValue(5);
		var spliting = strlatlng.split(",");
		var latlngd = {"lat":parseFloat(spliting[0]),"lng":parseFloat(spliting[1])};
		newcoordinates.push(latlngd);
	// htmlStr += "new google.maps.LatLng(" + currFencing.getPath().getAt(i).toUrlValue(5) + "), ";
	//Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
	//htmlStr += "" + currFencing.getPath().getAt(i).toUrlValue(5);
	}
	coordinates.push(newcoordinates)
	// console.log("htmlStr", coordinates);
	
}


