/* eslint-disable no-redeclare */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";


import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import KpiActions from '../manage/kpiActionsComponent';
import Consignmentforceclose from '../manage/consignmentforceclose.js';
import Select from 'react-select';

import Modal from 'react-responsive-modal';
import CustomLoadingCellRenderer from "./customLoadingCellRenderer";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import Counters from './railcountersComponent';
import CountUp from 'react-countup';
import DeliverCounters from './raildelivercountersComponent';
import DateFormater from './dateComponent';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getHyphenDDMMMYYYYHHMM, getYYYYMMDDHHMMSS, getDDMMMYYYYHHMMDefault, getHyphenDDMMMYYYY, getHyphenYYYYMMDDHHMMSS } from '../common/utils';
import 'react-vertical-timeline-component/style.min.css';
import DrawMap from '../common/drawmap';
import ConsignmentDrawMap from '../common/consignmentdrawmap';
import CustomDateComponent from '../common/dateFilterComponent';
import ComponentIndex from '../common/appendComponents';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import CSVFileValidator from 'csv-file-validator'
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
// import datetimepicker from 'jquery-datetimepicker';

import IntransitToLoadingYard from './intransittoloadingaction';
import RakeAttach from './rakeattachaction';
import IntransitToDestinationYard from './intransittodestinationaction';
import AtDestination from './atdestinationaction';
import DeliverRail from './deliverrailaction';
import Deliverconsignment from './deliverconsignment';
import { parseDragMeta } from '@fullcalendar/react';
//import * as XLSX from 'xlsx';

// $.datetimepicker.setLocale('en');
var infoBox = require('../common/google-infowindow');

var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749', '#F73E3E', '#B7B3B3'];
var selectedColor;
var colorButtons = {};
var fencingLength = 0;
var mapZoom = 12;
var coordinates = [];
var geoFencingArea;
var containerslist = [];
export default class ManageRailConsignments extends Component {

	constructor(props) {
		super(props);
		this.displayData = [];
		this.state = {
			pageTitle: "",
			deptcode: "",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			eventAction: null,
			allRowData: [],
			activeFilter: null,
			uploadDivWidth: '0%',
			sliderTranslate: '',
			sliderRouteTranslate: '',
			sliderBulkForceClose: "",
			tabsliderTranslate: '',
			timelineinfo: '',
			consignment_code: '',
			mapinfo: '',
			dealer: '',
			maptruckno: '',
			loadshow: 'show-n',
			showDiv: 'show-n',
			alertshow: 'fade',
			alertmg: 'show-n',
			alerterrmg: 'show-n',
			overly: 'show-n',
			alerterrshow: 'fade',
			alert: null,
			show: false,
			basicTitle: '',
			rownode: '',
			leg_no: '',
			basicType: "default",
			alert1: null,
			show1: false,
			basicTitle1: '',
			basicType1: "default",
			uploadYard : '',
			modules: AllModules,
			filterstatess: {},
			columnsdatas: {},
			rowgroupdcols: [],
			valuecolumns: [],
			activeconsignemnt: [],
			co2_emission_total:'',
			co2_saved:'',
			podtptdelivery: [],
			podtptdeliverycnt: 0,
			activetrucks: [],
			transitdelays: [],
			gpsdatana: [],
			overspeedtrucks: [],
			nightdrive: [],
			open: false,
			openforceclose: false,
			pivotmodeis: false,
			pivotcols: [],
			defTransitCoords: '',
			routeTruck: {},
			sidebarSubHeader: '',
			googelRoutes: '',
			plants: [],
			defaultColDef: {
				sortable: true,
				filter: true,
				resizable: true,
			},
			rowData: null,
			maprowData: null,
			rowSelection: 'multiple',
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			overlayNoRowsTemplate: "",
			frameworkComponents: {
				consignmentActions: ConsignmentActions,
				kpiActions: KpiActions,
				dateFormater: DateFormater,
				customLoadingCellRenderer: CustomLoadingCellRenderer,
				consignmentforceclose: Consignmentforceclose,
				customDateComponent: CustomDateComponent,
				intransitToLoadingYard: IntransitToLoadingYard,
				rakeattach: RakeAttach,
				intransitToDestinationYard: IntransitToDestinationYard,
				atDestination: AtDestination,
				deliverRail: DeliverRail,
				deliverconsignment: Deliverconsignment
			},

			animateRows: true,
			debug: true,
			showToolPanel: false,
			uppressAggFuncInHeader: true,

			childconfs: [],
			childrow: [],
			rowModelType: "serverSide",
			paginationPageSize: 50,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
						statusPanel: "agTotalAndFilteredRowCountComponent",
						align: "left"
					},
					{
						statusPanel: "agTotalRowCountComponent",
						align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
						id: "columns",
						labelDefault: "Columns",
						labelKey: "columns",
						iconKey: "columns",
						toolPanel: "agColumnsToolPanel"
					},
					{
						id: "filters",
						labelDefault: "Filters",
						labelKey: "filters",
						iconKey: "filter",
						toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams: {},
			rowClassRules: {
				//"highlitRow": "data.transit_delay == 1",
				//"green":"data.status >= 3"

			},
			triptype: '{value: "all", label: "All"}',
			tripvalue: '',
			consigner: [{ "value": "all", "label": "All" }],
			destination_yard: [{ "value": "all", "label": "All" }],
			originalData: [],
			isCounterDisplay: 1,
			screenpage: '',
			pagetitle: 'Rail Consignments',
			countersjson: '',
			originalcountersjson: '',
			consigneecoords: '',
			consigner_coordinates: '',
			containerslist: [],
			forceclosedata: '',
			reached_dealer_time: moment.parseZone().format('YYYY-MM-DD'),
			reachedhh: '',
			reachedmm: '',
			reachedss: '00',
			left_dealer_time: moment.parseZone().format('YYYY-MM-DD'),
			lefthh: '',
			leftmm: '',
			leftss: '00',
			invoice_time: '',
			invoicehh: '',
			invoicemm: '',
			invoicess: '00',
			reasonforceclose: '',
			sliderTranslatesidebar: '',
			consignment_details: '',
			rakeslist: [],
			loadingpoints: [],
			destinationpoints: [],
			rakeitem: '',
			sliderRakeTranslate: '',
			destinationpoint: { label: '', value: '' },
			loadpoint: { label: '', value: '' },
			loadyardpoint: '',
			dispatch_Date: '',
			intransittoload: [],
			rakeattach: [],
			rakeasign: [],
			intransittodestinate: [],
			atdestination: [],
			forcecls: [],
			deliver: [],
			transittodealer: [],
			intransittoloadcnt: 0,
			rakeattachcnt: 0,
			intransittodestinatecnt: 0,
			atdestinationcnt: 0,
			forceclscnt: 0,
			delivercnt: 0,
			transittodealercnt: 0,
			rakeasigncnt: 0,
			file: '',
			csvcontent: [],
			csvdelivercontent: [],
			sliderForceDestination: '',
			sliderForceLoadingYard: '',
			sliderBulkEditConsignments : '',
			dest_yard_reachedon: '',
			rr_number: '',
			msilpodreceive: [],
			msilpodreceivecnt: 0,
			railtransitdelays: [],
			railtransitdelayscnt: 0,
			usergridstate: [],
			screenurl: "",
			screentitle: "",
			transporterURLPath: "",
			isdeliverreason: "",
			deliverdate: '',
			deliverymaxDate: "",
			deliveryminDate: "",
			displayReasonField: "show-n",
			selectedReason: "",
			destinationOptions: [],
			lastMileTruckCounters: [],
			userType: "regular",
			filterCities: [],
			filterStates: [],
			filterClusters: [],
			filterTransporters: [],
			beforeNewFilterRowData: [],
			beforeNewFiltercountersjson: [],
			flCity: "",
			flState: "",
			flCluster: "",
			flTransporter:"",
			missingTransporter : [{label:"ADANI LOGISTICS LTD" , value:"T226"}],
			consBtn: 'btn-danger',
			missingConsBtn: 'btn-default',
			showTab: 'show-m',
			csvcontent1: '',
			file1: '',
			csvcontentBulkEditDeliveredConsignments : [],
			deliveredConsFile : '',
			allmissing_Data : [],
			missing_rake_delivery_point : [],
			missing_last_mile_dispatch_date : [],
			tptConformDelivery : [],
			activeBtn: "",
			deliveredBtn: "",
		}
		this.onShowRouteDiv = this.onShowRouteDiv.bind(this);
		this.onShowTimelineDiv = this.onShowTimelineDiv.bind(this);
		this.onShowRouteMap = this.onShowRouteMap.bind(this);
		this.onShowGoogleRoute = this.onShowGoogleRoute.bind(this);
		this.selectConsignerOptionsItems = this.selectConsignerOptionsItems.bind(this);
		this.onClickShowMapView = this.onClickShowMapView.bind(this);
		this.onClickForceClose = this.onClickForceClose.bind(this);
		this.onShowTransitLoadLegs = this.onShowTransitLoadLegs.bind(this);
		this.onShowRakeLegs = this.onShowRakeLegs.bind(this);
		this.onShowIntransitDestinationLegs = this.onShowIntransitDestinationLegs.bind(this);
		this.onShowAtDestinationLegs = this.onShowAtDestinationLegs.bind(this);
		this.onShowDeliveredLegs = this.onShowDeliveredLegs.bind(this);
		this.changeFileHandler = this.changeFileHandler.bind(this);
		this.uploadBulkFormHandler = this.uploadBulkFormHandler.bind(this);
		this.onLoadShowRails = this.onLoadShowRails.bind(this);
		this.uploadBulkDeliverFormHandler = this.uploadBulkDeliverFormHandler.bind(this);

	}
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
			} else {
				console.log("GA disabled...");
			}
		} catch (error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
	componentDidMount() {
		loadDateTimeScript();
		var edate = moment.parseZone().add(1, 'days').format('YYYY-MM-DD');
		var sdate = moment.parseZone().subtract(7, 'days').format('YYYY-MM-DD');
		// var sdate = '2020-03-01';

		this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({ eventAction: eventAction });
		//console.log("Props ", this.props.match)
		this.setState({
			loadshow: 'show-m',
			startDate: "",
			endDate: "",
		});
		var urlpath;
		var dpt;
		var isCounterDisplay = 1;
		var screenpage = '';
		var title = '';
		var userrols = localStorage.getItem("roles");
		var splitrole = userrols.split(",")
		//console.log("splitrole ",splitrole)

		if (this.props.match.path == "/railconsignments") {
			urlpath = '/consignments/railsndconsignments';
			this.setState({ activeBtn: "btn-info" })
		}
		if (this.props.match.path == "/deliveredrailconsignments") {
			urlpath = '/consignments/deliveredrailsndconsignments';
			this.setState({ deliveredBtn: "btn-info" })
		}
		//console.log("urlpath ", urlpath)
		dpt = 'SNDG';
		isCounterDisplay = 1;
		screenpage = 'rail';
		title = 'Rail Consignments'

		redirectURL.post("/consignments/usergridstates", { userId: localStorage.getItem("userid"), screenurl: window.location.pathname })
			.then(async (resp) => {
				await this.setState({
					usergridstate: resp.data
				});
				await this.restoreGridStates();
			})

		this.setState({
			deptcode: dpt,
			isCounterDisplay: isCounterDisplay,
			screenpage: screenpage,
			pagetitle: title
		});

		//Use following code after ACL enable

		if (this.props.match.path == "/deliveredrailconsignments") {
			this.setState({
				startDate: sdate,
				endDate: edate,
			});
			var consignData = {
				consigner_code: ["all"],
				dept_code: dpt,
				startDate: sdate,
				endDate: edate,
			}
		}
		else {
			var consignData = {
				consigner_code: ["all"],
				dept_code: dpt,
				startDate: "",
				endDate: "",
			}
		}
		if (splitrole.length > 0) {
			if (splitrole.includes("tpt_sndg") == true || splitrole.includes("tpt_prt") == true || splitrole.includes("tpt_rail") == true) {
				// console.log("transportercode ", localStorage.getItem("transportercode"))
				if (localStorage.getItem("transportercode") == undefined || localStorage.getItem("transportercode") == "undefined"
					|| localStorage.getItem("transportercode") == "") {
					if (this.props.match.path == "/railconsignments") {
						urlpath = '/consignments/railsndconsignments';
					}
					if (this.props.match.path == "/deliveredrailconsignments") {
						urlpath = '/consignments/deliveredrailsndconsignments';
					}
					this.setState({
						userType: "regular"
					})

				}
				else {
					if (this.props.match.path == "/railconsignments") {
						urlpath = '/consignments/transporterrailsndconsignments';
					}
					if (this.props.match.path == "/deliveredrailconsignments") {
						urlpath = '/consignments/transporterdeliveredrailsndconsignments';
					}
					consignData.transporter_code = localStorage.getItem("transportercode")
					this.setState({
						userType: "tpt"
					})
				}
			}
		}

		//console.log("dept_code ", this.state.dept_code)
		/*urlpath = '/consignments/consignmentByDepartment';
			dpt=localStorage.getItem('dept_code');
		redirectURL.post(urlpath,consignData)      */


		redirectURL.post("/consignments/plants", { dept_code: dpt })
			.then((resp) => {
				this.setState({
					plants: resp.data
				});
			})
		redirectURL.post("/consignments/rakeloaddestpoints", {
			transportercode: localStorage.getItem("transportercode")
		})
			.then((resp) => {
				//console.log("Rakes ", resp.data)
				//console.log(JSON.parse(localStorage.getItem("transportercode")))


				var allrakes = []
				try {
					var loggedusercode = JSON.parse(localStorage.getItem("transportercode"));
					//console.log(loggedusercode[0].trim())
					if (resp.data.rakes.length > 0) {
						resp.data.rakes.map((item) => {
							//console.log("item ",item)
							if (item.truck[0].transporter_code == loggedusercode[0]) {
								//console.log("Item ", item)
								allrakes.push(item)
							}
						})
					}
				}
				catch (e) {
					allrakes = resp.data.rakes
				}

				//console.log("allrakes ",allrakes)
				this.setState({
					rakeslist: allrakes,
					loadingpoints: resp.data.loadingpoints,
					destinationpoints: resp.data.destinationpoints
				});
			})
		redirectURL.post("/consignments/getConsignmentsNewFilterKeys", { dept_code: dpt, rail_consignment: 1 })
			.then((resp) => {
				var cities = resp.data.cities;
				var filterCities = [];
				if (cities.length > 0) {
					cities.map(function (c) {
						filterCities.push({
							label: c,
							value: c,
						});
					});
				}
				var states = resp.data.states;
				var filterStates = [];
				if (states.length > 0) {
					states.map(function (s) {
						filterStates.push({
							label: s,
							value: s,
						});
					});
				}
				var clusters = resp.data.clusters;
				var filterClusters = [];
				if (clusters.length > 0) {
					clusters.map(function (cl) {
						filterClusters.push({
							label: cl,
							value: cl,
						});
					});
				}
				var transporters = resp.data.transporters;
				var filterTransporters = [];
				if (transporters.length > 0) {
					transporters.map(function (t) {
						filterTransporters.push({
							label: t,
							value: t,
						});
					});
				}
				this.setState({
					filterCities: filterCities,
					filterStates: filterStates,
					filterClusters: filterClusters,
					filterTransporters: filterTransporters,
				});
			})
		this.onLoadShowRails(consignData)
		this.onLoadDestinationYards();	
	};

	onLoadShowRails(parameters) {
		//console.log(parameters);
		var intransittoloadarr = [];
		var rakeattacharr = [];
		var rakeassignarr = [];
		var intransittodestinatearr = [];
		var atdestinationarr = [];
		var forceclsarr = [];
		var deliverarr = [];
		var transittodealerarr = [];
		var msilpodreceive = [];
		var railtransitdelays = [];
		var podtptdelivery = [];
		var userrols = localStorage.getItem("roles");
		var splitrole = userrols.split(",")
		if (this.props.match.path == "/railconsignments") {
			var urlpath = '/consignments/railsndconsignments';
			if (userrols.includes("tvp_rail") == true) {
				parameters.userrols = 1;
			}
		}
		if (this.props.match.path == "/deliveredrailconsignments") {
			var urlpath = '/consignments/deliveredrailsndconsignments';
			if (userrols.includes("tvp_rail") == true) {
				parameters.userrols = 1;
			}
		}

		if (splitrole.length > 0) {
			if (splitrole.includes("tpt_sndg") == true || splitrole.includes("tpt_prt") == true || splitrole.includes("tpt_rail") == true) {
				// if(this.props.match.path == "/railconsignments")
				// {
				// 	var urlpath = '/consignments/transporterrailsndconsignments';	
				// }
				// if(this.props.match.path == "/deliveredrailconsignments")
				// {
				// 	var urlpath = '/consignments/transporterdeliveredrailsndconsignments';	
				// }

				if (localStorage.getItem("transportercode") == undefined || localStorage.getItem("transportercode") == "undefined"
					|| localStorage.getItem("transportercode") == "") {
					if (this.props.match.path == "/railconsignments") {
						urlpath = '/consignments/railsndconsignments';
						if (userrols.includes("tvp_rail") == true) {
							parameters.userrols = 1;
						}
					}
					if (this.props.match.path == "/deliveredrailconsignments") {
						urlpath = '/consignments/deliveredrailsndconsignments';
						if (userrols.includes("tvp_rail") == true) {
							parameters.userrols = 1;
						}
					}

				}
				else {
					if (this.props.match.path == "/railconsignments") {
						urlpath = '/consignments/transporterrailsndconsignments';
						if (userrols.includes("tvp_rail") == true) {
							parameters.userrols = 1;
						}
					}
					if (this.props.match.path == "/deliveredrailconsignments") {
						urlpath = '/consignments/transporterdeliveredrailsndconsignments';
						if (userrols.includes("tvp_rail") == true) {
							parameters.userrols = 1;
						}
					}
				}

			}
		}
		this.setState({
			transporterURLPath: urlpath
		})
		if (localStorage.getItem("is_sales_type") == 1) {
			parameters.sales_type = localStorage.getItem("sales_type");
			parameters.region = localStorage.getItem("region");
		}
		redirectURL.post(urlpath, parameters)
			.then((response) => {
				//  console.log("response ", response.data)
				var records = response.data.consignments;
				records = records.sort(GetSortDescOrder("_id"));
				var pagecounters = response.data.counters;
				//console.log("records", records);
				//console.log("counters", pagecounters);
				var recordset = []
				if (records.length > 0) {
					records = records.filter(rec => rec.status >= 1)
					if (this.props.match.path == "/railconsignments") {
						if (splitrole.length > 0) {
							if (splitrole.includes("tpt_sndg") == true || splitrole.includes("tpt_prt") == true || splitrole.includes("tpt_rail") == true) {
								// if (localStorage.getItem("user_type") == "TRANSPORTER") {
								// 	recordset = records;
								// }
								// else {
								recordset = records.filter(rec => ((rec.rake_delivery_point_name != 'Nidavanda Railyard' || rec.rake_delivery_point_name != 'DLIB Railyard') && rec.status != 6 && rec.status != 8 && rec.status != 9 && rec.status != 10))
								// }

							}
							else {
								recordset = records.filter(rec => (rec.status >= 1))
							}
						}
						else {
							recordset = records.filter(rec => (rec.status >= 1))
						}

						//recordset = records.filter(rec => ((rec.rake_delivery_point_name != 'Nidavanda Railyard' || rec.rake_delivery_point_name != 'DLIB Railyard') && rec.status != 6 && rec.status != 8 && rec.status != 9 && rec.status != 10))
					}
					if (this.props.match.path == "/deliveredrailconsignments") {
						recordset = records.filter(rec => (rec.status >= 1))
					}


					var selectedCity = $("#selectedCity").text();
					var selectedState = $("#selectedState").text();
					var selectedCluster = $("#selectedCluster").text();
					var selectedTransporter = $("#selectedTransporter").text();
					console.log(selectedCity, selectedState, selectedCluster, selectedTransporter, "filters")
					//console.log(filterRowData,"0")
					if (selectedCity != undefined && selectedCity != '' && selectedCity != "Select...") {
						recordset = recordset.filter(e => e.consignee_city == selectedCity);
						console.log(recordset, "1")
					}
					if (selectedState != undefined && selectedState != '' && selectedState != "Select...") {
						recordset = recordset.filter(e => e.consignee_state == selectedState);
					}
					if (selectedCluster != undefined && selectedCluster != '' && selectedCluster != "Select...") {
						recordset = recordset.filter(e => e.cluster == selectedCluster);
					}
					if (selectedTransporter != undefined && selectedTransporter != '' && selectedTransporter != "Select...") {
						recordset = recordset.filter(e => e.transporter_name == selectedTransporter);
					}

					intransittoloadarr = recordset.filter(rec => rec.status == 2)
					rakeattacharr = recordset.filter(rec => (rec.status == 3 && rec.rake_no == undefined))
					rakeassignarr = recordset.filter(rec => (rec.status == 3 && rec.rake_no != undefined))
					intransittodestinatearr = recordset.filter(rec => rec.status == 4)
					atdestinationarr = recordset.filter(rec => rec.status == 5)
					forceclsarr = recordset.filter(rec => rec.status == 6)
					transittodealerarr = recordset.filter(rec => rec.status == 7)
					deliverarr = recordset.filter(rec => (rec.status == 8 || rec.status == 9 || rec.status == 10))
					msilpodreceive = recordset.filter(rec => rec.pod_received == 1)
					railtransitdelays = recordset.filter(rec => rec.rail_transit_delay == 1)
					podtptdelivery = recordset.filter(rec => rec.pod_tpt_delivery_diff_days > 1)
				}
				//console.log("records ", recordset)

				if (localStorage.getItem("is_sales_type") == 1) {
					var recordsarr = []
					if (records.length > 0) {
						records.map((item) => {

							if (localStorage.getItem("sales_type") == "Arena") {
								item.vin_list = item.arena_list;
							}
							if (localStorage.getItem("sales_type") == "nexa") {
								item.vin_list = item.nexa_list;
							}
							recordsarr.push(item)
						})

						recordset = recordsarr;
					}
				}
				var gridData = records;

				var lastMileCounters = pagecounters.lastMileCounters
				if (lastMileCounters == undefined) {
					lastMileCounters = []
				}
				if(recordset.length > 0){
					recordset.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
				}
				var co2_emission_rail;
				var co2_saved;
				if(this.props.match.path == "/deliveredrailconsignments"){
					var totalCO2Emission = recordset.reduce((acc, curr) => {
						const emission = curr.co2_emission_rail >0 ? curr.co2_emission_rail / 1000 : 0;
						return acc + emission;
					  }, 0);
					  var totalCO2EmissionSaved = recordset.reduce((acc, curr) => {
						const emission = curr.co2_saved >0 ? curr.co2_saved / 1000 : 0;
						return acc + emission;
					  }, 0);
					  co2_emission_rail = totalCO2Emission;	
					  co2_saved = totalCO2EmissionSaved
					}
					console.log(co2_saved,'co2_saved')
				this.setState({
					rowData: recordset,
					allRowData: recordset,
					originalData: recordset,
					loadshow: 'show-n',
					countersjson: pagecounters,
					originalcountersjson: pagecounters,
					intransittoload: intransittoloadarr,
					rakeattach: rakeattacharr,
					rakeasign: rakeassignarr,
					intransittodestinate: intransittodestinatearr,
					atdestination: atdestinationarr,
					forcecls: forceclsarr,
					deliver: deliverarr,
					transittodealer: transittodealerarr,
					intransittoloadcnt: intransittoloadarr.length,
					rakeattachcnt: rakeattacharr.length,
					rakeasigncnt: rakeassignarr.length,
					intransittodestinatecnt: intransittodestinatearr.length,
					atdestinationcnt: atdestinationarr.length,
					forceclscnt: forceclsarr.length,
					delivercnt: deliverarr.length,
					activeconsignemnt: recordset.length,
					transittodealercnt: transittodealerarr.length,
					msilpodreceive: msilpodreceive,
					msilpodreceivecnt: msilpodreceive.length,
					railtransitdelays: railtransitdelays,
					railtransitdelayscnt: railtransitdelays.length,
					lastMileTruckCounters: lastMileCounters,
					podtptdeliverycnt: podtptdelivery.length,
					podtptdelivery: podtptdelivery,
					co2_emission_total:co2_emission_rail,
					co2_saved:co2_saved
				});


			})
			.catch(function (error) {
				console.log(error);
			});
	}

	onLoadDestinationYards() {
		var userrols = localStorage.getItem("roles");
		if (userrols.includes("tvp_rail") == true) {
			var items = [];
			items.push({ value: 'all', label: 'All' });
			items.push({ value: 'DLIB Railyard', label: 'DLIB Railyard' });
			items.push({ value: 'Melpakkam Railyard', label: 'Melpakkam Railyard' });
			items.push({ value: 'Nidavanda Railyard', label: 'Nidavanda Railyard' });
			items.push({ value: 'Irugur Railyard', label: 'Irugur Railyard' });
			items.push({ value: 'Rangapani Railyard', label: 'Rangapani Railyard' });
			items.push({ value: 'Shankarpally Railyard', label: 'Shankarpally Railyard' });
			items.push({ value: 'Reddipalem Railyard', label: 'Reddipalem Railyard' });
			items.push({ value: 'Changsari Railyard', label: 'Changsari Railyard' });
			items.push({ value: 'Multimodal Logistics Park', label: 'Multimodal Logistics Park'});
			items.push({ value: 'Ajni Railyard', label: 'Ajni Railyard'});
			this.setState({
				destinationOptions: items
			});
		}
		else {
			redirectURL.post("/consignments/getDestinationYards")
				.then((response) => {
					console.log("Destination Yard", response.data)
					var records = response.data;
					var items = [];
					items.push({ value: 'all', label: 'All' });
					records.forEach(item => {
						items.push({ value: item.geofence_name, label: item.geofence_name });
					});
					this.setState({
						destinationOptions: items
					});
				})
				.catch(function (e) {
					console.log("Destination Yards Error ", e)
				})
		}
	}

	selectGeofence() {
		let arr = [];
		try {
			this.state.geofencelist.forEach((item, index) => {
				arr.push({ value: this.state.geoFenceData[index]._id, label: item });
			});
			return arr;
		}
		catch (e) {
		}
	}

	onShowTimelineDiv = (e) => {
		//console.log("Props data ", e)
		this.setState({
			loadshow: 'show-m'
		});
		var reqData = {
			consignment_code: e.consignment_code
		}
		//console.log("reqData: ", reqData);
		redirectURL.post('/consignments/timeline', reqData, {
			headers: {
				'content-type': 'application/json'
			}
		})
			.then((response) => {
				var records = response.data;
				//console.log("records", records);

				this.setState({
					uploadDivWidth: '30%',
					sliderTranslate: "slider-translate-50p",
					showDiv: 'show-m',
					timelinedata: records,
					loadshow: 'show-n',
					overly: 'show-m'
				});
			})
			.catch(function (error) {
				console.log(error);
			});

	}

	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth: '0%',
			sliderTranslate: "",
			tabsliderTranslate: '',
			showDiv: 'show-n',
			sliderTranslatesidebar: "",
			uploadFile: '',
			file: '',
			csvmessage: '',
			csverrmessage: '',
			overly: 'show-n',
			loadshow: 'show-n',
			sliderForceDestination: '',
			sliderForceLoadingYard: '',
			sliderBulkEditConsignments : ''
		});
	}
	onShowGoogleRoute(consignmentcode, consigneecode, legno, truck_no) {
		console.log("GOogle params ", consignmentcode + ", " + consigneecode + ", " + legno)
		var reqData = {
			consignment_code: consignmentcode,
			consignee_code: consigneecode,
			leg_no: legno,
			truck_no: truck_no
		}
		redirectURL.post('/consignments/googlemaproutes', reqData, {
			headers: {
				'content-type': 'application/json'
			}
		})
			.then((response) => {
				console.log("Google ", response)
				//var gdt = '[{	"lat": 28.367420000000003,	"lng": 76.90442},{	"lat": 28.379795,	"lng": 76.903625},{	"lat": 28.380361999999998,	"lng": 76.899445},{	"lat": 28.383162,	"lng": 76.90275},{	"lat": 28.384619,	"lng": 76.900024},{	"lat": 28.385069,	"lng": 76.900024},{	"lat": 28.384990000000002,	"lng": 76.8998}]' 
				this.setState({
					googelRoutes: response.data.googleroute
				})
			})
			.catch(function (e) {
				console.log(e)
			})
	}
	onShowRouteDiv = (e) => {
		if (googleAnalytics.page.enableGA) {
			let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}

		console.log("IN onShowRouteDiv, Rute params ", e)
		this.setState({
			loadshow: 'show-m',
			maptruckno: e.truck_no
		});

		var gateouttime = moment.parseZone(e.gate_out_time).format('YYYY-MM-DD HH:mm:ss');

		var end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
		if (e.status == 2) {
			end_date = moment.parseZone(e.gate_out_time).utcOffset("+08:00").format('YYYY-MM-DD HH:mm:ss');
		}
		if (e.status == 3) {
			end_date = moment.parseZone(e.truck_loading_yard_reached_on).format('YYYY-MM-DD HH:mm:ss');
		}
		if (e.status == 4) {
			end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
		}

		if (e.status == 5) {
			end_date = moment.parseZone(e.rake_delivery_point_reached_on).format('YYYY-MM-DD HH:mm:ss');
		}

		if (e.status == 6) {
			if (e.last_mile_reached_on != '') {
				end_date = moment.parseZone(e.last_mile_reached_on).format('YYYY-MM-DD HH:mm:ss');
			}
			else {
				end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
			}
		}
		//console.log('gateouttime ', g  ateouttime)

		var reqData = {
			truck_no: e.truck_no,
			consignment_code: e.consignment_code,
			consignee_code: e.consignee_code,
			gate_out_time: gateouttime,
			seconddate: end_date,
			screen: "consignment"
		}
		//console.log("reqData: ", reqData);
		redirectURL.post('/consignments/maproutes', reqData, {
			headers: {
				'content-type': 'application/json'
			}
		})
			.then((response) => {
				var records = response.data;
				//console.log("Props data ", e)

				this.setState({
					loadshow: 'show-m'
				});
				if (records.status == "failure") {
					this.setState({
						show1: true,
						basicTitle1: 'No Route data available',
						basicType1: "danger",
						loadshow: 'show-n'
					});
				}
				else {
					if (records.coords.length == 0) {
						//console.log("records", records.coords);
						this.setState({
							show1: true,
							basicTitle1: 'No Route data available',
							basicType1: "danger",
							loadshow: 'show-n'
						});
					}
					else if (records != '') {
						// var edate ='';
						// if(e.status <= 4)
						// {
						// 	//var ms = new Date(e.gate_out_time).getTime() + ((e.transit_time+1)*86400000);
						// 	//var edatesecond = new Date(ms);
						// 	end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')
						// 	edate = getDDMMYYYYHHMMSS(end_date);
						// }
						// else if(e.status == 5)
						// {
						// 	if (e.rake_delivery_point_reached_on)
						// 	{
						// 		edate = getDDMMYYYYHHMMSS(e.rake_delivery_point_reached_on);
						// 	}
						// 	else{
						// 		end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')
						// 		edate = getDDMMYYYYHHMMSS(end_date);
						// 	}
						// }
						//console.log("End Date ", edate)
						this.setState({
							sliderRouteTranslate: "slider-translate-60p",
							showDiv: 'show-m',
							mapinfo: records,
							dealer: e.consignee_code,
							consignment_code: "Consignment : " + e.consignment_code,
							maptruckno: e.truck_no,
							routeTruck: { "truck_no": e.truck_no, "startTime": gateouttime, "endTime": getDDMMYYYYHHMMSS(end_date) },
							loadshow: 'show-n',
							sidebarSubHeader: "Consignment Information",
							overly: 'show-m',
							rownode: e,
							leg_no: 0,

						});
						// this.onShowGoogleRoute(e.consignment_code, e.consignee_code, 0, e.truck_no)
						//this.renderMap();

					}
				}


			})
			.catch(function (error) {
				console.log(error);
			});
	}
	onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth: '0%',
			sliderRouteTranslate: "",
			showDiv: 'show-n',
			uploadFile: '',
			file: '',
			csvmessage: '',
			csverrmessage: '',
			overly: 'show-n',
			sliderForceDestination: '',
			sliderForceLoadingYard: '',
			sliderBulkEditConsignments : ''
		});
	}


	onGridReady = params => {
		this.gridApi = params.api;

		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;

		//    ChartMenu.prototype.saveChart = function (e) {
		// 	   var event = {
		// 	      data: this.gridOptionsWrapper.getApi(),
		// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
		// 	    };
		// 	   //console.log("Testing", event);
		// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
		// 	   this.dispatchEvent(event);
		// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;

		//    };
		if (this.state.deptcode == 'LOG-PRT') {
			console.log("Step 1 ")
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception", "loading_delay_exception", "unloading_delay_exception", "trip_type"], false);

			this.gridColumnApi.setColumnsVisible(["trip_type"], false);
		}
		else if (this.state.deptcode == 'SNDG') {
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], true);
			this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], true);
			//this.gridColumnApi.setColumnsVisible(["unloading_delay_exception"], true);
			this.gridColumnApi.setColumnsVisible(["consignee_city"], true);
			this.gridColumnApi.setColumnsVisible(["trip_type"], false);
		}
		else if (this.state.deptcode == 'LOG-TNP') {
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], true);
			this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], true);
			//this.gridColumnApi.setColumnsVisible(["unloading_delay_exception"], true);
			this.gridColumnApi.setColumnsVisible(["consignee_city"], true);
			this.gridColumnApi.setColumnsVisible(["trip_type"], true);
		}

		else { }

	};


	onGridState = () => {
		//console.log(this.gridApi);

		/*Get  Current Columns State and Store in this.colState */
		this.colState = this.gridColumnApi.getColumnState();

		/*Get Current RowGroup Columns State and Store in this.rowGroupState */
		this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		/*Get Current Pivot Columns State and Store in this.pivotColumns, 
		 * User should enable the Pivot mode.*/
		this.pivotColumns = this.gridColumnApi.getPivotColumns();

		/*Get Current Filter State and Store in window.filterState */
		window.filterState = this.gridApi.getFilterModel();
		this.gridApi.setFilterModel(window.filterState);



	}

	onRowClicked(params) {
		console.log("IN onRowClicked, params = ", params);
		//console.log("row click ", params)
		if (params.column.colId == '_id_1' || params.column.colId == '_id_2') {

			params.node.setExpanded(!params.node.expanded);
		}
		else {

			params.node.setExpanded(false);
		}

	}

	onShowRouteMap(rownode) {
		console.log("IN onShowRouteMap, params = ", rownode);
		console.log("rownode", rownode);
		console.log("rownode.consignment_code ", rownode.consignment_code)
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.setState({
			loadshow: 'show-m'
		})
		var d = new Date(rownode.first_instance);
		var r = new Date(rownode.recent_instance);
		var finstance = rownode.first_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var rinstance = rownode.recent_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var params = {
			truck_no: rownode.truck_no,
			consignment_code: rownode.consignment_code,
			consignee_code: rownode.consignee_code,
			first_instance: finstance,
			recent_instance: rinstance,
			screen: "consignment"
		}

		redirectURL.post('/reports/overspeedgpsroutes', params)
			.then((response) => {

				//var records = JSON.parse(JSON.stringify(response)).data;
				console.log(response);
				this.setState({
					mapinfo: response.data,
					dealer: rownode.consignee_code,
					sliderRouteTranslate: "slider-translate-50p",
					consignment_code: rownode.truck_no,
					maptruckno: rownode.truck_no,
					routeTruck: {
						"truck_no": rownode.truck_no, "startTime": response.data['route_details']['start_time'],
						"endTime": response.data['route_details']['end_time']
					},
					overly: 'show-m',
					loadshow: 'show-n',
					rownode: rownode,
				});
				this.renderMap();


			})
			.catch(function (error) {
				console.log(error);
			});

	}

	onShowTransitRouteMap(rownode) {
		console.log("IN onShowTransitRouteMap, params = ", rownode);
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		//console.log("rownode", rownode);
		this.setState({
			loadshow: 'show-m'
		})
		var d = new Date(rownode.leg_start);
		var r = new Date(rownode.leg_end_eta);
		var fintance = rownode.leg_start.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var eintance = rownode.leg_end_eta.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var params = {
			truck_no: rownode.truck_no,
			consignment_code: rownode.consignment_code,
			consignee_code: rownode.consignee_code,
			first_instance: fintance,
			recent_instance: eintance,
			screen: "consignment"
		}
		redirectURL.post('/reports/nightdriveroutes', params)
			.then((response) => {

				//var records = JSON.parse(JSON.stringify(response)).data;
				//console.log("Inner Grid ",response.data);
				if (response.data.coords.length == 0) {
					this.setState({
						show: true,
						basicTitle: 'No Route data available',
						basicType: "danger",
						loadshow: 'show-n'
					});
				}
				else {
					var sdate = '';
					var edate = '';

					if (rownode.leg_start != '' && rownode.leg_start != undefined) {
						sdate = rownode.leg_start;
					}
					if (rownode.leg_end_eta != '' && rownode.leg_end_eta != undefined) {
						edate = getDDMMYYYYHHMMSS(rownode.leg_end_eta)
					}
					this.setState({
						mapinfo: response.data,
						dealer: rownode.consignee_code,
						sliderRouteTranslate: "slider-translate-60p",
						defTransitCoords: rownode.consignee_coordinates,
						consignment_code: "Consignment : " + rownode.truck_no,
						maptruckno: rownode.truck_no,
						routeTruck: { "truck_no": rownode.truck_no, "startTime": sdate, "endTime": edate },
						loadshow: 'show-n',
						sidebarSubHeader: "Consignment Leg Information",
						overly: 'show-m',
						rownode: rownode,
						leg_no: rownode.leg_no,

					});

					// this.onShowGoogleRoute(rownode.consignment_code, rownode.consignee_code, rownode.leg_no, rownode.truck_no)
					this.renderMap();

				}
			})
			.catch(function (error) {
				console.log(error);
			});

	}

	onClickHideAll() {
		this.setState({
			loadshow: 'show-n',
			overly: 'show-n',
			sliderRouteTranslate: '',
			tabsliderTranslate: '',
			sliderTranslatesidebar: '',
			sliderForceCloseTranslate: '',
			sliderRakeTranslate: '',
			bulkslide: '',
			sliderForceDestination: '',
			sliderForceLoadingYard: '',
			sliderBulkForceClose: "",
			bulkdeliverslide: "",
			bulkslideForDispatch: '',
			sliderBulkEditConsignments : ''
		});

	}
	

	showGoogleRouteAlert = () => {
		this.setState({
			show: true,
			basicTitle: "No google routes found",
			basicType: "danger"
		});
	}

	closeAlert = async () => {
		await this.setState({
			show: false
		});
		await window.location.reload();
	}

	closeAlert1 = async () => {
		this.setState({
			show1: false
		});

	}
	onClickCounterShowDataTEST(params) {
		console.log("IN onClickCounterShowData Do NOTHING- params = ", params);
	}

	onClickCounter(params) {
		if (params == "all"){
			this.gridApi.setRowData(this.state.allmissing_Data);
			this.gridApi.onFilterChanged();
		}
		if (params == "reached_dealer_yard"){
			this.gridApi.setRowData(this.state.missing_rake_delivery_point);
			this.gridApi.onFilterChanged();
		}
		if (params == "last_mile_dispatch"){
			this.gridApi.setRowData(this.state.missing_last_mile_dispatch_date);
			this.gridApi.onFilterChanged();
		}
		if (params == "tpt_confirm_delivery"){
			this.gridApi.setRowData(this.state.tptConformDelivery);
			this.gridApi.onFilterChanged();
		}
	}

	onClickCounterShowData(params) {
		console.log("IN onClickCounterShowData, params = ", params);
		if (googleAnalytics.page.enableGA) {
			let eventLabel = null;
			if (params) {
				eventLabel = googleAnalytics.page.kpi[params];
				//console.log("eventLabel = ", eventLabel);
			}

			let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventCounterAction,
				"label": eventLabel,
			}
			googleAnalytics.logEvent(eventOptions);
		}

		if (params == "intransittoload") {
			var filterComponent = this.gridApi.getFilterInstance("intransittoload");
			//filterComponent.selectNothing();
			//filterComponent.selectValue("2");
			//filterComponent.applyModel();
			let isFilterDataAvailable = this.state.rowData.filter(rec => {
				if (rec["status"]) {
					return rec["status"] == 2;
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if (params == "rakeattach") {
			var filterComponent = this.gridApi.getFilterInstance("rakeattach");
			//filterComponent.selectNothing();
			//filterComponent.selectValue("3");

			//filterComponent.applyModel();
			let isFilterDataAvailable = this.state.rowData.filter(rec => {
				if (rec["status"]) {
					return (rec["status"] == 3 && rec["rake_no"] == undefined);
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if (params == "rakeassign") {
			var filterComponent = this.gridApi.getFilterInstance("rakeassign");
			//filterComponent.selectNothing();
			//filterComponent.selectValue("3");

			//filterComponent.applyModel();
			let isFilterDataAvailable = this.state.rowData.filter(rec => {
				if (rec["status"]) {
					return (rec["status"] == 3 && rec["rake_no"] != undefined);
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if (params == "intransittodestinate") {
			var filterComponent = this.gridApi.getFilterInstance("intransittodestinate");
			//filterComponent.selectNothing();
			//filterComponent.selectValue("4");

			//filterComponent.applyModel();
			let isFilterDataAvailable = this.state.rowData.filter(rec => {
				if (rec["status"]) {
					return rec["status"] == 4;
				}
			});
			console.log("isFilterDataAvailable ", isFilterDataAvailable)
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if (params == "atdestination") {
			var filterComponent = this.gridApi.getFilterInstance("atdestination");
			//filterComponent.selectNothing();
			//filterComponent.selectValue("5");

			//filterComponent.applyModel();
			let isFilterDataAvailable = this.state.rowData.filter(rec => {
				if (rec["status"]) {
					return rec["status"] == 5;
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if (params == "transittodealer") {
			var filterComponent = this.gridApi.getFilterInstance("transittodealer");
			//filterComponent.selectNothing();
			//filterComponent.selectValue("6");

			//filterComponent.applyModel();
			let isFilterDataAvailable = this.state.rowData.filter(rec => {
				if (rec["status"]) {
					return rec["status"] == 7;
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if (params == "deliver") {
			var filterComponent = this.gridApi.getFilterInstance("deliver");
			//filterComponent.selectNothing();
			//filterComponent.selectValue("8");

			//filterComponent.applyModel();
			let isFilterDataAvailable = this.state.rowData.filter(rec => {
				if (rec["status"]) {
					return rec["status"] == 8;
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if (params == "msilpod") {
			var filterComponent = this.gridApi.getFilterInstance("msilpod");
			//filterComponent.selectNothing();
			//filterComponent.selectValue("8");

			//filterComponent.applyModel();
			let isFilterDataAvailable = this.state.rowData.filter(rec => {
				if (rec["pod_received"]) {
					return rec["pod_received"] == 1;
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if (params == 'rail_transit_delay') {
			var filterComponent = this.gridApi.getFilterInstance("rail_transit_delay");
			//filterComponent.selectNothing();
			//filterComponent.selectValue("8");

			//filterComponent.applyModel();
			let isFilterDataAvailable = this.state.rowData.filter(rec => {
				if (rec["rail_transit_delay"]) {
					return rec["rail_transit_delay"] == 1;
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if (params == "last_mile_truck_counters") {
			var filteredData = this.state.rowData.filter((e) => {
				return this.state.lastMileTruckCounters.indexOf(e.last_mile_truck_no) >= 0
			})
			this.gridApi.setRowData(filteredData);
			this.gridApi.onFilterChanged();
		}
		else if (params == "podtpt") {
			let podtptdelivery = this.state.rowData.filter(rec => rec.pod_tpt_delivery_diff_days > 1)
			this.gridApi.setRowData(podtptdelivery);
			this.gridApi.onFilterChanged();
		}
		else if (params == "all"){
			this.gridApi.setRowData(this.state.rowData);
			this.gridApi.onFilterChanged();
		}
		else if(params == "co2_emission_total"){
			let isFilterDataAvailableForEmission = this.state.rowData.filter(rec => {
				return rec["co2_emission_rail"] !== undefined && rec["co2_emission_rail"] !== null && rec["co2_emission_rail"] !== '' && rec["co2_emission_rail"] > 0;
			});
			this.gridApi.setRowData(isFilterDataAvailableForEmission);
			this.gridApi.onFilterChanged();
		}
		else if(params == "co2_saved"){
			let isFilterDataAvailableForEmission = this.state.rowData.filter(rec => {
				return rec["co2_saved"] !== undefined && rec["co2_saved"] !== null && rec["co2_saved"] !== ''  && rec["co2_saved"] > 0;
			});
			this.gridApi.setRowData(isFilterDataAvailableForEmission);
			this.gridApi.onFilterChanged();
		}
		else {
			if (this.state.tripvalue != undefined && this.state.tripvalue != '') {

				this.gridApi.setRowData(this.state.rowData);
			}
			else {

				this.gridApi.setRowData(this.state.allRowData);
			}
			this.gridApi.onFilterChanged();
		}

	}
	// onSelectTrip(value){
	// 	//console.log(value.target.value)
	// 	this.setState({
	// 		triptype:value.target.value
	// 	});
	// }
	getTruckConsignments = (event) => {
		this.setState({
			overly: "show-m",
			loadshow: "show-m"
		})
		event.preventDefault();
		var selectedFilter = $("#selectedFilter").text();
		var inputTruckConsignment = $("#inputTruckConsignment").val();
		if (selectedFilter == "Select..." || inputTruckConsignment == "") {
			this.setState({
				basicTitle: "Fields Should not be Empty",
				basicType: "danger",
				show: true
			})
		}
		else {
			//console.log(selectedFilter,inputTruckConsignment);
			var urlpath = ""
			if (this.props.match.path == "/deliveredrailconsignments") {
				urlpath = "/consignments/filterRailByConsignment";
				var pageurl = "/deliveredrailconsignments";
			}
			if (this.props.match.path == "/railconsignments") {
				urlpath = "/consignments/filterRailByConsignment";
				var pageurl = "/railconsignments";
			}
			var reqprm = {
				"selectedFilter": selectedFilter,
				"inputTruckConsignment": inputTruckConsignment,
				"urlpath": pageurl
			}
			if (localStorage.getItem("is_sales_type") == 1) {
				reqprm.sales_type = localStorage.getItem("sales_type");
				reqprm.region = localStorage.getItem("region");
			}
			redirectURL.post(urlpath, reqprm)
				.then((response) => {
					var records = response.data.consignments;
					records = records.sort(GetSortDescOrder("_id"));
					var intransittoloadarr = [];
					var rakeattacharr = [];
					var rakeassignarr = [];
					var intransittodestinatearr = [];
					var atdestinationarr = [];
					var forceclsarr = [];
					var deliverarr = [];
					var transittodealerarr = [];
					var msilpodreceive = [];
					var railtransitdelays = [];
					var pagecounters = response.data.counters;
					//console.log("records", records);
					//console.log("counters", pagecounters);
					if (records.length > 0) {
						records = records.filter(rec => rec.status >= 1)
						intransittoloadarr = records.filter(rec => rec.status == 2)
						rakeattacharr = records.filter(rec => (rec.status == 3 && rec.rake_no == undefined))
						rakeassignarr = records.filter(rec => (rec.status == 3 && rec.rake_no != undefined))
						intransittodestinatearr = records.filter(rec => rec.status == 4)
						atdestinationarr = records.filter(rec => rec.status == 5)
						forceclsarr = records.filter(rec => rec.status == 6)
						transittodealerarr = records.filter(rec => rec.status == 7)
						deliverarr = records.filter(rec => (rec.status == 8 || rec.status == 9 || rec.status == 10))
						msilpodreceive = records.filter(rec => rec.pod_received == 1)
						railtransitdelays = records.filter(rec => rec.rail_transit_delay == 1)
					}
					var co2_emission_rail;
					var co2_saved;
				if(this.props.match.path == "/deliveredrailconsignments"){
					var totalCO2Emission = records.reduce((acc, curr) => {
						const emission = curr.co2_emission_rail >0 ? curr.co2_emission_rail / 1000 : 0;
						return acc + emission;
					  }, 0);
					  var totalCO2EmissionSaved = records.reduce((acc, curr) => {
						const emission = curr.co2_saved >0 ? curr.co2_saved / 1000 : 0;
						return acc + emission;
					  }, 0);
					  co2_emission_rail = totalCO2Emission;	
					  co2_saved = totalCO2EmissionSaved;
					}
					console.log("co2_saved ", co2_saved)

					this.setState({
						rowData: records,
						allRowData: records,
						overly: "show-n",
						originalData: records,
						loadshow: 'show-n',
						countersjson: pagecounters,
						originalcountersjson: pagecounters,
						intransittoload: intransittoloadarr,
						rakeattach: rakeattacharr,
						rakeasign: rakeassignarr,
						intransittodestinate: intransittodestinatearr,
						atdestination: atdestinationarr,
						forcecls: forceclsarr,
						deliver: deliverarr,
						transittodealer: transittodealerarr,
						intransittoloadcnt: intransittoloadarr.length,
						rakeattachcnt: rakeattacharr.length,
						rakeasigncnt: rakeassignarr.length,
						intransittodestinatecnt: intransittodestinatearr.length,
						atdestinationcnt: atdestinationarr.length,
						forceclscnt: forceclsarr.length,
						delivercnt: deliverarr.length,
						activeconsignemnt: records.length,
						transittodealercnt: transittodealerarr.length,
						msilpodreceive: msilpodreceive,
						msilpodreceivecnt: msilpodreceive.length,
						railtransitdelays: railtransitdelays,
						railtransitdelayscnt: railtransitdelays.length,
						co2_emission_total:co2_emission_rail,
						co2_saved:co2_saved
					});
				}).catch = ((e) => {
					console.log(e);
				})
		}

	}

	onSelectTrip = triptype => {
		this.setState(
			{ triptype },
			() => console.log(`Trip Option selected:`, this.state.triptype)
		);

		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}

	onClickTripButton = async () => {
		var sDate = this.state.startDate;
		var eDate = this.state.endDate;
		this.setState({
			startDate: sDate,
			endDate: eDate,
			loadshow: 'show-m'
		});

		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedSelectConsigner,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		//console.log("Buton this.state.triptype ", this.state.triptype.value)
		if (this.state.triptype.value == undefined) {
			var tripvale = "all";
		}
		else {
			var tripvale = this.state.triptype.value;
		}
		var selectcons = [];
		if (this.state.consigner != null) {
			if (this.state.consigner.length > 0) {
				this.state.consigner.forEach(item => {
					selectcons.push(item.value);
				});
			}
		}
		var tdata = '';
		//console.log("trip ",tripvale)
		if (tripvale != "") {
			//console.log("Step 1", tripvale);
			if (tripvale == 'all') {
				//console.log("Step 2", tripvale);
				tdata = {
					triptype: tripvale,
					consigner_code: selectcons,
					dept_code: this.state.deptcode,
					screenpage: this.state.screenpage,
					startDate: sDate,
					endDate: eDate
				}
			}
			else {
				//console.log("Step 3", tripvale);
				tdata = {
					triptype: tripvale,
					consigner_code: selectcons,
					dept_code: this.state.deptcode,
					screenpage: this.state.screenpage,
					startDate: sDate,
					endDate: eDate
				}
			}

		}
		else if (selectcons.length > 0) {
			//console.log("Step 4", tripvale);
			tdata = {
				consigner_code: selectcons,
				dept_code: this.state.deptcode,
				screenpage: this.state.screenpage,
				startDate: sDate,
				endDate: eDate
			}
		}
		else {
			//console.log("Step 5", tripvale);
			tdata = ''
		}
		//console.log("tdata ",tdata)
		if (tdata != '') {

			await redirectURL.post("/consignments/tripconsignments", tdata)
				.then((response) => {
					//console.log("Trip array ", response.data)
					var records = response.data.consignments;
					var pagecounters = response.data.counters;
					this.setState({
						tripvalue: tripvale,
						rowData: records,
						allRowData: records,
						loadshow: 'show-n',
						countersjson: pagecounters
						//consigner:''
					});
				})
				.catch(function (e) {
					console.log("Counters Grid Error ", e)
				})

		}
		else {
			//console.log("this.state.allRowData ", this.state.originalData)
			this.setState({
				rowData: this.state.originalData,
				allRowData: this.state.originalData,
				tripvalue: tripvale,
				loadshow: 'show-n',
				countersjson: this.state.originalcountersjson
			});
		}

	}

	changeConsignerArrayHandler = consigner => {
		this.setState(
			{ consigner },
			() => console.log(`Consigner Option selected:`, this.state.consigner)
		);

		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}
	selectConsignerOptionsItems() {
		let items = [];
		//console.log(this.state.trucks);
		var consigners = [];
		if (this.state.deptcode == 'SNDG') {
			//var keys = ["B", "F", "G", "I","M", "N"];
			var keys = ["F", "G", "M", "B","D"];

			// An array of values 
			//var values = ["TVP Bangalore", "Gurgaon", "Gujarat", "Siliguri","Manesar", "TVP Nagpur"]; 
			var values = ["Gurgaon", "Gujarat", "Manesar", "D22 Bengaluru (Bidadi)","Dobaspet"];

			// Object created 
			var obj = {};

			// Using loop to insert key 
			// value in Object 
			for (var i = 0; i < keys.length; i++) {
				consigners.push({ "code": keys[i], "value": values[i] });
			}
		}


		if (this.state.deptcode == 'LOG-PRT') {
			// var keys = ["MSILDM", "MSILG", "MSILM", "RPDC-BAN","RPDC-GUJ", "RPDC-NAG", "RPDC-SIL"]; 

			// // An array of values 
			// var values = ["MSIL Domestic", "Gurgaon", "Manesar", "RPDC Bangalore","RPDC Gujarat", "RPDC Nagpur", "RPDC Siliguri"]; 


			var keys = ["MSILG", "MSILM", "RPDC-GUJ"];

			// An array of values 
			var values = ["Gurgaon", "Manesar", "RPDC Gujarat"];

			// Object created 
			var obj = {};

			// Using loop to insert key 
			// value in Object 
			for (var i = 0; i < keys.length; i++) {
				consigners.push({ "code": keys[i], "value": values[i] });
			}
		}


		if (this.state.deptcode == 'LOG-TNP') {
			// var keys = ["MSIL-G", "MSIL-M", "MSILMC","MSILME", "SMG"]; 

			// // An array of values 
			// var values = ["Gurgaon", "Manesar", "Casting","Power Train", "SMG Gujarat"]; 

			var keys = ["MSIL-G", "MSIL-M", "SMG"];

			// An array of values 
			var values = ["Gurgaon", "Manesar", "SMG Gujarat"];


			// Object created 
			var obj = {};

			// Using loop to insert key 
			// value in Object 
			for (var i = 0; i < keys.length; i++) {
				consigners.push({ "code": keys[i], "value": values[i] });
			}
		}

		//console.log("consigners ", consigners)
		items.push({ value: 'all', label: 'All' });
		consigners.forEach(item => {
			items.push({ value: item.code, label: item.value });
		});
		// if(this.state.originalData.length > 0)
		// {
		// 	var unqis=[];
		// 	this.state.originalData.forEach(item => {
		// 		unqis.push(item.consigner_code);
		// 	});
		// 	const uniqueCosnigners = unqis.filter((value, index, self) => {
		// 		return self.indexOf(value) === index
		// 	  })
		// 	  uniqueCosnigners.forEach(item => {
		//        	items.push({value:item,label:item}); 

		//     });
		// }

		return items;
	}

	changeDestinationYardArrayHandler = destination_yard => {
		this.setState(
			{ destination_yard },
			() => console.log(`Destination Yard Option selected:`, this.state.destination_yard)
		);

		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}

	onGetConsigners = async (event) => {
		this.setState({
			//startDate:moment(sDate).format("DD-MM-Y"),
			//endDate:moment(eDate).format("DD-MM-Y"),
			loadshow: 'show-m'
		});
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedSelectConsigner,
			}
			googleAnalytics.logEvent(eventOptions);
		}

		var selectcons = []
		if (this.state.consigner != null) {
			if (this.state.consigner.length > 0) {
				this.state.consigner.forEach(item => {
					selectcons.push(item.value);
				});
			}

		}
		else {
			selectcons.push("all");
		}

		//console.log("selectcons ", selectcons)

		var sDate = this.state.startDate;
		var eDate = this.state.endDate;
		if (sDate != "NaN-NaN-NaN") {
			var stdate = sDate;
		}
		else {
			var stdate = ""
		}
		if (eDate != "NaN-NaN-NaN") {
			var etdate = eDate;
		}
		else {
			var etdate = ""
		}
		var destinationYard = this.state.destination_yard;
		var dtYard = "";
		if (destinationYard != '' && destinationYard != undefined) {
			var dtYard = [];
			destinationYard.map(function (e) {
				dtYard.push(e.value);
			});
		}
		var tdata = {
			consigner_code: selectcons,
			dept_code: this.state.deptcode,
			screenpage: this.state.screenpage,
			destination_yard: dtYard,
			startDate: stdate,
			endDate: etdate
		}
		var userrols = localStorage.getItem("roles");
		var splitrole = userrols.split(",")
		if (splitrole.length > 0) {
			if (splitrole.includes("tpt_sndg") == true || splitrole.includes("tpt_prt") == true || splitrole.includes("tpt_rail") == true) {

				tdata.transporter_code = localStorage.getItem("transportercode")
			}
		}

		this.onLoadShowRails(tdata)
		// await redirectURL.post("/consignments/consignerconsignments", tdata)
		// .then((response) => {
		// 	//console.log("Consinger COde array ", response.data)
		// 	var records = response.data.consignments;
		// 	var pagecounters = response.data.counters;
		// 	var intransittoloadarr=[];
		// 	var rakeattacharr=[];
		// 	var intransittodestinatearr=[];
		// 	var atdestinationarr=[];
		// 	var forceclsarr=[];
		// 	var deliverarr=[];
		// 	var transittodealerarr=[];
		// 	if(records.length > 0)
		// 	{
		// 		records = records.filter(rec => rec.status >= 1)
		// 		intransittoloadarr = records.filter(rec => rec.status >= 2)
		// 		rakeattacharr = records.filter(rec => rec.status >= 3)
		// 		intransittodestinatearr = records.filter(rec => rec.status >= 4)
		// 		atdestinationarr = records.filter(rec => rec.status >= 5)
		// 		forceclsarr = records.filter(rec => rec.status >= 6)
		// 		transittodealerarr = records.filter(rec => rec.status >= 7)
		// 		deliverarr = records.filter(rec => rec.status >= 8)
		// 	}
		// 	//console.log("records ", records)

		// 	this.setState({
		// 		rowData:records,
		// 		allRowData:records,
		// 		loadshow:'show-n',
		// 		countersjson:pagecounters,
		// 		originalcountersjson:pagecounters,
		// 		intransittoload:intransittoloadarr,
		// 		rakeattach:rakeattacharr,
		// 		intransittodestinate:intransittodestinatearr,
		// 		atdestination:atdestinationarr,
		// 		forcecls:forceclsarr,
		// 		deliver:deliverarr,
		// 		transittodealer:transittodealerarr,
		// 		intransittoloadcnt:intransittoloadarr.length,
		// 		rakeattachcnt:rakeattacharr.length,
		// 		intransittodestinatecnt:intransittodestinatearr.length,
		// 		atdestinationcnt:atdestinationarr.length,
		// 		forceclscnt:forceclsarr.length,
		// 		delivercnt:deliverarr.length,
		// 		activeconsignemnt:records.length,
		// 		transittodealercnt:transittodealerarr.length
		// 	});

		// })
		// .catch(function(e){
		// 	console.log("Counters Grid Error ", e)
		// })

	}


	handlerStartDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);

		var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

		this.setState({
			startDate: startdate
		});
		//let value = event.target.value;
		//this.setState({'startDate':value});
	}

	handlerEndDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);

		var edate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())
		this.setState({
			endDate: edate
		});
	}
	onRowSelection(event) {
		var containerslist = [];
		//console.log("rowselect", event)
		var rwCount = event.api.getSelectedNodes();
		//var containerlist=[];
		var rowCount = event.data
		//console.log("rowCount ", rowCount)

		rwCount.map((item) => {
			containerslist.push(item.data.truck_no)
		});

		this.setState({
			containerslist: containerslist
		})

	}
	onClickShowMapView = async () => {
		//this.gridApi.deselectAll();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		if (this.state.containerslist.length > 0) {
			var reqparms = {
				truck_no: this.state.containerslist,
				dept_code: this.state.deptcode
			}
		}
		else {
			var reqparms = {
				truck_no: [],
				dept_code: this.state.deptcode
			}
		}

		await redirectURL.post("/consignments/mapview", reqparms)
			.then((response) => {
				//console.log("Res ",response.data.message)
				var records = response.data.message;

				var truck = JSON.parse(records.truck_info);
				if (truck.length > 0) {
					if (truck.length > 1) {
						var consinees = this.state.plants;
					}
					else {
						var consinees = records.consignee_coordinates;
					}
				}
				else {
					var consinees = [];
				}
				console.log("truckinfo,", truck)
				//console.log("truck.consignee_coordinates,",JSON.parse(records.consignee_coordinates))
				this.setState({
					open: true,
					maprowData: truck,
					rownode: truck,
					maptruckno: records.truck_no,
					consigneecoords: consinees,
					consigner_coordinates: records.consigner_coordinates
				});


				//containerslist=[];
			})
			.catch(function (e) {
				console.log("Error ", e)
			})
	}
	onOpenModal = () => {
		this.setState({ open: true });
	};

	onCloseModal = () => {
		this.gridApi.deselectAll();
		this.setState({ open: false });
	};


	onOpenForceCloseModal = () => {
		this.setState({ openforceclose: true });
	};

	onCloseForceCloseModal = () => {
		this.gridApi.deselectAll();
		this.setState({ openforceclose: false });
	};

	onCellUpdateData = (params) => {
		console.log("Parms ", params);
		var reqs = {
			rowid: params.data._id,
			tnpconsignment: params.value
		}
		redirectURL.post("consignments/updatetnpmarking", reqs)
			.then((response) => {
				console.log("Res ", response);
			})
	}
	async onShowSidebarAquireComponent(e) {

		// console.log(e);
		if (e.colDef.field == 'truck_no') {
			this.setState({
				sliderTranslatesidebar: "",
				loadshow: 'show-m'
			});
			let ComponentInfo = ComponentIndex['truck'];
			//console.log("ComponentInfo", ComponentInfo);
			var layoutdata1 = {
				truck_no: e.data['truck_no']
			};
			//console.log("layoutdata",layoutdata);
			await redirectURL.post('/consignments/truckDetails', layoutdata1)
				.then((response) => {
					// console.log("trucks response", response);
					var records = JSON.parse(JSON.stringify(response)).data;
					var truckrecords = records.truck;
					var consignrecords = records.consignments;
					var currenttrucklocation = records.maplocation;
					// console.log("Current Location",currenttrucklocation);
					this.setState({
						truckInfo: truckrecords,
						consignments: consignrecords,
						currenttrucklocation: currenttrucklocation
					});
				})
				.catch(function (error) {
					console.log(error);
				});
			this.displayData = await (<ComponentInfo context={this} truckNo={e.data[e.colDef.field]} truckInfo={this.state.truckInfo} consignments={this.state.consignments} currenttrucklocation={this.state.currenttrucklocation}></ComponentInfo>);
			this.setState({
				showdata: this.displayData,
				sliderTranslatesidebar: "slider-translate",
				loadshow: 'show-n',
				overly: 'show-m'
			});
		}
		else if (e.colDef.field == 'consignment_code') {
			this.setState({
				sliderTranslatesidebar: "",
				loadshow: 'show-m'
			});
			let ComponentInfo = ComponentIndex['consignment'];
			//console.log("ComponentInfo", ComponentInfo);
			var layoutdata1 = {
				consignment_code: e.data['consignment_code'],
				consignee_code: e.data['consignee_code']
			};
			// console.log(layoutdata1);
			await redirectURL.post('/consignments/consignmentDetails', layoutdata1)
				.then((response) => {
					// console.log("trucks response", response.data[0]);
					var consignment_details = response.data;
					// If gateouttime is current time - It shows "No Route data available"

					this.setState({
						consignment_details: consignment_details,
						//consignments:consignrecords,
						//currenttrucklocation:currenttrucklocation
					});
					// console.log(this.state.consignment_details);
				})
				.catch(function (error) {
					console.log(error);
				});
			try {
				this.displayData = await (<ComponentInfo context={this} consignment={this.state.consignment_details}></ComponentInfo>);
				this.setState({
					showdata: this.displayData,
					sliderTranslatesidebar: "slider-translate",
					loadshow: 'show-n',
					overly: 'show-m'
				});
			}
			catch (e) {
				this.setState({
					showdata: "",
					sliderTranslatesidebar: "",
					loadshow: 'show-n',
					overly: 'show-n'
				});
			}

		}
	}


	handlerReachedDealerTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);

		var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

		this.setState({
			reached_dealer_time: startdate
		});
		//let value = event.target.value;
		//this.setState({'startDate':value});
	}

	handlerLeftDealerTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);

		var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

		this.setState({
			left_dealer_time: startdate
		});
		//let value = event.target.value;
		//this.setState({'startDate':value});
	}

	handlerInvoiceTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);

		var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

		this.setState({
			invoice_time: startdate
		});
		//let value = event.target.value;
		//this.setState({'startDate':value});
	}
	onClickForceClose = (params) => {
		//console.log("Pareas ", params)
		this.setState({
			forceclosedata: params.data,
			sliderForceCloseTranslate: "slider-translate",
			overly: 'show-m'
		})
	}
	changeHandler = (event) => {
		//console.log("Select", event.target.value);
		let name = event.target.name;
		let value = event.target.value;
		this.setState({ [name]: value });
	}
	onClickShowRakeSidebar = (params) => {
		//console.log("Pareas ", params)
		var rownode = this.gridApi.getSelectedNodes();
		console.log("rr ", rownode);
		//if(rownode.length > 0)
		//{
		this.setState({
			forceclosedata: params.data,
			sliderRakeTranslate: "slider-translate",
			overly: 'show-m'
		})
		// }
		// else
		// {
		// 	this.setState({
		// 		show:true,
		// 		basicTitle:"Please choose atleast one consignment",
		// 		basicType:"danger"
		// 	})
		// }

	}
	onChangeRakeItem(rakeitem) {
		this.setState(
			{ rakeitem },
			() => console.log(`Consigner Option selected:`, this.state.rakeitem)
		);

		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}
	onChangeLoadingPointItem(loadpoint) {

		// if(this.state.destinationpoint.value != null  && this.state.destinationpoint.value != undefined && this.state.destinationpoint.value != ""){
		if (this.state.destinationpoint.value !== loadpoint.value) {
			console.log('loadpoint', loadpoint, '\n this.state.destinationpoint', this.state.destinationpoint)
			this.setState(
				{ loadpoint },
				() => console.log(`Consigner Option selected:`, this.state.loadpoint)
			);
		} else {
			this.setState({
				show1: true,
				basicTitle1: "Loading Yard and Destination Yard cannont be same",
				basicType1: "warning",
				loadpoint: { label: '', value: '' }
			})
		}

		// }

	}
	onChangeDestinationPointItem(destinationpoint) {
		// if(this.state.loadpoint.value != null  && this.state.loadpoint.value != undefined && this.state.loadpoint.value != ""){
		if (this.state.loadpoint.value != destinationpoint.value) {
			this.setState(
				{ destinationpoint },
				() => console.log(`Consigner Option selected:`, this.state.destinationpoint)
			);
		} else {
			this.setState({
				show1: true,
				basicTitle1: "Loading Yard and Destination Yard cannont be same",
				basicType1: "warning",
				destinationpoint: { label: '', value: '' }
			})
		}
		// }	


	}
	selectrakeoptins() {
		let items = [];
		//console.log("this.state.rakeslist ", this.state.rakeslist)
		items.push({ "value": "NMG", "label": "NMG" })
		//console.log("transportercode ", JSON.parse(localStorage.getItem("transportercode")))
		try {
			var transportercode = JSON.parse(localStorage.getItem("transportercode"));
			if (transportercode.indexOf("T305") >= 0) {

				items.push({ "value": "DBKM", "label": "DBKM" })
			}
		}
		catch (e) {

		}
		try {
			if (this.state.rakeslist.length > 0) {
				this.state.rakeslist.map((item) => {
					items.push({ "value": item.truck_no, "label": item.truck_no })
				})
			}
		}
		catch (e) {

		}

		return items;
	}
	selectloadingpoints() {
		let items = [];
		try {
			if (this.state.loadingpoints.length > 0) {
				this.state.loadingpoints.map((item) => {
					items.push({ "value": item._id, "label": item.geofence_name })
				})
			}
		}
		catch (e) {

		}
		return items;
	}
	selectdestinationpoints() {
		let items = [];
		try {
			if (this.state.destinationpoints.length > 0) {
				this.state.destinationpoints.map((item) => {
					items.push({ "value": item._id, "label": item.geofence_name })
				})
			}
		}
		catch (e) {

		}

		return items;
	}
	formRakeHandler = async (event) => {
		event.preventDefault()
		// var rownode = this.gridApi.getSelectedNodes();
		// //console.log("rownode ", rownode);
		var arrlist = []
		var flag = 0;
		this.setState({
			overly: 'show-m',
			loadshow: 'show-m',
		})
		var rakeno = this.state.rakeitem;
		var loadpoint = this.state.loadpoint.label;
		var destinationpoint = this.state.destinationpoint.label;
		//console.log("destinationpoint ",destinationpoint)
		var dispatch_Date = document.getElementById("dispatch_Date").value;
		var dd = dispatch_Date.split(" ");
		var disdate = dd[0].split("-");
		var dispatchdate = disdate[2] + "-" + disdate[1] + "-" + disdate[0] + " " + dd[1];
		var rr_number = this.state.rr_number;
		var upload_type = "Assign to rake"
		var upload_department = "SNDG"
		var uploaded_by = localStorage.getItem("email")
		var upload_time = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
		await this.state.csvcontent.map(async (item) => {
			if (isEmpty(item) == false && item != "" && item != undefined) {
				//console.log("item ", item)
				if (item.consignment_code != '') {
					await redirectURL.post("/consignments/item", {
						consignment_code: item.consignment_code
					})
						.then((response) => {
							//console.log("Res ", response.data)
							var record = response.data;
							if (record.length > 0) {
								//console.log(record[0].gate_out_time)
								var s1 = moment.parseZone(dispatchdate).format("x");
								var g = getYYYYMMDDHHMMSS(record[0].gate_out_time);
								var s2 = moment.parseZone(g).format("x");
								//console.log("S1 ", s1);
								//console.log("S2 ", s2);
								if (s1 > s2) {
									flag = 1;
								}
								else {
									flag = 0;
									this.setState({
										show: true,
										basicTitle: "Dispatch Date should be greater than Consignment Gate out time",
										basicType: "danger"
									});
								}
							}

						})
				}

			}

		})


		var reqparams = {
			rownode: this.state.csvcontent,
			rake_no: rakeno.value,
			rake_loading_point_name: loadpoint,
			rake_loading_point_id: loadpoint,
			rake_delivery_point_name: destinationpoint,
			rake_delivery_point_id: destinationpoint,
			expected_dispatch_date: dispatchdate,
			rr_number: rr_number,
			upload_type: upload_type,
			upload_department: upload_department,
			uploaded_by: uploaded_by,
			upload_time: upload_time,
		}
		let saveData = new FormData();
		saveData.append("file", this.state.file);
		saveData.append("rownode", JSON.stringify(this.state.csvcontent));
		saveData.append("rake_no", rakeno.value);
		saveData.append("rake_loading_point_name", loadpoint);
		saveData.append("rake_loading_point_id", loadpoint);
		saveData.append("rake_delivery_point_name", destinationpoint);
		saveData.append("rake_delivery_point_id", destinationpoint);
		saveData.append("expected_dispatch_date", dispatchdate);
		saveData.append("rr_number", rr_number);
		saveData.append("upload_type", upload_type);
		saveData.append("upload_department", upload_department);
		saveData.append("uploaded_by", uploaded_by);
		saveData.append("upload_time", upload_time);
		//console.log("reqparams ",reqparams)
		if (loadpoint != '' && loadpoint != undefined && destinationpoint != '' && destinationpoint != undefined && rr_number != '' && rr_number != undefined && rakeno.value != '' && rakeno.value != undefined && dispatchdate != '' && dispatchdate != undefined) {
			redirectURL.post("/consignments/saveRakeData", saveData)
				.then((response) => {
					//console.log("Resposne  ", response)
					if (response.data.status == 'Success') {

						this.setState({
							show: true,
							basicType: 'success',
							basicTitle: "Successfully Uploaded.",
							uploadDivWidth: '0%',
							sliderTranslate: '',
							showDiv: 'show-n',
							uploadFile: '',
							overly: 'show-n',
							loadshow: 'show-n',
							file: ''
						})

					}else if(response.data.status == 'Failure'){
						this.setState({
							show: true,
							basicType: 'warning',
							basicTitle: response.data.message,
							uploadDivWidth: '0%',
							sliderTranslate: '',
							showDiv: 'show-n',
							uploadFile: '',
							overly: 'show-n',
							loadshow: 'show-n',
							file: ''
						})
					}
					else {
						this.setState({
							sliderRakeTranslate: "",
							overly: 'show-n',
							loadshow: "show-n"
						})
						//window.location.reload();
					}
				})
				.catch(function (e) {
					console.log("Error ", e)
				})
		}
		else {
			this.setState({
				show1: true,
				basicTitle1: 'Please fill all fields to continue.',
				basicType1: "danger",
				overly: 'show-n',
				loadshow: 'show-n'
			});
		}
	}
	formForceCloseHandler(event) {
		event.preventDefault()

		var consignemnt = this.state.forceclosedata;
		var consignment_code = consignemnt.consignment_code;
		var consignee_code = consignemnt.consignee_code;

		var truck_no = consignemnt.truck_no;
		var dept_code = this.state.deptcode;
		var force_closure_time = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
		var userid = localStorage.getItem('userid');
		var reasonforceclose = this.state.reasonforceclose;
		//  var reached_dealer_time =  moment.parseZone(new Date(this.state.reached_dealer_time)).format("YYYY-MM-DD")+" "+this.state.reachedhh+":"+this.state.reachedmm+":"+this.state.reachedss;
		//  var left_dealer = moment.parseZone(new Date(this.state.left_dealer_time)).format("YYYY-MM-DD")+" "+this.state.lefthh+":"+this.state.leftmm+":"+this.state.leftss;

		// 	var reached_dealer_time =  document.getElementById("reached_dealer_time").value;
		// 	if(reached_dealer_time != '')
		// 	{
		// 		var rtime = reached_dealer_time.split(" ");
		// 		var splitrtime = rtime[0].split("-");
		// 		var reachdealertime = splitrtime[2]+"-"+splitrtime[1]+"-"+splitrtime[0]+" "+rtime[1]+":00";  
		// 		console.log("reached_dealer_time ", reachdealertime)
		// 	}
		//    else{
		// 	var reachdealertime = "";
		//    }
		// 	var left_dealer = document.getElementById("left_dealer_time").value;  
		// 	if(left_dealer != '')
		// 	{
		// 		var ltime = left_dealer.split(" ");
		// 		var splitltime = ltime[0].split("-");
		// 		var leftdealertime = splitltime[2]+"-"+splitltime[1]+"-"+splitltime[0]+" "+ltime[1]+":00";
		// 		console.log("leftdealertime ", leftdealertime)
		// 	}   
		// 	else
		// 	{
		// 		var leftdealertime=""
		// 	}    

		if (this.state.deptcode == "SNDG") {
			var invoice_time = getHyphenYYYYMMDDHHMMSS(consignemnt.invoice_time);
			var reqdata = {
				dept_code: dept_code,
				consignment_code: [consignment_code],
				consignee_code: consignee_code,
				truck_no: truck_no,
				force_closure_time: force_closure_time,
				userid: userid,
				reasonforceclose: reasonforceclose,
				// reached_dealer_time:reachdealertime,
				invoice_time: invoice_time,
				// left_dealer:leftdealertime
			}
		}
		else {
			var reqdata = {
				dept_code: dept_code,
				consignment_code: [consignment_code],
				consignee_code: "",
				truck_no: truck_no,
				force_closure_time: force_closure_time,
				userid: userid,
				reasonforceclose: reasonforceclose,
				// reached_dealer_time:reachdealertime,
				invoice_time: "",
				// left_dealer:leftdealertime
			}
		}

		//console.log("reqdata ",reqdata)
		if (reasonforceclose.replace(/\s/g, "").length) {
			if (reasonforceclose != '') {
				redirectURL.post("/consignments/updateRailSingleConsignmentForceClose", reqdata)
					.then((response) => {
						console.log("Resposne updateConsignmentForceClose ", response)
						if (response.data.status == 'Success') {
							this.setState({
								show: true,
								basicTitle: response.data.message,
								basicType: "success",
								loadshow: 'show-n',
								left_dealer_time: '',
								reached_dealer_time: '',
								reachedhh: '',
								reachedmm: '',
								reachedss: '',
								lefthh: '',
								leftmm: '',
								leftss: '',
								reasonforceclose: "",
								openforceclose: false
							});
						}
						else {
							this.setState({
								show: true,
								basicTitle: response.data.message,
								basicType: "danger",
								loadshow: 'show-n',
								left_dealer_time: '',
								reached_dealer_time: '',
								reachedhh: '',
								reachedmm: '',
								reachedss: '',
								lefthh: '',
								leftmm: '',
								leftss: '',
								reasonforceclose: '',
								openforceclose: false
							});
						}

					})
					.catch(function (e) {
						console.log("Error ", e)
					})
			}
			else {
				this.setState({
					show: true,
					basicTitle: 'Reason for closue should not be empty',
					basicType: "danger",
				});
			}
		}
		else {
			this.setState({
				show: true,
				basicTitle: 'Reason for closue should not be empty',
				basicType: "danger",
			});
		}

	}

	onShowTransitLoadLegs(params) {
		console.log("In Transit to Loading Yard ", params)
	}
	onShowRakeLegs(params) {

		console.log("Rake Attached ", params)
	}
	onShowIntransitDestinationLegs(params) {

		console.log("In Transit To Destination Yard ", params)
	}
	onShowAtDestinationLegs(params) {

		console.log("At Destination Yard ", params)
	}
	onShowDeliveredLegs(params) {

		console.log("Delivered ", params)
	}
	resetUpload = () => {
		this.setState({
			bulkslideForDispatch: '',
			uploadDivWidth: '0%',
			bulkdeliverslide: '',
			bulkslide: '',
			showDiv: 'show-n',
			overly: 'show-n',
			file: ''
		});
		document.getElementById("upform").reset();
	}
	onClickShowBulkUpload() {
		this.setState({
			bulkslide: "slider-translate",
			overly: 'show-m'
		});
	}

	onClickShowBulkUploadForMissingMileDispatch = () => {
		this.setState({
			bulkslideForDispatch: 'slider-translate',
			overly: "show-m"
		})
	}

	onClickShowBulkDeliverUpload() {
		this.setState({
			bulkdeliverslide: "slider-translate",
			overly: 'show-m'
		});
	}
	changeFileHandler = async (e) => {
		//console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }

		const config = {
			headers: [
				{
					name: 'consignment_code',
					inputName: 'consignment_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				}
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
			.then((csvData) => {
				this.setState({
					csvcontent: csvData.data
				});
			})
			.catch(err => { })

		var out = new Promise(function (reject, resolve) {
			var reader = new FileReader();
			reader.onload = async function (e) {
				var contents = await e.target.result;
				console.log("contents ", contents)
				resolve(contents);
			};
			var tt = reader.readAsText(e.target.files[0]);
			console.log("tt ", tt)
		});
		//console.log("Out ", out);

		this.setState({
			file: e.target.files[0]
		});
		//console.log("e.target.files[0].type ", e.target.files[0].type);

		if (e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
		}
		else {
			e.target.value = null;
			this.setState({
				uploadFile: '',
				show: true,
				basicType: 'danger',
				basicTitle: 'Please upload file having extensions .csv only.',
			});
		}

	}

	changeFileHandler1 = (e) => {
		const config = {
			headers: [				
				{
					name: 'truck_no',
					inputName: 'truck_no',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},{
					name: 'consignment_code',
					inputName: 'consignment_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
			.then((csvData) => {
				this.setState({
					csvcontent1: csvData.data
				});
			})
			.catch(err => { })

		var out = new Promise(function (reject, resolve) {
			var reader = new FileReader();
			reader.onload = async function (e) {
				var contents = await e.target.result;
				console.log("contents ", contents)
				resolve(contents);
			};
			var tt = reader.readAsText(e.target.files[0]);
			console.log("tt ", tt)
		});
		//console.log("Out ", out);

		this.setState({
			file1: e.target.files[0]
		});
		//console.log("e.target.files[0].type ", e.target.files[0].type);

		if (e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
		}
		else {
			e.target.value = null;
			this.setState({
				uploadFile: '',
				show: true,
				basicType: 'danger',
				basicTitle: 'Please upload file having extensions .csv only.',
			});
		}
	}

	changeFileHandlerBulkEdit = (e) =>{
		const config = {
			headers: [
				{
					name: 'consignment_code',
					inputName: 'consignment_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'truck_loading_yard_reached_on',
					inputName: 'truck_loading_yard_reached_on',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},{
					name: 'rake_no',
					inputName: 'rake_no',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'rr_number',
					inputName: 'rr_number',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'rake_loading_point_name',
					inputName: 'rake_loading_point_name',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'rake_delivery_point_name',
					inputName: 'rake_delivery_point_name',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'rake_delivery_point_reached_on',
					inputName: 'rake_delivery_point_reached_on',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'recent_dealer_reported',
					inputName: 'recent_dealer_reported',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'last_mile_truck_no',
					inputName: 'last_mile_truck_no',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'last_mile_dispatch_date',
					inputName: 'last_mile_dispatch_date',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'rake_loading_point_left_on',
					inputName: 'rake_loading_point_left_on',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
			.then((csvData) => {
				this.setState({
					csvcontentBulkEditDeliveredConsignments: csvData.data
				});
			})
			.catch(err => { })
		console.log(this.state.csvcontentBulkEditDeliveredConsignments)
		var out = new Promise(function (reject, resolve) {
			var reader = new FileReader();
			reader.onload = async function (e) {
				var contents = await e.target.result;
				console.log("contents ", contents)
				document.getElementById("appednCSVData").innerHTML = contents
				resolve(contents);
			};
			var tt = reader.readAsText(e.target.files[0]);
			console.log("tt ", tt)
		});
		//console.log("Out ", out);

		this.setState({
			deliveredConsFile: e.target.files[0]
		});
		//console.log("e.target.files[0].type ", e.target.files[0].type);

		if (e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
		}
		else {
			e.target.value = null;
			this.setState({
				uploadFile: '',
				show: true,
				basicType: 'danger',
				basicTitle: 'Please upload file having extensions .csv only.',
			});
		}

	}

	changeBulkForceFileHandler = async (e) => {
		//console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }

		const config = {
			headers: [
				{
					name: 'truck_no',
					inputName: 'truck_no',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'consignment_code',
					inputName: 'consignment_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'reason',
					inputName: 'reason',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				}
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
			.then((csvData) => {
				this.setState({
					csvcontent: csvData.data
				});
			})
			.catch(err => { })

		var out = new Promise(function (reject, resolve) {
			var reader = new FileReader();
			reader.onload = async function (e) {
				var contents = await e.target.result;
				console.log("contents ", contents)
				resolve(contents);
			};
			var tt = reader.readAsText(e.target.files[0]);
			console.log("tt ", tt)
		});
		//console.log("Out ", out);

		this.setState({
			file: e.target.files[0]
		});		
		if(e.target.files[0].type == '' || e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
		if (e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
		}
		else {
			e.target.value = null;
			this.setState({
				uploadFile: '',
				show: true,
				basicType: 'danger',
				basicTitle: 'Please upload file having extensions .csv only.',
			});
		}

	}


	// uploadFormHandler(event){
	// 	event.preventDefault();
	// 	console.log("this.state.file ", this.state.file)
	// 	if(this.state.file != '')
	// 	{
	// 		var formData = new FormData(event.target);
	// 		formData.append('uploadFile',this.state.file);
	// 		formData.append("userId", localStorage.getItem('userid'));

	// 		console.log('file',this.state.csvcontent);
	// 		this.setState({
	// 			loadshow:'show-m'
	// 		}); 
	// 		var rparams = {
	// 			uploadFile:this.state.file,
	// 			userId:localStorage.getItem('userid'),
	// 		}
	// 		console.log("rparams ",rparams);
	// 		redirectURL.post("/consignments/uploadBulkAssigns", rparams).
	// 		then(
	// 			(response)=>{
	// 				console.log(response);
	// 				console.log(response.data);
	// 				document.getElementById("upform").reset();
	// 				if(response.data.status == 'failure')
	// 				{
	// 					if(response.data.message.empty!="")
	// 					{
	// 						var emtymesg = response.data.message.empty
	// 					}
	// 					else{
	// 						var emtymesg = "NA";
	// 					}
	// 					if(response.data.message.wrong!="")
	// 					{
	// 						var wrongymesg = response.data.message.wrong
	// 					}
	// 					else{
	// 						var wrongymesg = "NA";
	// 					}
	// 					if(response.data.message.scriptings!="")
	// 					{
	// 						var scriptingsmesg = response.data.message.scriptings
	// 					}
	// 					else{
	// 						var scriptingsmesg = "NA";
	// 					}
	// 					var resmessage = "";
	// 					resmessage=resmessage+emtymesg+", ";
	// 					resmessage=resmessage+wrongymesg+", ";
	// 					resmessage=resmessage+scriptingsmesg

	// 					this.setState({
	// 						//csverrmessage:response.data.status,
	// 						show: true, basicType:'danger', basicTitle:resmessage,
	// 						uploadDivWidth:'0%',
	// 						sliderTranslate:'',
	// 						showDiv:'show-n',
	// 						loadshow:'show-n',
	// 						uploadFile:'',
	// 						file:''
	// 					});

	// 				}
	// 				else
	// 				{
	// 					this.setState({
	// 						show: true, basicType:'success', basicTitle:response.data.message,
	// 						uploadDivWidth:'0%',
	// 						sliderTranslate:'',
	// 						showDiv:'show-n',
	// 						uploadFile:'',
	// 						loadshow:'show-n',
	// 						file:''
	// 					});

	// 				}


	// 			}
	// 		)
	// 		.catch(function(error){
	// 			console.log(error);
	// 		});
	// 	}

	// }

	onClickBulkEditChanges = (e) =>{
		e.preventDefault()
		// console.log("this.state.deliveredConsFile ", this.state.deliveredConsFile)
		if (this.state.deliveredConsFile != '') {
			var csvdd = this.state.deliveredConsFile;

			// console.log("event ", e.target.uploadFile.value);
			var formData = new FormData();
			formData.append('uploadFile', this.state.deliveredConsFile);
			formData.append("userId", localStorage.getItem('userid'));
			formData.append("csvcontent", this.state.csvcontentBulkEditDeliveredConsignments);
			var rparams = {
				uploadFile: this.state.deliveredConsFile,
				userId: localStorage.getItem('userid'),
				csvcontent: this.state.csvcontentBulkEditDeliveredConsignments
			}
			//console.log('file',formData);
			//console.log('rparams',rparams);
			this.setState({
				loadshow: 'show-m'
			});
			redirectURL.post("/rakeplanning/bulkUpdateDeliveredConsignments", rparams).then(
					(response) => {
						console.log('bulkUpdateDeliveredConsignments',response.data);
						//document.getElementById("upform").reset();
						if (response.data.invalidCount > 0) {
							this.setState({
								//csverrmessage:response.data.status,
								show: true, basicType: 'danger', 
								basicTitle: "Failed To Update as date field format is not as per system," + response.data.invalidCount + " records didnt uploaded",
								uploadDivWidth: '0%',
								sliderBulkEditConsignments: '',
								showDiv: 'show-n',
								loadshow: 'show-n',
								deliveredConsFile: ''
							});
						}
						else {
							this.setState({
								show: true,
								basicType: 'success',
								basicTitle: "Successfully Updated " + response.data.validCount + " records.",
								sliderBulkEditConsignments: '',
								showDiv: 'show-n',
								uploadFile: '',
								loadshow: 'show-n',
								deliveredConsFile: ''
							});
						}
					}
				)
				.catch(function (error) {
					console.log(error);
				});
		}
	}


	uploadBulkFormHandler(event) {
		event.preventDefault();

		if (this.state.file != '') {
			// console.log("this.state.file ", this.state.file)
			var csvdd = this.state.file;

			console.log("event ", event.target.uploadFile.value);
			var formData = new FormData();
			formData.append('uploadFile', this.state.file);
			formData.append("userId", localStorage.getItem('userid'));
			formData.append("csvcontent", this.state.csvcontent);
			var rparams = {
				uploadFile: this.state.file,
				userId: localStorage.getItem('userid'),
				csvcontent: this.state.csvcontent
			}
			//console.log('file',formData);
			//console.log('rparams',rparams);
			this.setState({
				loadshow: 'show-m'
			});
			redirectURL.post("/consignments/uploadBulkAssigns", rparams)
				.then(
					(response) => {
						//console.log(response);
						console.log(response.data.data);
						//document.getElementById("upform").reset();
						if (response.data.data.status == 'failure') {
							if (response.data.message.empty != "") {
								var emtymesg = response.data.message.empty
							}
							else {
								var emtymesg = "NA";
							}
							if (response.data.message.wrong != "") {
								var wrongymesg = response.data.message.wrong
							}
							else {
								var wrongymesg = "NA";
							}
							if (response.data.message.scriptings != "") {
								var scriptingsmesg = response.data.message.scriptings
							}
							else {
								var scriptingsmesg = "NA";
							}
							var resmessage = "";
							resmessage = resmessage + emtymesg + ", ";
							resmessage = resmessage + wrongymesg + ", ";
							resmessage = resmessage + scriptingsmesg

							this.setState({
								//csverrmessage:response.data.status,
								show: true, basicType: 'danger', basicTitle: resmessage,
								uploadDivWidth: '0%',
								sliderTranslate: '',
								showDiv: 'show-n',
								loadshow: 'show-n',
								uploadFile: '',
								file: ''
							});

						}
						else {
							console.log("here")
							this.setState({
								show: true,
								basicType: 'success',
								basicTitle: "Successfully Uploaded.",
								uploadDivWidth: '0%',
								sliderTranslate: '',
								showDiv: 'show-n',
								uploadFile: '',
								loadshow: 'show-n',
								file: ''
							});

						}


					}
				)
				.catch(function (error) {
					console.log(error);
				});
		}

	}

	uploadBulkFormHandlerForDispatchDate = (event) => {
		event.preventDefault();
		if(this.state.file1 != '')
		{
			if($(".uploadDispatchDate").val() != "__-__-____")
			{
				//console.log("this.state.file ",this.state.file)
				var csvdd = this.state.file1;
				var upload_type = "Assign last mile truck"
				var upload_department ="SNDG"
				var uploaded_by = localStorage.getItem("email")
				var upload_time = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
				// console.log("event ",event.target.uploadFile.value);
				
				var rparams = {
					csvcontent:this.state.csvcontent1,
					bulkUploadYard : this.state.uploadYard,
					dispatch_date : $(".uploadDispatchDate").val(),
					upload_type : upload_type,
					upload_department : upload_department,
					uploaded_by : uploaded_by,
					upload_time : upload_time,
				}
			// console.log(rparams);
				let saveData = new FormData();
				saveData.append("file", this.state.file1);
				saveData.append("csvcontent", JSON.stringify(this.state.csvcontent1));
				saveData.append("bulkUploadYard", this.state.uploadYard);
				saveData.append("dispatch_date", $(".uploadDispatchDate").val());
				saveData.append("upload_type", upload_type);
				saveData.append("upload_department", upload_department);
				saveData.append("upload_time", upload_time);
				saveData.append("uploaded_by", uploaded_by);
				if (this.state.missingConsBtn == 'btn-danger') saveData.append('missing_data', 1)
				var csvContent = this.state.csvcontent1;
				// console.log(csvContent)
				// console.log(typeof(csvContent));
				var emptyFlag  = 0;
				for(var i=1;i<csvContent.length;i++)
				{
					var truck_no = csvContent[i].truck_no;
					var consignment_code = csvContent[i].consignment_code;
					//console.log(consignment_code)
					if(truck_no == "" || consignment_code == "" || truck_no == null || truck_no == undefined || consignment_code == undefined || consignment_code==null)
					{
						emptyFlag = 1;
					}
					
				}
				
				this.setState({
					loadshow:'show-m'
				}); 
				var emptyFlag = 0;
				if(emptyFlag == 0)
				{
					redirectURL.post("/consignments/updateBulkRoadpickUp",saveData)
					.then((response)=>{
						var result = response.data;
						//console.log("response bulk upload ", result)
						if(result.notvalidrecords.length == 0)
						{
							if(result.flag == 0)
							{
								this.setState({
									//csverrmessage:response.data.status,
									show: true, basicType:'success', basicTitle:"Successfuly update File.",
									uploadDivWidth:'0%',
									showDiv:'show-n',
									loadshow:'show-n',
									uploadFile:'',
									bulkslide:"",
									overly:"show-n",
									file:''
								});
							}
							else{
								this.setState({
									show: true, basicType:'danger', 
									basicTitle:"Dispatch date should be greater than destination reached date",
									uploadDivWidth:'0%',
									showDiv:'show-n',
									loadshow:'show-n',
									uploadFile:'',
									bulkslide:"",
									overly:"show-n",
									file:''
								});
							}
							
						}
						else{
							if(result.flag == 0)
							{
								this.setState({
									//csverrmessage:response.data.status,
									show: true, basicType:'danger', 
									basicTitle:"Following Consignment Codes does not have destination yard. "+result.notvalidrecords,
									uploadDivWidth:'0%',
									showDiv:'show-n',
									loadshow:'show-n',
									uploadFile:'',
									bulkslide:"",
									overly:"show-n",
									file:''
								});
							}
							else
							{
								this.setState({
									show: true, basicType:'danger', 
									basicTitle:"Dispatch date should be greater than destination reached date",
									uploadDivWidth:'0%',
									showDiv:'show-n',
									loadshow:'show-n',
									uploadFile:'',
									bulkslide:"",
									overly:"show-n",
									file:''
								});
							}
						}
					}).catch((e)=>{
						console.log(e);
					})
				}
				else
				{
					$("#bulkUploadID").val("");
					
						this.setState({
							//csverrmessage:response.data.status,
							show: true, basicType:'danger', basicTitle:"Consignment Code or Truck No Must Not be Empty.",
							uploadDivWidth:'0%',
							sliderTranslate:'',
							showDiv:'show-n',
							loadshow:'show-n',
							uploadFile:'',
							file:''
						});
				}
			}
			else{
				this.setState({
					basicTitle: "Dispatch Date Must not be Empty",
					basicType: "warning",
					show: true
				})
			}
			
			
		}

	}

	changeBulkUploadYard = (e) => {
		console.log('changeBulkUploadYard', e)

		this.setState({
			uploadYard: e.label,
		})
	}

	changeSetDeliverFileHandler = async (e) => {
		//console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }

		const config = {
			headers: [
				{
					name: 'consignment_code',
					inputName: 'consignment_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'deliverdate',
					inputName: 'deliverdate',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'reason',
					inputName: 'reason',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				}
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
			.then((csvData) => {
				this.setState({
					csvdelivercontent: csvData.data
				});
			})
			.catch(err => { })

		var out = new Promise(function (reject, resolve) {
			var reader = new FileReader();
			reader.onload = async function (e) {
				var contents = await e.target.result;
				console.log("contents ", contents)
				resolve(contents);
			};
			var tt = reader.readAsText(e.target.files[0]);
			console.log("tt ", tt)
		});
		//console.log("Out ", out);

		this.setState({
			file: e.target.files[0]
		});
		//console.log("e.target.files[0].type ", e.target.files[0].type);

		if (e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
		}
		else {
			e.target.value = null;
			this.setState({
				uploadFile:'',
				show: true, 
				basicType:'danger', 
				basicTitle:'Please upload file having extensions .csv only.',
			});
		}

	}
	uploadBulkDeliverFormHandler(event) {
		event.preventDefault();
		
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Rail Transporter bulk upload set delivered",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		var transporter_code = localStorage.getItem('transportercode')
		var upload_type = "Bulk mark delivered"
		var upload_department = "SNDG"
		var uploaded_by = localStorage.getItem("email")
		var upload_time = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
		var dept_code = this.state.deptcode;
		var tpt_confirm_delivery = 1
		var reqparams = {
			tpt_confirm_delivery: tpt_confirm_delivery,
			csvdelivercontent: this.state.csvdelivercontent,
			email: localStorage.getItem("email"),
			dept_code: dept_code,
			transporter_code: transporter_code,
			upload_type: upload_type,
			upload_department: upload_department,
			uploaded_by: uploaded_by,
			upload_time: upload_time,
		}
		// const formattedCSV = this.state.csvdelivercontent.map(item => {
		// 	return {
		// 	  ...item,
		// 	  deliverdate: convertDateToDesiredFormat(item.deliverdate.replace(/,/g, '')) // Remove commas from the date string
		// 	};
		//   });
		// const formattedCSV = this.state.csvdelivercontent.map(item => {
		// 	return {
		// 	  ...item,
		// 	  deliverdate: convertDateToDesiredFormat(item.deliverdate)
		// 	};
		//   });
		  
		  // Create a FormData object with the updated csvdelivercontent.
		  let saveData = new FormData();
		  saveData.append("file", this.state.file);
		  saveData.append("tpt_confirm_delivery", tpt_confirm_delivery);
		  saveData.append("csvdelivercontent", JSON.stringify(this.state.csvdelivercontent)); // Use the updated data
		  saveData.append("email", localStorage.getItem("email"));
		  saveData.append("dept_code", dept_code);
		  saveData.append("transporter_code", transporter_code);
		  saveData.append("upload_type", upload_type);
		  saveData.append("upload_department", upload_department);
		  saveData.append("upload_time", upload_time);
		  saveData.append("uploaded_by", uploaded_by);
		  
		//   console.log(formattedCSV, 'csvdata');
		redirectURL.post("/consignments/transporterbulkdeliverupdate", saveData)
			.then((response) => {
				var result = response.data;
				console.log("response bulk upload ", result)
				if (result.notvalidrecords.length == 0) {
					if (result.flag == 0) {
						this.setState({
							//csverrmessage:response.data.status,
							show: true, basicType: 'success', basicTitle: "Successfuly update File.",
							uploadDivWidth: '0%',
							showDiv: 'show-n',
							loadshow: 'show-n',
							uploadFile: '',
							bulkdeliverslide: "",
							overly: "show-n",
							file: ''
						});
					}
					else {
						this.setState({
							show: true, basicType: 'danger',
							basicTitle: "Delivered date should be greater than last mile dispatch date",
							uploadDivWidth: '0%',
							showDiv: 'show-n',
							loadshow: 'show-n',
							uploadFile: '',
							bulkdeliverslide: "",
							overly: "show-n",
							file: ''
						});
					}

				}
				else {
					if (result.flag == 0) {
						this.setState({
							//csverrmessage:response.data.status,
							show: true, basicType: 'danger',
							basicTitle: "Delivered date should be in the format of DD/MM/YYYY HH:mm",
							uploadDivWidth: '0%',
							showDiv: 'show-n',
							loadshow: 'show-n',
							uploadFile: '',
							bulkdeliverslide: "",
							overly: "show-n",
							file: ''
						});
					}
					else {
						this.setState({
							show: true, basicType: 'danger',
							basicTitle: "Following Consignment Codes does not have last mile dispatch date. " + result.notvalidrecords,
							uploadDivWidth: '0%',
							showDiv: 'show-n',
							loadshow: 'show-n',
							uploadFile: '',
							bulkdeliverslide: "",
							overly: "show-n",
							file: ''
						});
					}
				}
			})
			.catch(function (e) {
				console.log("Error ", e)
			})
	}

	onClickShowDeliverPopup = (params) => {
		//console.log('Del params ', params.data.last_mile_dispatch_date)
		if (params.data.last_mile_dispatch_date == '' || params.data.last_mile_dispatch_date == undefined) {
			this.setState({
				show: true,
				basicType: "danger",
				basicTitle: "Please add Last Mile Dispatch Date"
			})
		}
		else {
			var maxdate = moment.parseZone().add(1, 'days').format('YYYY-MM-DD');
			var mdate = moment.parseZone(params.data.gate_out_time).format("YYYY-MM-DD");
			var mindate = moment.parseZone(new Date(mdate)).subtract(1, 'days').format('YYYY-MM-DD');

			this.setState({
				delivermodal: true,
				deliverrow: params,
				deliverdate: '',
				deliverymaxDate: maxdate,
				deliveryminDate: mindate
			});
		}

	}

	handlerDeliverDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);

		var deliverdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

		this.setState({
			deliverdate: deliverdate
		});
	}

	onOpenDeliverModal = () => {
		this.setState({ delivermodal: true });
	};

	onCloseDeliverModal = () => {
		this.gridApi.deselectAll();
		this.setState({ delivermodal: false });
	};

	formSetDeliveredDate(event) {
		event.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Rail Transporter set delivered",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		var deliverrow = this.state.deliverrow;
		var selectedReason = this.state.selectedReason;
		if (selectedReason == 'Others') {

			var isdeliverreason = this.state.isdeliverreason;
		}
		else {

			var isdeliverreason = this.state.selectedReason;
		}
		if (this.state.deliverdate != '' && isdeliverreason != '') {
			var deliverdate = this.state.deliverdate;

			var reqparams = {
				tpt_confirm_delivery: 1,
				tpt_delivery_date: moment.parseZone(deliverdate).format("YYYY-MM-DD"),
				consigment_code: deliverrow.data.consignment_code,
				tpt_delivery_reason: isdeliverreason,
				email: localStorage.getItem("email")
			}
			redirectURL.post("/consignments/setDeliverDate", reqparams)
				.then((response) => {
					console.log("resss ", response.data)
					if (response.data.ok == 1) {
						this.setState({
							delivermodal: false,
							deliverdate: '',
							isdeliverreason: ''
						});
					}
				})
		}
		else {
			this.setState({
				show: true,
				basicTitle: 'Fields are mandatory',
				basicType: 'danger'
			});
		}

	}

	onClickForceMoveDestinationYard() {
		//console.log("Force MOve ")
		this.setState({
			sliderForceDestination: 'slider-translate',
			overly: "show-m"
		});
	}

	onClickForceMoveLoadingYard() {
		this.setState({
			sliderForceLoadingYard: 'slider-translate',
			overly: "show-m"
		});
	}

	onClickBulkEditDeliveredConsignments = () =>{
		this.setState({
			sliderBulkEditConsignments: 'slider-translate-60p',
			overly: "show-m"
		});
	}

	onClickBulkForceClose() {
		this.setState({
			sliderBulkForceClose: 'slider-translate',
			overly: "show-m"
		});
	}

	formForceMoveDestinationYardHandler = async (event) => {
		event.preventDefault()
		var dest_yard_reachedon = document.getElementById("dest_yard_reachedon").value;
		//console.log("dest_yard_reachedon ", dest_yard_reachedon)
		var dest_yard = dest_yard_reachedon.split(" ");
		var spyard = dest_yard[0].split("-");
		var destyardreachedon = spyard[2] + "-" + spyard[1] + "-" + spyard[0] + " " + dest_yard[1] + ":00"
		var movearr = []
		var upload_type = "Force move to destination yard"
		var upload_department = "SNDG"
		var uploaded_by = localStorage.getItem("email")
		var upload_time = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
		await this.state.csvcontent.map(async (item) => {
			if (isEmpty(item) == false && item != "" && item != undefined) {
				//console.log("item ", item)
				if (item.consignment_code != '') {
					await redirectURL.post("/consignments/item", {
						consignment_code: item.consignment_code
					})
						.then((response) => {
							//console.log("Res ", response.data)
							var record = response.data;
							if (record.length > 0) {
								//console.log(record[0].expected_rake_dispatch_date)
								if (record[0].expected_rake_dispatch_date != undefined) {
									var s1 = moment.parseZone(destyardreachedon).format("x");
									var g = getYYYYMMDDHHMMSS(record[0].expected_rake_dispatch_date);
									var s2 = moment.parseZone(g).format("x");
									//console.log("S1 ", s1);
									//console.log("S2 ", s2);
									if (s1 > s2) {
									}
									else {
										movearr.push(item.consignment_code)

									}
								}
								else {
									movearr.push(item.consignment_code)
								}

							}

						})
				}

			}

		})

		// console.log('forceClosed', movearr.length)
		if (movearr.length == 0) {
			var reqparams = {
				dest_yard_reachedon: destyardreachedon,
				userId: localStorage.getItem('userid'),
				csvcontent: this.state.csvcontent,
				upload_type: upload_type,
				upload_department: upload_department,
				upload_time: upload_time,
				uploaded_by: uploaded_by,
			}
			let saveData = new FormData();
			saveData.append("file", this.state.file);
			saveData.append("dest_yard_reachedon", destyardreachedon);
			saveData.append("userId", localStorage.getItem('userid'));
			saveData.append("csvcontent", JSON.stringify(this.state.csvcontent));
			saveData.append("upload_type", upload_type);
			saveData.append("upload_department", upload_department);
			saveData.append("upload_time", upload_time);
			saveData.append("uploaded_by", uploaded_by);
			console.log("this.state.missingConsBtn", this.state.missingConsBtn)
			if (this.state.missingConsBtn == 'btn-danger') saveData.append('missing_data', 1)

			redirectURL.post("/consignments/forcemovedestinationyard", saveData)
				.then((response) => {
					console.log("Respone ", response.data)
					var rec = response.data;
					if (rec.notvalid.length == 0) {
						this.setState({
							show: true,
							basicType: 'success',
							basicTitle: "Successfully Uploaded.",
							uploadDivWidth: '0%',
							sliderForceDestination: '',
							showDiv: 'show-n',
							uploadFile: '',
							loadshow: 'show-n',
							file: ''
						});
					}
					else {
						this.setState({
							show: true,
							basicType: 'danger',
							basicTitle: "Destination yard reached on should be greater than Dispatch date. " + rec.notvalid,
							uploadDivWidth: '0%',
							sliderForceDestination: '',
							showDiv: 'show-n',
							uploadFile: '',
							loadshow: 'show-n',
							file: ''
						});
					}

				})
		}
		else {
			this.setState({
				show: true,
				basicTitle: "Destination yard reached on should be greater than Dispatch date." + movearr,
				basicType: "danger"
			});
		}

	}

	onChangeLoadingYardItem(loadyardpoint) {
		this.setState(
			{ loadyardpoint },
			() => console.log(`Loading Yard Option selected:`, this.state.loadyardpoint)
		);

		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}

	formForceMoveLoadingYardHandler = async (event) => {
		event.preventDefault()
		var loadyardpoint = this.state.loadyardpoint;
		var loading_yard_reached_on = document.getElementById("loading_yard_reached_on").value;
		console.log("loadyardpoint ", loadyardpoint);
		var dest_yard = loading_yard_reached_on.split(" ");
		var spyard = dest_yard[0].split("-");
		var destyardreachedon = spyard[2] + "-" + spyard[1] + "-" + spyard[0] + " " + dest_yard[1] + ":00"
		var movearr = []
		var upload_type = "Force move to loading yard"
		var upload_department = "SNDG"
		var uploaded_by = localStorage.getItem("email")
		var upload_time = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
		var movearrdata = await this.state.csvcontent.map(async (item) => {
			if (isEmpty(item) == false && item != "" && item != undefined) {
				//console.log("item ", item)
				if (item.consignment_code != '') {
					await redirectURL.post("/consignments/item", {
						consignment_code: item.consignment_code
					})
						.then((response) => {
							//console.log("Res ", response.data)
							var record = response.data;
							if (record.length > 0) {
								//console.log("Gate Out", record[0].gate_out_time)
								console.log("Loading Yard Reached On", destyardreachedon)
								var gateout = moment.parseZone(record[0].gate_out_time).format("YYYY-MM-DD HH:mm:ss");
								console.log("gateout ", gateout)
								var s1 = moment.parseZone(destyardreachedon).format("x");
								var g = getYYYYMMDDHHMMSS(gateout);
								var s2 = moment.parseZone(g).format("x");
								var today = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
								console.log("today ", today)
								var s3 = moment.parseZone(today).format("x");
								console.log("S1 ", s1);
								console.log("S2 ", s2);
								console.log("S3 ", s3);
								if (parseInt(s1) > parseInt(s2)) {
									if (parseInt(s1) < parseInt(s3)) {
										movearr.push(item.consignment_code)
										//console.log(movearr)
									}
									else {
										console.log("condition Failed-1")
									}
								}
								else {
									console.log("condition Failed")
								}


							}

						})
				}

			}

		})
		// console.log("movearrdata",movearrdata)
		Promise.all(movearrdata).then(async () => {
			console.log(movearr)

			// if(movearr.length > 0)
			// {
			if (destyardreachedon != '' && destyardreachedon != undefined && loadyardpoint != '' && loadyardpoint != undefined) {
				// var reqparams = {
				// 	truck_loading_yard_reached_on: destyardreachedon,
				// 	truck_loading_yard_drop_location: loadyardpoint.label,
				// 	userId: localStorage.getItem('userid'),
				// 	csvcontent: this.state.csvcontent,
				// 	upload_type: upload_type,
				// 	upload_department: upload_department,
				// 	upload_time: upload_time,
				// 	uploaded_by: uploaded_by,
				// }
				// console.log(reqparams, "")
				let saveData = new FormData();
				saveData.append("file", this.state.file);
				saveData.append("truck_loading_yard_reached_on", destyardreachedon);
				saveData.append("truck_loading_yard_drop_location", loadyardpoint.label);
				saveData.append("userId", localStorage.getItem('userid'));
				saveData.append("csvcontent", JSON.stringify(this.state.csvcontent));
				saveData.append("upload_type", upload_type);
				saveData.append("upload_department", upload_department);
				saveData.append("upload_time", upload_time);
				saveData.append("uploaded_by", uploaded_by);

				await redirectURL.post("/consignments/forcemoveloadingyard", saveData)
					.then((response) => {
						console.log("Respone ", response.data)
						var rec = response.data;
						if (rec.notvalid.length == 0) {
							this.setState({
								show: true,
								basicType: 'success',
								basicTitle: "Successfully Uploaded.",
								uploadDivWidth: '0%',
								sliderForceDestination: '',
								showDiv: 'show-n',
								uploadFile: '',
								loadshow: 'show-n',
								file: ''
							});
						}
						else {
							this.setState({
								show: true,
								basimovearrcType: 'danger',
								basicTitle: "Loading yard reached on should be greater than Gate out time and less than current time. " + rec.notvalid,
								uploadDivWidth: '0%',
								sliderForceDestination: '',
								showDiv: 'show-n',
								uploadFile: '',
								loadshow: 'show-n',
								file: ''
							});
						}

					})
				// }
				// else
				// {
				// 	this.setState({
				// 		show:true,
				// 		basicTitle:"Loading yard reached on should be greater than Gate out time and less than current time." +movearr,
				// 		basicType:"danger"
				// 	});
				// }
			}
			else {
				this.setState({
					show1: true,
					basicTitle1: 'Please fill all fields to continue.',
					basicType1: "danger",
					loadshow: 'show-n'
				});
			}
		})

	}

	formBulkForceCloseHandler(event) {
		event.preventDefault();
		var dept_code = this.state.deptcode;
		//console.log(this.state.csvcontent);
		if (this.state.file != '') {
			//console.log("this.state.file ",this.state.file)
			var csvdd = this.state.file;
			var upload_type = "Bulk force closure"
			var upload_department = "SNDG"
			var uploaded_by = localStorage.getItem("email")
			var upload_time = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
			//console.log("event ",event.target.uploadFile.value);

			var rparams = {
				file: this.state.file,
				userId: localStorage.getItem('userid'),
				csvcontent: this.state.csvcontent,
				dept_code: dept_code,
				upload_type: upload_type,
				upload_department: upload_department,
				uploaded_by: uploaded_by,
				upload_time: upload_time,
			}
			let saveData = new FormData();
			saveData.append("file", this.state.file);
			saveData.append("userId", localStorage.getItem('userid'));
			saveData.append("dept_code", dept_code);
			saveData.append("csvcontent", JSON.stringify(this.state.csvcontent));
			saveData.append("upload_type", upload_type);
			saveData.append("upload_department", upload_department);
			saveData.append("upload_time", upload_time);
			saveData.append("uploaded_by", uploaded_by);

			//console.log('file',formData);
			//console.log('rparams',rparams);
			this.setState({
				loadshow: 'show-m'
			});
			var reasonFlag = 0;
			var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
			this.state.csvcontent.map(function (e, index) {
				if (index != 0) {
					if (e.reason == "" || e.reason == null || e.reason == undefined) {
						reasonFlag = 1;
					}
					if (reasonFlag == 0) {
						if (format.test(e.reason)) { reasonFlag = 1; }
					}
				}

		})
			if (reasonFlag == 0) {
				redirectURL.post("/consignments/uploadRailBulkForceClose", saveData)
					.then(
						(response) => {
							//console.log(response);
							//console.log(response.data);
							document.getElementById("upform").reset();
							if (response.data.message != 'success') {


							}
							else {
								this.setState({
									show: true, basicType: 'success', basicTitle: "Force Closed Successfully",
									uploadDivWidth: '0%',
									sliderTranslate: '',
									showDiv: 'show-n',
									uploadFile: '',
									loadshow: 'show-n',
									file: ''
								});
								// window.location.reload();
							}


						}).catch(function (error) {
							console.log(error);
						});
			}
			else {
				this.setState({
					//csverrmessage:response.data.status,
					show: true, basicType: 'danger', basicTitle: "Reason Must not be Empty and Must not Contain Special Charecters",
					loadshow: "show-n",
				});
			}


		}
	}


	onClickSaveGridState() {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Save Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();

		let screenpage = '';

		if (this.props.match.path == "/railconsignments") {
			screenpage = 'rail consignments';
		}
		else if (this.props.match.path == "/deliveredrailconsignments") {
			screenpage = 'rail delivered consignments';
		}

		let reqparams = {
			gridcolumns: window.colState,
			gridgroup: window.groupState,
			gridcolsort: window.sortState,
			gridcolfilter: window.filterState,
			userId: localStorage.getItem("userid"),
			screenurl: window.location.pathname,
			screentitle: screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
			.then((response) => {
				//console.log("State response ",response.data)
				this.setState({
					show: true,
					basicTitle: "Successfully saved grid layout",
					basicType: "success",
					screenurl: window.location.pathname,
					screentitle: screenpage
				})
			})
			.catch(function (e) {
				console.log("Error ", e)
			})
	}

	restoreGridStates() {
		try {


			if (this.state.usergridstate.length > 0) {
				var windowstates = this.state.usergridstate;
				this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
				this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
				this.gridApi.setSortModel(windowstates[0].gridcolsort);
				this.gridApi.setFilterModel(windowstates[0].gridcolfilter);

			}
		}
		catch (e) { }
	}

	resetState = () => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Reset Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId: localStorage.getItem("userid"),
			screenurl: window.location.pathname,
			screentitle: this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
			.then((response) => {
				this.setState({
					show: true,
					basicTitle: "Successfully reset default grid layout",
					basicType: "success"
				})
			})

	};

	dateValidation = (currentDate) => {
		return currentDate.isBefore(moment(this.state.deliverymaxDate)) && currentDate.isAfter(moment(this.state.deliveryminDate));
	};

	selecetedReason(params) {
		//console.log(params);
		try {
			this.setState({
				selectedReason: params.value
			});
			//console.log(params.value);
			if (params.value == "Others") {
				this.setState({
					displayReasonField: "show-m"
				})
			}
			else {
				this.setState({
					displayReasonField: "show-n"
				})
			}
		} catch (e) { }

	}
	newFilter = (filterType, selOption) => {
		console.log(filterType, selOption)
		this.setState({ [filterType]: selOption });
	}
	reloadPageData = () => {
		window.location.reload();
	}

	onClickTab = tab => {
		const { allRowData } = this.state
		if (tab == 1) {
			this.setState({
				consBtn: "btn-danger",
				missingConsBtn: "btn-default",
				showTab: 'show-m',
				rowData: allRowData
			})
		}
		else {
			try {
				var tpt_Code = JSON.parse(localStorage.getItem("transportercode"))[0]
			} catch (e) {
				var tpt_Code = ""
			}
			var query = {}
			if([undefined,"",null,"undefined","null"].includes(tpt_Code) == false){
				query.tpt_Code = tpt_Code
			}else{
				query.tpt_Code = "T226"
			}
			const dept_Code = JSON.parse((localStorage.getItem('dept_code')))[0]
			const user_type = localStorage.getItem('user_type')
			// console.log('dept_Code', dept_Code)
			if ((dept_Code == 'SNDG' || dept_Code == 'AD' || user_type == 'DCTUSER')) {
				this.setState({
					loadshow: 'show-m',
					overly: 'show-m',
					consBtn: "btn-default",
					missingConsBtn: "btn-danger",
				})
			this.getMissingData(query)
				redirectURL.post("/consignments/getGeoFences").then((response) => {
					var geofencelist = response.data.geofencelist;
					// console.log(geofencelist, "geofencelist");
					var geoFenceArray = [];
					geofencelist.map(function (params) {
						geoFenceArray.push(params.geofence_name);
					});
					this.setState({
						geofencelist: geoFenceArray,
						geoFenceData: geofencelist,
						rowData: []
					});
				}).catch(function (error) {
					console.log("error");
				});
			}
		}

	}

	getMissingData = (query) => {
		redirectURL.post("/dashboard/getMissingConsignments", query).then(resp => {
			var data = resp.data.records
			var tptConformDelivery = resp.data.consignments
			var missing_rake_delivery_point = []
			var missing_last_mile_dispatch_date = []
			var allmissing_Data = [...data , ...tptConformDelivery]

			var records = [...data , ...tptConformDelivery]
			records.map(e =>{
				if (e.rake_delivery_point_reached_on=="" || e.rake_delivery_point_reached_on == undefined){
					missing_rake_delivery_point.push(e)
				}
				if(["Melpakkam Railyard","Shankarpally Railyard","Irugur Railyard","DLIB Railyard","Nidavanda Railyard","Mundra Railyard"].includes(e.rake_delivery_point_name)==true){
					

				}	
				else if( e.rake_loading_point_name != "Detroj Railyard" && e.rake_loading_point_name != undefined && e.rake_loading_point_name != "" && e.rake_loading_point_name != null &&  e.rake_delivery_point_name != "Shankarpally Railyard" &&  e.rake_delivery_point_name != "" &&  e.rake_delivery_point_name != undefined &&  e.rake_delivery_point_name != null&&e.last_mile_dispatch_date != '' && typeof e.last_mile_dispatch_date != 'undefined')
				{
				}
				else{
					missing_last_mile_dispatch_date.push(e)
				}
				
			})
			this.setState({
				rowData: [...resp.data.records, ...resp.data.consignments],
				showTab: 'show-n',
				loadshow: 'show-n',
				overly: 'show-n',
				allmissing_Data:allmissing_Data,
				missing_rake_delivery_point:missing_rake_delivery_point,
				missing_last_mile_dispatch_date:missing_last_mile_dispatch_date,
				consBtn: "is_missing",
				tptConformDelivery:tptConformDelivery,
			})
			
		})
	}

	onSubmitMissingData = (event) => {
		var query = {}
		if (this.state.missingTransporter!= ""){
			query.tpt_Code = this.state.missingTransporter.value
		}
		if (![undefined , "undefined" , "" , null].includes(query.tpt_Code)){
			this.setState({
				loadshow: 'show-m',
				overly: 'show-m',
			})
			this.getMissingData(query)
		}
	}

	onRowCellClick = (param) =>{
		// console.log("onRowCellClick", param)
		if(param.colDef.field === 'updated_by_dct'){
			// console.log("updated_by_dct", param)
			let id = {
				"_id" : param.data._id,				
			}
			let query = {
				// "truck_loading_yard_reached_on":param.data.truck_loading_yard_reached_on,
				// "rake_no" : param.data.rake_no,
				// "rr_number" : param.data.rr_number,
				// "rake_loading_point_name": param.data.rake_loading_point_name,
				// "rake_delivery_point_name" : param.data.rake_delivery_point_name,
				// "last_mile_truck_no": param.data.last_mile_truck_no,
				// "last_mile_dispatch_date" : param.data.last_mile_dispatch_date,
				// "rake_delivery_point_reached_on" : param.data.rake_delivery_point_reached_on,
				// "recent_dealer_reported" : param.data.recent_dealer_reported
			}
			if(param.data.truck_loading_yard_reached_on !== "") query.truck_loading_yard_reached_on = param.data.truck_loading_yard_reached_on
			if(param.data.rake_no !== "") query.rake_no = param.data.rake_no
			if(param.data.rr_number !== "" ) query.rr_number = param.data.rr_number
			if(param.data.rake_loading_point_name !== "") query.rake_loading_point_name = param.data.rake_loading_point_name
			if(param.data.rake_delivery_point_name  !== "") query.rake_delivery_point_name = param.data.rake_delivery_point_name
			if(param.data.last_mile_truck_no !== "") query.last_mile_truck_no = param.data.last_mile_truck_no
			if(param.data.last_mile_dispatch_date !== "") query.last_mile_dispatch_date = param.data.last_mile_dispatch_date
			if(param.data.rake_delivery_point_reached_on !== "") query.rake_delivery_point_reached_on = param.data.rake_delivery_point_reached_on
			if(param.data.recent_dealer_reported !== "") query.recent_dealer_reported = param.data.recent_dealer_reported
			
			redirectURL.post("/consignments/updateDelayedRailInfoForDCT", {id,query}).then(res =>{
				// console.log("res", res.data)
				this.setState({
					show : true,
					basicTitle : 'Successfully updated',
					basicType : 'success'
				})
			})
		}
	}

	reloadPageData1 = (pageUrl)=>{
		this.props.history.push(pageUrl);       
    }

	render() {

		let sdeliveredoptions = [
			{ value: "GPS Data Not Available", label: "GPS Data Not Available" },
			{ value: "Consignee location not updated", label: "Consignee location not updated" },
			{ value: "Consignment unloaded at different/secondary location", label: "Consignment unloaded at different/secondary location" },
			{ value: "Transshipment", label: "Transshipment" },
			{ value: "Others", label: "Others" },

		];

		const modalStyles = {
			width: '1300px !important',
		}
		const { open,openforceclose,delivermodal} = this.state;

		var maptransporter = '';
		try {
			if (this.state.rownode.transporter) {
				///console.log("map rownode ",this.state.rownode.transporter[0])
				maptransporter = this.state.rownode.transporter[0].transporter_name;
			}
		}
		catch (e) {
			console.log(e)
		}
		//console.log("Rendere ",this.state.deptcode)
		let transitTimeHeading = (this.state.deptcode === "LOG-TNP") ? "Transit Time (Hours)" : "Transit Time (Days)";
		let isHiddenLocalMovement = (this.state.deptcode === "LOG-PRT") ? false : true;
		//console.log("isHiddenLocalMovement = ", isHiddenLocalMovement, this.state.deptcode);
		let hideonlyPRT = (this.state.deptcode === "LOG-PRT") ? true : false;
		let hideonlyTNP = (this.state.deptcode === "LOG-TNP") ? true : false;
		let hideTNPconsignment = (this.state.deptcode === "LOG-TNP") ? false : true;
		//let hideOnlyActive = 
		let hideonlyCol = false;

		let showOnlyCol = (this.props.match.path == "/railconsignments" && localStorage.getItem("user_type") == "TRANSPORTER") ? true : false;

		let hideOnlyColDeliver = (this.props.match.path == "/railconsignments") ? false : true;
		let showOnlyColDeliver = true;
		let showOnlyDeliver = (this.props.match.path == "/deliveredrailconsignments") ? false : true;
		const isDct = localStorage.getItem('username') == "Rishi Gaurshiway"
		const isDeliverConsignments = this.props.match.path == "/deliveredrailconsignments"

		let isDctEditable = false

		if(isDct && isDeliverConsignments){
			isDctEditable = true
		}
		// console.log('isDctEditable',isDctEditable)
		let showonlyactivencr = true;
		var showOnlyTranporter = (localStorage.getItem("user_type") == "TRANSPORTER") ? false : true;
		let invoicedisable = true;
		let hidecritical = (this.props.match.path == "/railconsignments") ? false : true;
		let showOnlyColapartFromTransporter = (this.props.match.path == "/railconsignments" && (localStorage.getItem("user_type") !== "TRANSPORTER")) ? true : false;

		var pagepath = this.props.match.path;

		let columnwithDefs = [
			// {
			// 	headerName: "Actions",
			// 	field: "_id",
			// 	colId: "_id",
			// 	width: 40,
			// 	pinned: 'left',
			// 	filter: false,resizable: true,
			// 	headerCheckboxSelection: false,
			// 	//headerCheckboxSelectionFilteredOnly: true,
			// 	checkboxSelection: true

			// },
			{
				headerName: "",
				field: "_id",
				colId: "_id",
				width: 50,
				pinned: 'left',
				cellRenderer: 'consignmentActions',
				filter: true,
				resizable: true
			},
			{
				headerName: "",
				field: "status",
				width: 40,
				pinned: 'left',
				pivot: false,
				hide: hideOnlyColDeliver,
				cellRendererSelector: function (params) {

					var rendComponent = {
						component: 'intransitToLoadingYard'
					};
					return rendComponent

				},
				filter: true, resizable: true
			},
			{
				headerName: "",
				field: "status",
				width: 40,
				pinned: 'left',
				pivot: false,
				hide: hideOnlyColDeliver,
				cellRendererSelector: function (params) {
					var rendComponent = {
						component: 'rakeattach'
					};
					return rendComponent

				},
				filter: true, resizable: true
			},
			{
				headerName: "",
				field: "status",
				width: 40,
				pinned: 'left',
				pivot: false,
				hide: hideOnlyColDeliver,
				cellRendererSelector: function (params) {
					var rendComponent = {
						component: 'atDestination'
					};
					return rendComponent

				},
				filter: true, resizable: true
			},
			{
				headerName: "",
				field: "status",
				width: 40,
				pinned: 'left',
				pivot: false,
				hide: hideOnlyColDeliver,
				cellRendererSelector: function (params) {
					var rendComponent = {
						component: 'deliverRail'
					};
					return rendComponent

				},
				filter: true, resizable: true
			},

			{
				headerName: "Consignment Code",
				field: "consignment_code",
				width: 120,
				pinned: 'left',
				editable: false,
				filter: "agSetColumnFilter", resizable: true
				//cellRenderer: "agGroupCellRenderer"
			},
			{
				headerName: "VIN No(s)",
				field: "vin_list",
				width: 120,
				pinned: "left",
				editable: false,
				resizable: true
			},

			{
				headerName: "Sales Type",
				field: "sales_type",
				width: 100,
				pinned: "left",
				editable: false,
				resizable: true
			},

			{
				headerName: "Region",
				field: "region",
				width: 80,
				pinned: "left",
				editable: false,
				resizable: true
			},

			{
				headerName: "Arean Vin(s)",
				field: "arena_list",
				width: 120,
				pinned: "left",
				editable: false,
				resizable: true
			},
			// {
			// 	headerName: "Nexa Vin(s)",
			// 	field: "nexa_list",
			// 	width: 120,
			// 	pinned: "left",
			// 	editable: false,
			// 	resizable: true
			// },
			// {
			// 	headerName: "SBVH Code",
			// 	field: "sbvh",
			// 	width: 120,
			// 	pinned: "left",
			// 	editable: false,
			// 	resizable: true
			// },
			{
				headerName: "Status",
				field: "status",
				width: 180,
				pinned: 'left',
				filter: "agSetColumnFilter",
				resizable: true,
				valueGetter: function (params) {
					//console.log("rake no",params.data.rake_no);
					//console.log("GPS ", params.data.gps_data_available);
					if (params.data.status == 2) {
						return "In Transit to Loading Yard"
					}
					else if (params.data.status == 3 && params.data.rake_no != undefined) {
						return "Rake Assigned"
					}
					else if (params.data.status == 3 && params.data.rake_no == undefined) {
						return "At Loading Yard"
					}
					else if (params.data.status == 4) {
						return "In Transit To Destination Yard"
					}

					else if (params.data.status == 5) {
						return "At Destination Yard"
					}
					else if (params.data.status == 6) {
						return "Force Closed"
					}
					else if (params.data.status == 7) {
						return "In Transit To Destination Dealer"
					}
					else if (params.data.status == 8) {
						return "Near Destination"
					}
					else if (params.data.status == 9) {
						return "Reached Destination"
					}
					else if (params.data.status == 10) {
						return "Left Destination"
					}
					else {
						return ""
					}
				}
			},

			{
				headerName: "Expected ETA",
				field: "eta_rail_final_destination",
				width: 120,
				editable: false,
				filter: "agSetColumnFilter", resizable: true,
				valueGetter: function (params) {
					try {
						return getDDMMMYYYYHHMMDefault(params.data.eta_rail_final_destination)
					}
					catch (e) {
						return "";
					}
				}
				//cellRenderer: "agGroupCellRenderer"
			},
			{
				headerName: "Revised ETA",
				field: "revised_eta_rail_final_destination",
				width: 120,
				editable: false,
				filter: "agSetColumnFilter", resizable: true,
				valueGetter: function (params) {
					try {
						return getDDMMMYYYYHHMMDefault(params.data.revised_eta_rail_final_destination)
					}
					catch (e) {
						return "";
					}
				},
				cellClass: function (params) {
					let hourDifference = 0;
					let revisedDateOnlyMoment = 0
					let expectedDateOnlyMoment = 0
					if (params.data.revised_eta_rail_final_destination && params.data.eta_rail_final_destination) {
						revisedDateOnlyMoment = moment(params.data.revised_eta_rail_final_destination);
						expectedDateOnlyMoment = moment(params.data.eta_rail_final_destination);
						hourDifference = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'hours');
						//console.log("diff= ", params.data.consignment_code, hourDifference);
					} else {
						//console.log("ELSE = ", params.data.revised_trip, params.data.expected_trip_end);
					}

					if (revisedDateOnlyMoment > expectedDateOnlyMoment) {
						return 'bgColorDangerMedium'
					}

					if (revisedDateOnlyMoment < expectedDateOnlyMoment) {
						return 'bgColorSuccessMedium'
					}
					else {
						return ''
					}
				}
				//cellRenderer: "agGroupCellRenderer"
			},

			{
				headerName: "Transit delay (Days)",
				field: "_id",
				width: 120,
				editable: false,
				filter: "agSetColumnFilter", resizable: true,
				valueGetter: function (params) {
					var expected = params.data.eta_rail_final_destination;
					if (params.data.revised_eta_rail_final_destination == '' || params.data.revised_eta_rail_final_destination == undefined) {
						var revised = params.data.eta_rail_final_destination;
					}
					else {
						var revised = params.data.revised_eta_rail_final_destination;
					}
					//console.log("Exp ", expected)
					//console.log("revised ", revised)
					var s = moment.parseZone(expected).format("x");
					var ed = moment.parseZone(revised).format("x");
					//console.log("Exp ", s)
					//console.log("revised ", ed)
					var diff = (ed - s)
					// console.log("Diff",diff/1000)
					var days = secondsToDays(diff)
					//console.log("Days ", Math.round(days/1000))
					var totaldays = Math.round(days / 1000);
					if (totaldays > 0) {
						return totaldays;
					}
					else {
						return 0;
					}
					//return Math.round(days/1000);

				}
			},

			{
				headerName: "Delivered?",
				field: "tpt_confirm_delivery",
				width: 120,
				hide: showOnlyTranporter,
				//checkboxSelection: true,
				cellRendererSelector: function (params) {
					if (params.data.tpt_confirm_delivery == 1) {
						return '';
					}
					else {
						var rendComponent = {
							component: 'deliverconsignment'
						};
						return rendComponent

					}

				},
				valueGetter: function (params) {
					if (params.data.tpt_confirm_delivery == 1) {
						//console.log("tpt_delivery_date ",params.data.tpt_delivery_date);
						return getHyphenDDMMMYYYYHHMM(params.data.tpt_delivery_date)
					}
					else {
						return '';
					}
				},
				filter: false,
				resizable: true

			},
			{
				headerName: "<i class='icofont icofont-truck'></i> MSIL to Loading Yard",
				headerClass: ["cellstylegrid", "cellTopHeader"],
				children: [

					{
						headerName: "Cluster",
						field: "cluster",
						width: 100,
						filter: "agSetColumnFilter",
						resizable: true,

					},
					{
						headerName: "Truck No",
						field: "truck_no",
						width: 100,
						filter: "agSetColumnFilter",
						resizable: true
					},
					{
						headerName: "Transshipment",
						field: "is_transshipment",
						width: 100,
						filter: "agSetColumnFilter",
						valueGetter: function (params) {
							if (params.data.is_transshipment == 1) {
								return "Yes";
							}
							else {
								return "No";
							}
						},
						resizable: true
					},
					{
						headerName: "Old Truck No",
						field: "old_truck_no",
						width: 100,
						filter: "agSetColumnFilter",
						resizable: true
					},
					{
						headerName: "Transporter Name",
						field: "transporter_name",

						width: 150,
						filter: "agSetColumnFilter",
						resizable: true
					},

					{
						headerName: "Last City/Town",
						field: "truck",
						width: 150,
						valueGetter: function (params) {
							//console.log(params.data.truck);
							if (params.data.hasOwnProperty('truck')) {
								if (params.data.truck.length > 0) {
									return params.data.truck[0].area;
								}
							}

						},
						filter: "agSetColumnFilter",
						resizable: true
					},
					{
						headerName: "Dealer Delivery Date",
						field: "recent_dealer_reported",
						width: 120,
						
						editable: false,
						resizable: true,
						valueGetter: function (params) {
							try {
								return getDDMMMYYYYHHMMDefault(params.data.recent_dealer_reported)
							}
							catch (e) {
								return "";
							}
						}
					},
					{
						headerName: "Last State",
						field: "truck",
						width: 120,
						filter: "agSetColumnFilter",
						resizable: true,
						valueGetter: function (params) {
							//console.log(params.data.truck);
							if (params.data.hasOwnProperty('truck')) {
								if (params.data.truck.length > 0) {
									return params.data.truck[0].state;
								}
							}

						}
					},

					{
						headerName: "Last Packet Time",
						field: "truck",
						valueGetter: function (params) {
							//console.log(params.data.transporter);
							if (params.data.hasOwnProperty('truck')) {
								if (params.data.truck.length > 0) {
									return getHyphenDDMMMYYYYHHMM(params.data.truck[0].timestamp);
								}
							}

						},
						comparator: dateComparator,

						width: 150,
						//filter: "agDateColumnFilter",
						resizable: true
					},
					{
						headerName: "GPS Provider",
						field: "truck",
						width: 150,
						filter: "agSetColumnFilter",
						resizable: true,
						valueGetter: function (params) {
							//console.log(params.data.transporter);
							if (params.data.hasOwnProperty('truck')) {
								if (params.data.truck.length > 0) {
									return params.data.truck[0].actual_lspuser;
								}
							}

						},
					},
					{
						headerName: "Invoice Date",
						field: "invoice_time",
						width: 150,
						//filter: "agDateColumnFilter",
						resizable: true,
						hide: hideonlyPRT,
						comparator: dateComparator,

						valueGetter: function (params) {
							if (params.data.invoice_time != '' && params.data.invoice_time != undefined) {
								return getHyphenDDMMMYYYYHHMM(params.data.invoice_time);
							}
							else {
								return "NA";
							}
						},
					},
					{
						headerName: "Consigner Code",
						field: "consigner_code",
						width: 150,
						filter: "agSetColumnFilter",
						cellRenderer: '', resizable: true,
						valueGetter: (params) => {
							if (params.data.consigner_code == "BP") {
								return "B"
							}
							return params.data.consigner_code
						},

					},
					{
						headerName: "Consignee Code",
						field: "consignee_code",
						width: 150,
						filter: "agSetColumnFilter",
						resizable: true
					},

					{
						headerName: "Dist. From Destination (km)",
						field: "distance_from_dealer_location",
						width: 150,
						filter: "agSetColumnFilter",
						resizable: true,
						valueGetter: function (params) {
							//console.log("params ", params.data);
							if (typeof params.data.distance_from_dealer_location == 'undefined') {

							}
							else {
								return Math.round(params.data.distance_from_dealer_location);
							}

						},
						hide: showonlyactivencr
					},

					{
						headerName: "Consignee Name",
						field: "consignee_name",
						width: 150,
						hide: hideonlyTNP,
						filter: "agSetColumnFilter",
						resizable: true
					},
					{
						headerName: "Consignee City",
						field: "consignee_city",
						width: 150,
						hide: hideonlyTNP,
						filter: "agSetColumnFilter",
						resizable: true
					},

					{
						headerName: "Gate in Time",
						field: "gate_in_time",
						width: 180,
						//filter: "agDateColumnFilter",
						//cellRenderer:'dateFormater',
						comparator: dateComparator,

						valueGetter: function (params) {
							return getHyphenDDMMMYYYYHHMM(params.data.gate_in_time);
						},
						resizable: true
					},
					{
						headerName: "GPS Gate in Time",
						field: "inside_fence",
						width: 180,

						comparator: dateComparator,

						valueGetter: function (params) {
							return getHyphenDDMMMYYYYHHMM(params.data.inside_fence);
						},
						resizable: true
					},
					{
						headerName: "Gate out Time",
						field: "gate_out_time",
						width: 180,
						//filter: "agDateColumnFilter",
						//cellRenderer:'dateFormater',
						// sortable:true,
						comparator: dateComparator,

						valueGetter: function (params) {
							return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time);
						},
						resizable: true
					},
					{
						headerName: "GPS Gate out Time",
						field: "outside_fence",
						width: 180,
						//filter: "agDateColumnFilter",
						comparator: dateComparator,

						valueGetter: function (params) {
							return getHyphenDDMMMYYYYHHMM(params.data.outside_fence);
						},
						resizable: true
					},

					{
						headerName: "ETA Loading Yard ",
						field: "eta_first_mile",
						width: 140,
						filter: "agSetColumnFilter",
						resizable: true,
						valueGetter: function (params) {
							//console.log("truck_loading_yard_reached_on ", params.data.truck_loading_yard_reached_on)
							if (params.data.eta_first_mile != '' && typeof params.data.eta_first_mile != 'undefined') {
								return getHyphenDDMMMYYYYHHMM(params.data.eta_first_mile);
							}
							else {
								return '';
							}
						}

					},
					{
						headerName: "Loading Yard Reached On ",
						field: "truck_loading_yard_reached_on",
						width: 140,
						filter: "agSetColumnFilter",
						resizable: true,
						editable: () =>{
							if(isDctEditable) return true
							return false
						}, 
						cellEditor: DateEditor,
						valueSetter : (params) =>{		
							// console.log("params.oldValue", params.oldValue)					
							// console.log("param.newValue", (params.newValue))	
							if(params.oldValue !== params.newValue){
								const date = formatDate(params.newValue)
								console.log('truck_loading_yard_reached_on',date)
								params.data.truck_loading_yard_reached_on = date
								//2023-01-08T03:25:06.000Z
								// params.data.truck_loading_yard_reached_on = params.newValue
							}							
						},
						valueGetter: function (params) {
							//console.log("truck_loading_yard_reached_on ", params.data.truck_loading_yard_reached_on)
							if (params.data.truck_loading_yard_reached_on != '' && typeof params.data.truck_loading_yard_reached_on != 'undefined') {
								return getHyphenDDMMMYYYYHHMM(params.data.truck_loading_yard_reached_on);
							}
							else {
								return '';
							}
						},				

					},
					{
						headerName: "FM - CO2 Emission KgCO2e (STD Distance)",
						field: "co2_emission_FM",
						width: 160,
						filter: "agSetColumnFilter",
						hide:showOnlyDeliver,
						resizable: true,
						valueGetter: function (params) {
							if (params.data.co2_emission_FM != ''&& params.data.co2_emission_FM != undefined && params.data.co2_emission_FM != null && params.data.co2_emission_FM > 0 && typeof params.data.co2_emission_FM != 'undefined') {
								return (params.data.co2_emission_FM);
							}
							else {
								return '';
							}
					}
				    }

				]
			},
			{
				headerName: "<i class='icofont icofont-train-line'></i> Loading Yard to Destination Yard",
				headerClass: ["cellstylegridB", "cellTopHeader"],
				children: [
					{
						headerName: "Rake No",
						field: "rake_no",
						width: 140,
						filter: "agSetColumnFilter",
						resizable: true,
						editable: () =>{
							if(isDctEditable) return true
							return false
						}, 
						cellEditor: 'agRichSelectCellEditor',
						cellEditorParams: param =>{		
							let rake_list = []
							this.state.rakeslist.map(e => rake_list.push(e.truck_no))
							return {
								values: rake_list
							}						
						  } ,
						valueSetter : params =>{
							const oldVal = params.oldValue
							const newVal = params.newValue
							// console.log("params_oldVal", params.oldValue)
							// console.log("params_newVal", params.newValue)
							if (oldVal != newVal){
								params.data.rake_no = newVal
							}

						}

					},
					{
						headerName: "MSIL Rake ID",
						field: "reck_number",
						width: 140,
						filter: "agSetColumnFilter",
						resizable: true,

					},
					{
						headerName: "FNR No",
						field: "rr_number",
						widht: 100,
						filter: "agSetColumnFilter",
						editable: () =>{
							if(isDctEditable) return true
							return false
						}, 
						resizable: true,
						valueSetter : param =>{
							const newVal = param.newValue
							const oldVal = param.oldValue
							if(oldVal !== newVal){
								param.data.rr_number = newVal
							}
						}
						// valueGetter:function(params)
						// {
						// 	console.log("rr_number ",params.data)
						// 	return params.data.rr_number;
						// }
					},
					{
						headerName: "Last City/Town",
						field: "rake_no",
						width: 140,
						filter: "agSetColumnFilter",
						resizable: true,
						valueGetter: function (params) {
							if (params.data.hasOwnProperty('raketruck')) {
								if (params.data.raketruck.length > 0) {
									return params.data.raketruck[0].area;
								}
							}
						}

					},
					{
						headerName: "Last State",
						field: "rake_no",
						width: 140,
						filter: "agSetColumnFilter",
						resizable: true,
						valueGetter: function (params) {
							if (params.data.hasOwnProperty('raketruck')) {
								if (params.data.raketruck.length > 0) {
									return params.data.raketruck[0].state;
								}
							}

						},

					},
					{
						headerName: "Last Packet Time",
						field: "rake_no",
						width: 140,
						filter: "agSetColumnFilter",
						resizable: true,
						valueGetter: function (params) {
							//console.log(params.data.transporter);
							if (params.data.hasOwnProperty('raketruck')) {
								if (params.data.raketruck.length > 0) {
									return getHyphenDDMMMYYYYHHMM(params.data.raketruck[0].timestamp);
								}
							}

						},
						//filter: "agDateColumnFilter",
						comparator: dateComparator,

					},
					{
						headerName: "Loading Yard",
						field: "rake_loading_point_name",
						width: 140,
						filter: "agSetColumnFilter",
						resizable: true,
						editable: () =>{
							if(isDctEditable) return true
							return false
						}, 
						cellEditor: 'agRichSelectCellEditor',
						cellEditorParams: param =>{	
							let loading_list = []
							this.state.loadingpoints.map(e => loading_list.push(e.geofence_name))					
							return {
								values: loading_list
								}							
						  } ,
						valueSetter : params =>{
							const oldVal = params.oldValue
							const newVal = params.newValue
							// console.log("params_oldVal", params.oldValue)
							// console.log("params_newVal", params.newValue)
							if (oldVal != newVal){
								params.data.rake_loading_point_name = newVal
							}
		
						}


					},
					{
						headerName: "Destination Yard",
						field: "rake_delivery_point_name",
						width: 140,
						filter: "agSetColumnFilter",
						resizable: true,
						editable: () =>{
							if(isDctEditable) return true
							return false
						}, 
						cellEditor: 'agRichSelectCellEditor',
						cellEditorParams: param =>{		
							let delivery_p_name = []
							this.state.destinationpoints.map(e => delivery_p_name.push(e.geofence_name))					
							return {
								values: delivery_p_name
								}							
						  } ,
						valueSetter : params =>{							
							const oldVal = params.oldValue
							const newVal = params.newValue
							// console.log("params_oldVal", params.oldValue)
							// console.log("params_newVal", params.newValue)
							if (oldVal != newVal){
								params.data.rake_delivery_point_name = newVal
							}
						
						}

					},
					{
						headerName: "TPT Load Dispatch Date/Time ",
						field: "expected_rake_dispatch_date",
						width: 140,
						filter: "agSetColumnFilter",
						resizable: true,
						valueGetter: function (params) {						 
							if (params.data.expected_rake_dispatch_date != '' && typeof params.data.expected_rake_dispatch_date != 'undefined') {
								return getHyphenDDMMMYYYYHHMM(params.data.expected_rake_dispatch_date);
							}
							else {
								return '';
							}
						}

					},
					{
						headerName: "GPS Load Dispatch Date/Time ",
						field: "rake_loading_point_left_on",
						width: 140,
						filter: "agSetColumnFilter",
						resizable: true,
						valueGetter: function (params) {
							// if (params.data.expected_rake_dispatch_date != '' && typeof params.data.expected_rake_dispatch_date != 'undefined') {
							// 	return getHyphenDDMMMYYYYHHMM(params.data.expected_rake_dispatch_date);
							// }
							// else 
							if (params.data.rake_loading_point_left_on != '' && typeof params.data.rake_loading_point_left_on != 'undefined') {
								return getHyphenDDMMMYYYYHHMM(params.data.rake_loading_point_left_on);
							}
							else {
								return '';
							}
						}

					},

					{
						headerName: "ETA Destination Yard ",
						field: "eta_rail_middle_mile",
						width: 140,
						filter: "agSetColumnFilter",
						resizable: true,
						valueGetter: function (params) {
							//console.log("truck_loading_yard_reached_on ", params.data.truck_loading_yard_reached_on)
							if (params.data.eta_rail_middle_mile != '' && typeof params.data.eta_rail_middle_mile != 'undefined') {
								return getHyphenDDMMMYYYYHHMM(params.data.eta_rail_middle_mile);
							}
							else {
								return '';
							}
						}

					},
					{
						headerName: "Destination Yard Reached On ",
						field: "rake_delivery_point_reached_on",
						width: 140,
						filter: "agSetColumnFilter",
						resizable: true,
						editable: () =>{
							if(isDctEditable) return true
							return false
						}, 
						cellEditor: DateEditor,
						valueSetter : (params) =>{		
							// console.log("params.oldValue", params.oldValue)					
							// console.log("param.newValue", (params.newValue))	
							if(params.oldValue !== params.newValue){
								const date = formatDate(params.newValue)
								params.data.rake_delivery_point_reached_on = date
								//2023-01-08T03:25:06.000Z
								// params.data.truck_loading_yard_reached_on = params.newValue
							}							
						},
						valueGetter: function (params) {
							// return params.data.rake_delivery_point_reached_on
							if (params.data.rake_delivery_point_reached_on != '' && typeof params.data.rake_delivery_point_reached_on != 'undefined') {
								return getHyphenDDMMMYYYYHHMM(params.data.rake_delivery_point_reached_on);
							}
							else {
								return '';
							}
						}

					},
					{
						headerName: "RM - CO2 Emission KgCO2e (STD Distance)",
						field: "co2_emission_RM",
						width: 160,
						filter: "agSetColumnFilter",
						hide:showOnlyDeliver,
						resizable: true,
						valueGetter: function (params) {
							if (params.data.co2_emission_RM != '' && params.data.co2_emission_RM != undefined && params.data.co2_emission_RM != null && params.data.co2_emission_RM > 0 && typeof params.data.co2_emission_RM != 'undefined') {
								return (params.data.co2_emission_RM);
							}
							else {
								return '';
							}
						}

					},
				]
			},
			{
				headerName: "Local Movement(Yes/No)",
				field: "local_movement",
				width: 200,
				filter: "agSetColumnFilter",
				resizable: true,
				hide: isHiddenLocalMovement,
				valueGetter: function (params) {
					if (params.data.local_movement == true) {
						return "Yes";
					}
					else {
						return "No";
					}

				}
			},
			{
				headerName: "<i class='icofont icofont-truck'></i> Destination yard to Dealer",
				headerClass: ["cellstylegridB", "cellTopHeader"],
				children: [
					{
						headerName: "Last Mile Dispatch Date ",
						field: "last_mile_dispatch_date",
						width: 140,
						filter: "agSetColumnFilter",
						resizable: true,
						editable: () =>{
							if(isDctEditable) return true
							return false
						}, 
						cellEditor: DateEditor,
						valueSetter : (params) =>{		
							// console.log("params.oldValue", params.oldValue)					
							// console.log("param.newValue", (params.newValue))	
							if(params.oldValue !== params.newValue){
								const date = formatDate(params.newValue)
								params.data.last_mile_dispatch_date = date
								//2023-01-08T03:25:06.000Z
								// params.data.truck_loading_yard_reached_on = params.newValue
							}							
						},
						valueGetter: function (params) {
							if (params.data.last_mile_dispatch_date != '' && typeof params.data.last_mile_dispatch_date != 'undefined') {
								return getHyphenDDMMMYYYYHHMM(params.data.last_mile_dispatch_date);
							}
							else {
								return '';
							}
						}

					},
					{
						headerName: "Last Mile Truck No ",
						field: "last_mile_truck_no",
						width: 140,
						filter: "agSetColumnFilter",
						resizable: true,
						editable: () =>{
							if(isDctEditable) return true
							return false
						}, 
						valueSetter : param =>{
							const newVal = param.newValue
							const oldVal = param.oldValue
							if(oldVal !== newVal){
								param.data.last_mile_truck_no = newVal
							}
						}						

					},
					{
						headerName: "Last Packet Time",
						field: "timestamp",
						width: 140,
						filter: "agSetColumnFilter",
						resizable: true,
						valueGetter: function (params) {
							try {
								if (params.data.lastmiletruck.length > 0) {
									return getHyphenDDMMMYYYYHHMM(params.data.lastmiletruck[0].timestamp);
								}
								else {
									return "";
								}
							} catch (e) { }

						},
						//filter: "agDateColumnFilter",
						comparator: dateComparator,

					},
					{
						headerName: "Last Area/City",
						field: "area",
						width: 140,
						filter: "agSetColumnFilter",
						resizable: true,
						valueGetter: function (params) {
							try {
								//console.log(params.data.lastmiletruck)
								if (params.data.lastmiletruck.length > 0) {
									return params.data.lastmiletruck[0].area;
								}
							} catch (e) { }
						},
					},
					{
						headerName: "Last State",
						field: "state",
						width: 140,
						filter: "agSetColumnFilter",
						resizable: true,
						valueGetter: function (params) {
							try {
								if (params.data.lastmiletruck.length > 0) {
									return params.data.lastmiletruck[0].state;
								}
							} catch (e) { }
						},
					},

					{
						headerName: "ETA Dealer Reached On ",
						field: "eta_rail_last_mile",
						width: 140,
						filter: "agSetColumnFilter",
						resizable: true,
						valueGetter: function (params) {
							//console.log("truck_loading_yard_reached_on ", params.data.truck_loading_yard_reached_on)
							if (params.data.eta_rail_last_mile != '' && typeof params.data.eta_rail_last_mile != 'undefined') {
								return getHyphenDDMMMYYYYHHMM(params.data.eta_rail_last_mile);
							}
							else {
								return '';
							}
						}

					},
					{
						headerName: "Last Mile Dealer Reached On ",
						field: "recent_dealer_reported",
						width: 140,
						filter: "agSetColumnFilter",
						resizable: true,
						editable: () =>{
							if(isDctEditable) return true
							return false
						}, 
						cellEditor: DateEditor,
						valueSetter : (params) =>{		
							// console.log("params.oldValue", params.oldValue)					
							// console.log("param.newValue", (params.newValue))	
							if(params.oldValue !== params.newValue){
								const date = formatDate(params.newValue)
								params.data.recent_dealer_reported = date
								//2023-01-08T03:25:06.000Z
								// params.data.truck_loading_yard_reached_on = params.newValue
							}							
						},
						valueGetter: function (params) {
							try {
								if (params.data.recent_dealer_reported != '' && typeof params.data.recent_dealer_reported != 'undefined') {
									return getHyphenDDMMMYYYYHHMM(params.data.recent_dealer_reported);
								}
								else {
									return '';
								}
							}
							catch (e) {
								return '';
							}


						}

					},
					{
						headerName: "LM - CO2 Emission KgCO2e (STD Distance)",
						field: "co2_emission_LM",
						width: 160,
						filter: "agSetColumnFilter",
						hide:showOnlyDeliver,
						resizable: true,
						valueGetter: function (params) {
							//console.log("truck_loading_yard_reached_on ", params.data.truck_loading_yard_reached_on)
							if (params.data.co2_emission_LM != '' && params.data.co2_emission_LM != undefined && params.data.co2_emission_LM != null && params.data.co2_emission_LM > 0 && typeof params.data.co2_emission_LM != 'undefined') {
								return (params.data.co2_emission_LM);
							}
							else {
								return '';
							}
						}

					},
				]
			},


			{
				headerName: "TVP Gate In",
				field: "tvp_gate_in",
				width: 160,
				filter: "agSetColumnFilter",
				resizable: true,
				hide: showOnlyDeliver,
				valueGetter: function (params) {
					try {
						if (params.data.tvp_gate_in != '1970-01-01 00:00:00.000Z' && params.data.tvp_gate_in != '') {
							return getDDMMMYYYYHHMMDefault(params.data.tvp_gate_in);
						}
						else {
							return '';
						}

					}
					catch (e) {
						return "";
					}
				}
			},

			{
				headerName: "POD Received",
				field: "pod_received",
				width: 100,
				filter: "agSetColumnFilter",
				hide: showOnlyDeliver,
				resizable: true,
				valueGetter: function (params) {
					try {
						if (params.data.pod_received == 1) {
							return "Yes";
						}
						else {
							return "No";
						}
					}
					catch (e) {
						return "No";
					}
				}
			},

			{
				headerName: "POD Date",
				field: "trip_delivery_date",
				width: 100,
				hide: showOnlyDeliver,
				filter: "agSetColumnFilter",
				resizable: true,
				valueGetter: function (params) {
					try {
						if (params.data.trip_delivery_date != '') {
							return getDDMMMYYYYHHMMDefault(params.data.trip_delivery_date);
						}
						else {
							return "";
						}
					} catch (e) {
						return "";
					}

				}
			},


			{
				headerName: "Trip Type",
				field: "trip_type",
				widht: 100,
				hide: hideonlyPRT,
				filter: "agSetColumnFilter",
				resizable: true
			},


			{
				headerName: "TPT Delivered",
				field: "tpt_confirm_delivery",
				width: 120,
				filter: "agSetColumnFilter",
				resizable: true,
				hide: showOnlyCol,
				valueGetter: function (params) {
					try {
						if (params.data.tpt_confirm_delivery == 1) {
							return "Yes";
						}
						else {
							return "No";
						}
					}
					catch (e) {
						return "No";
					}
				}
			},
			{
				headerName: "TPT Delivered On",
				field: "tpt_delivery_date",
				width: 120,
				filter: "agSetColumnFilter",
				resizable: true,
				hide: showOnlyCol,
				valueGetter: function (params) {
					try {
						if (params.data.tpt_delivery_date != "") {
							return getHyphenDDMMMYYYYHHMM(params.data.tpt_delivery_date);
						}
						else {
							return "No";
						}
					}
					catch (e) {
						return "No";
					}
				}
			},
			{
				headerName: "POD and TPT Delivery Diff (Days)",
				field: "pod_tpt_delivery_diff_days",
				width: 160,
				resizable: true,
				hide: showOnlyDeliver,
			},
			{
				headerName: "TPT Delivered Reason",
				field: "tpt_delivery_reason",
				width: 200,
				filter: "agSetColumnFilter",
				resizable: true,
				hide: showOnlyCol,
				valueGetter: function (params) {
					try {
						if (params.data.tpt_delivery_reason != "") {
							return params.data.tpt_delivery_reason;
						}
						else {
							return "";
						}
					}
					catch (e) {
						return "";
					}
				}
			},
			{
				headerName: "TNP Consignment",
				field: "tnpconsignment",
				width: 200,
				filter: "agSetColumnFilter",
				hide: hideTNPconsignment,
				cellEditor: 'agRichSelectCellEditor',
				cellEditorParams: {
					values: [
						'Yes',
						'No'
					],
				},
				editable: true,
				resizable: true,
				valueGetter: function (params) {
					if (params.data.tnpconsignment == 1) {
						return "Yes";
					}
					else {
						return "No";
					}
				}
			},
			{
				headerName: "Vehicle Mode",
				field: "vehicle_mode",
				widht: 100,
				filter: "agSetColumnFilter",
				resizable: true
			},
			{
				headerName: "Items",
				field: "items",
				width: 150,
				filter: "agSetColumnFilter",
				cellRenderer: '', resizable: true
			},
			{
				headerName: "Total Rail - CO2 Emission KgCO2e (STD Distance)",
				filter: "agSetColumnFilter",
				hide:showOnlyDeliver,
				field: "co2_emission_rail",
				width: 200,
				filter: true,resizable: true,
				valueGetter: function (params) {
					if (params.data.co2_emission_rail != undefined && params.data.co2_emission_rail > 0 && params.data.co2_emission_rail != "" && params.data.co2_emission_rail !== null) {
					return (params.data.co2_emission_rail);
					}else
					return "";
				}
			},
			{
				headerName: "Total Road - CO2 Emission KgCO2e (STD Distance)",
				filter: "agSetColumnFilter",
				hide:showOnlyDeliver,
				field: "co2_emission_if_road",
				width: 200,
				filter: true,resizable: true,
				valueGetter: function (params) {
					if (params.data.co2_emission_if_road != undefined && params.data.co2_emission_if_road != "" && params.data.co2_emission_if_road !== null && params.data.co2_emission_if_road > 0) {
					return (params.data.co2_emission_if_road);
					}else
					return "";
				}
			},
			{
				headerName: "Saving KgCO2e",
				filter: "agSetColumnFilter",
				hide:showOnlyDeliver,
				field: "co2_saved",
				width: 180,
				filter: true,resizable: true,
				valueGetter: function (params) {
					if (params.data.co2_saved != undefined && params.data.co2_saved > 0 && params.data.co2_saved != "" && params.data.co2_saved !== null) {
					return (params.data.co2_saved);
					}else
					return "";
				}
			},
			 {
				headerName: "Critical",
				field: "is_critical",
				width: 90,
				hide: hidecritical,
				cellRenderer: function (params) {
					var input = document.createElement('input');
					input.type = "checkbox";
					params.value = 0;
					var status = false;
					if (params.data.is_critical == true) {
						status = true;
						input.checked = true;
						params.value = 1;
					}
					input.addEventListener('click', function (event) {
						//console.log(event);
						params.value = !params.value;
						//console.log(params);
						onSetCritical(params.data, params.value)
					});
					return input;
				},
				filter: false,
				resizable: true

			},
			{
				headerName: "Force Closed By",
				field: "force_closure_performed_by",
				width: 150,
				hide: showOnlyColDeliver,
				filter: false, resizable: true,


			},

			{
				headerName: "Force Closed Reason",
				field: "force_closure_reason",
				width: 200,
				hide: showOnlyColDeliver,

				filter: false, resizable: true,


			},

			{
				headerName: "Force Closed On",
				field: "force_closure_time",
				width: 180,
				hide: showOnlyColDeliver,

				filter: false, resizable: true,
				valueGetter: function (params) {
					return getDDMMYYYYHHMMSS(params.data.force_closure_time)
				}

			},
			{
				headerName: "Update",
				field: "updated_by_dct",
				width:110,
				filter: true,
				hide: isDct,
				resizable: true,
				cellRenderer:function(params){					
					return '<button class="btn btn-danger" style="padding:0 9px">Update</button>';						
				},
				valueSetter : param =>{
					param.data.is_dct_changed = "updated_by_dct"
				}
					   
			},

		];

		if (showOnlyColapartFromTransporter == true) {
			columnwithDefs.push(
				{
					headerName: "Force Close",
					field: "consignment_code",
					width: 80,
					cellRendererSelector: function (params) {
						var rendComponent = {
							component: 'consignmentforceclose'
						};
						return rendComponent;
					},
					filter: false, resizable: true,

				},
			)
		}
		const dStyles = {
			width: this.props.width,
			height: '500px',
			marginLeft: this.props.marginLeft,

		}

		var hText = 'All';
		if (this.state.deptcode == 'SNDG') {
			hText = 'Sales and Dispatch'
			var firstmilurl = "/sndrailtransshipments"
		}
		if (this.state.deptcode == 'LOG-TNP') {
			hText = 'Train and Production'
			var firstmilurl = "/prtrailtransshipments"
		}

		if (this.state.deptcode == 'LOG-PRT') {
			hText = 'Spare Parts'
			var firstmilurl = "/tnprailtransshipments"
		}
		const tripoptoins = [
			{ "value": "all", label: "All" },
			{ "value": "S", label: "Short Haul" },
			{ "value": "L", label: "Long Haul" }
		]
		if (this.state.missingConsBtn == 'btn-danger') {
			columnwithDefs = [
				{
					headerName: "",
					field: "_id",
					colId: "_id",
					width: 50,
					pinned: 'left',
					cellRenderer: 'consignmentActions',
					filter: true,
					resizable: true
				},
				{
					headerName: "",
					field: "status",
					width: 40,
					pinned: 'left',
					pivot: false,
					hide: hideOnlyColDeliver,
					cellRendererSelector: function (params) {

						var rendComponent = {
							component: 'intransitToLoadingYard'
						};
						return rendComponent

					},
					filter: true, resizable: true
				},
				{
					headerName: "",
					field: "status",
					width: 40,
					pinned: 'left',
					pivot: false,
					hide: hideOnlyColDeliver,
					cellRendererSelector: function (params) {
						var rendComponent = {
							component: 'rakeattach'
						};
						return rendComponent

					},
					filter: true, resizable: true
				},
				{
					headerName: "",
					field: "status",
					width: 40,
					pinned: 'left',
					pivot: false,
					hide: hideOnlyColDeliver,
					cellRendererSelector: function (params) {
						var rendComponent = {
							component: 'atDestination'
						};
						return rendComponent

					},
					filter: true, resizable: true
				},
				{
					headerName: "",
					field: "status",
					width: 40,
					pinned: 'left',
					pivot: false,
					hide: hideOnlyColDeliver,
					cellRendererSelector: function (params) {
						var rendComponent = {
							component: 'deliverRail'
						};
						return rendComponent

					},
					filter: true, resizable: true
				},
				{
					headerName: "Consignment Code",
					field: "consignment_code",
					width: 120,
					pinned: 'left',
					editable: false,
					filter: "agSetColumnFilter", resizable: true
					//cellRenderer: "agGroupCellRenderer"
				},
				{
					headerName: "VIN No(s)",
					field: "vin_list",
					width: 120,
					pinned: "left",
					editable: false,
					resizable: true
				},
				{
					headerName: "Status",
					field: "status",
					width: 180,
					pinned: 'left',
					filter: "agSetColumnFilter",
					resizable: true,
					valueGetter: function (params) {
						//console.log("rake no",params.data.rake_no);
						//console.log("GPS ", params.data.gps_data_available);
						if (params.data.status == 2) {
							return "In Transit to Loading Yard"
						}
						else if (params.data.status == 3 && params.data.rake_no != undefined) {
							return "Rake Assigned"
						}
						else if (params.data.status == 3 && params.data.rake_no == undefined) {
							return "At Loading Yard"
						}
						else if (params.data.status == 4) {
							return "In Transit To Destination Yard"
						}

						else if (params.data.status == 5) {
							return "At Destination Yard"
						}
						else if (params.data.status == 6) {
							return "Force Closed"
						}
						else if (params.data.status == 7) {
							return "In Transit To Destination Dealer"
						}
						else if (params.data.status == 8) {
							return "Near Destination"
						}
						else if (params.data.status == 9) {
							return "Reached Destination"
						}
						else if (params.data.status == 10) {
							return "Left Destination"
						}
						else {
							return ""
						}
					}
				},
				{
					headerName: "Transporter Name",
					field: "transporter_name",

					width: 150,
					filter: "agSetColumnFilter",
					resizable: true
				},
				{
					headerName: "Invoice Date",
					field: "invoice_time",
					width: 150,
					//filter: "agDateColumnFilter",
					resizable: true,
					hide: hideonlyPRT,
					comparator: dateComparator,

					valueGetter: function (params) {
						if (params.data.invoice_time != '' && params.data.invoice_time != undefined) {
							return getHyphenDDMMMYYYYHHMM(params.data.invoice_time);
						}
						else {
							return "NA";
						}
					},
				},
				{
					headerName: "Consigner Code",
					field: "consigner_code",
					width: 150,
					filter: "agSetColumnFilter",
					cellRenderer: '', resizable: true,
					valueGetter: (params) => {
						if (params.data.consigner_code == "BP") {
							return "B"
						}
						return params.data.consigner_code
					},

				},
				{
					headerName: "Consignee Code",
					field: "consignee_code",
					width: 150,
					filter: "agSetColumnFilter",
					resizable: true
				},
				{
					headerName: "Consignee Name",
					field: "consignee_name",
					width: 150,
					hide: hideonlyTNP,
					filter: "agSetColumnFilter",
					resizable: true
				},
				{
					headerName: "Consignee City",
					field: "consignee_city",
					width: 150,
					hide: hideonlyTNP,
					filter: "agSetColumnFilter",
					resizable: true
				},
				{
					headerName: "Loading Yard Reached On ",
					field: "truck_loading_yard_reached_on",
					width: 140,
					filter: "agSetColumnFilter",
					resizable: true,
					valueGetter: function (params) {
						//console.log("truck_loading_yard_reached_on ", params.data.truck_loading_yard_reached_on)
						if (params.data.truck_loading_yard_reached_on != '' && typeof params.data.truck_loading_yard_reached_on != 'undefined') {
							return getHyphenDDMMMYYYYHHMM(params.data.truck_loading_yard_reached_on);
						}
						else {
							return '';
						}
					}

				},
				{
					headerName: "Rake No",
					field: "rake_no",
					width: 140,
					filter: "agSetColumnFilter",
					resizable: true,

				},
				{
					headerName: "MSIL Rake ID",
					field: "reck_number",
					width: 140,
					filter: "agSetColumnFilter",
					resizable: true,

				},
				{
					headerName: "FNR No",
					field: "rr_number",
					widht: 100,
					filter: "agSetColumnFilter",
					//
					resizable: true,					
					// valueGetter:function(params)
					// {
					// 	console.log("rr_number ",params.data)
					// 	return params.data.rr_number;
					// }
				},
				{
					headerName: "Loading Yard",
					field: "rake_loading_point_name",
					width: 140,
					filter: "agSetColumnFilter",
					resizable: true,

				},
				{
					headerName: "Destination Yard",
					field: "rake_delivery_point_name",
					width: 140,
					filter: "agSetColumnFilter",
					resizable: true,

				},
				{
					headerName: "Load Dispatch Date/Time ",
					field: "rake_loading_point_left_on",
					width: 140,
					filter: "agSetColumnFilter",
					resizable: true,
					valueGetter: function (params) {
						// if (params.data.expected_rake_dispatch_date != '' && typeof params.data.expected_rake_dispatch_date != 'undefined') {
						// 	return getHyphenDDMMMYYYYHHMM(params.data.expected_rake_dispatch_date);
						// }
						if (params.data.rake_loading_point_left_on != '' && typeof params.data.rake_loading_point_left_on != 'undefined') {
							return getHyphenDDMMMYYYYHHMM(params.data.rake_loading_point_left_on);
						}
						else {
							return '';
						}
					}

				},
				{
					headerName: "Destination Yard Reached On ",
					field: "rake_delivery_point_reached_on",
					width: 140,
					filter: "agSetColumnFilter",
					resizable: true,
					valueGetter: function (params) {
						// return params.data.rake_delivery_point_reached_on
						if (params.data.rake_delivery_point_reached_on != '' && typeof params.data.rake_delivery_point_reached_on != 'undefined') {
							return getHyphenDDMMMYYYYHHMM(params.data.rake_delivery_point_reached_on);
						}
						else {
							return '';
						}
					}

				},
				{
					headerName: "Last Mile Dispatch Date",
					field: "last_mile_dispatch_date",
					width: 140,
					filter: "agSetColumnFilter",
					resizable: true,
					valueGetter: function (params) 
					{	
						// return params.data.rake_delivery_point_reached_on
						if(["Melpakkam Railyard","Shankarpally Railyard","Irugur Railyard","DLIB Railyard","Nidavanda Railyard","Mundra Railyard"].includes(params.data.rake_delivery_point_name)==true){
							if(params.data.rake_loading_point_name == "Detroj Railyard" &&  params.data.rake_delivery_point_name == "Shankarpally Railyard")
							{
								if (params.data.last_mile_dispatch_date != '' && typeof params.data.last_mile_dispatch_date != 'undefined'){
									return getHyphenDDMMMYYYYHHMM(params.data.last_mile_dispatch_date);
								}
							}else{
								return "N/A"
							}

						}	
						else if(params.data.rake_loading_point_name != "Detroj Railyard" && params.data.rake_loading_point_name != undefined && params.data.rake_loading_point_name != "" && params.data.rake_loading_point_name != null &&  params.data.rake_delivery_point_name != "Shankarpally Railyard" &&  params.data.rake_delivery_point_name != "" &&  params.data.rake_delivery_point_name != undefined &&  params.data.rake_delivery_point_name != null)
						{
							if (params.data.last_mile_dispatch_date != '' && typeof params.data.last_mile_dispatch_date != 'undefined'){
								return getHyphenDDMMMYYYYHHMM(params.data.last_mile_dispatch_date);
							}
						}else{
							return ""
						}
					}
				},
			]
		}

		var is_tansporter_code_empty = [undefined, "", "undefined" , null].includes(localStorage.getItem("transportercode")) ? true : false

		return (
			<div className="container-fluid">
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}
				>
				</SweetAlert>
				<SweetAlert
					show={this.state.show1}
					type={this.state.basicType1}
					title={this.state.basicTitle1}
					onConfirm={this.closeAlert1}
				>
				</SweetAlert>
				<div className="row" style={{ marginBottom: "1%" }}>
					<div className="col-xl-12 col-lg-12">
                        <button 
							onClick={
								this.reloadPageData1.bind(this, "/railconsignments")
							} 
							className={"btn "+this.state.activeBtn}
							// style={{
							// 	display: (this.state.activeBtn || this.state.deliveredBtn || this.state.allBtn) ? "inline-flex" : "none"
							// }}
						>
							Active
						</button>
                        <button 
							onClick={
								this.reloadPageData1.bind(this,"/deliveredrailconsignments")
							} 
							className={"btn "+this.state.deliveredBtn}
							// style={{
							// 	display: (this.state.activeBtn || this.state.deliveredBtn || this.state.allBtn) ? "inline-flex" : "none"
							// }}
						>
							Delivered
						</button>
						<button 
							onClick={this.reloadPageData.bind(this)} 
							className={"btn btn-info "+ this.state.showTab}
							style={{ float: "right" }}
						>
							Reset Filters
						</button>
                    </div>
				</div>

				<div style={{padding:"10px",background:"rgb(177 170 170 / 32%)",borderRadius: "4px"}}>

				
				{(this.props.match.path == "/railconsignments") ?
					<div className="button-group" style={{ marginBottom: "1%" }}>
						<button className={"btn " + this.state.consBtn} style={{background:this.state.consBtn == "is_missing"?"#fff":""}} onClick={() => this.onClickTab(1)}>Active Consignments</button>

						<button className={"btn " + this.state.missingConsBtn} onClick={() => this.onClickTab(2)}>Missing Data</button>

					</div>
					: ""}
				
				<div className={"row"}>
					<div className={"col-xl-4 col-lg-4 " + this.state.showTab}>
						<div className=" card">
							<div className="row card-body" style={{ padding: "10px 30px" }}>
								<form className="row" onSubmit={this.getTruckConsignments.bind(this)}>
									<div className="form-group col-xl-12 col-lg-12">
										<label>Select Type</label>
										<Select
											className="border-radius-0"
											isMulti={false}
											id="selectedFilter"
											style={{ borderRadius: "0px" }}
											options={[{ label: "Truck", vaue: "Truck" }, { label: "Consignments", value: "Consignments" }, { label: "Vin", value: "Vin" }]} required />
									</div>
									<div className={"col-xl-12 col-lg-12 form-group"}>
										<label className="reason">Truck / Consignment No / Vin</label>
										<input type="text" name="reason" autoComplete="off" class="form-control" id="inputTruckConsignment" />
									</div>
									<div className="form-group col-xl-12 col-lg-12">
										<button type="submit" className="btn btn-success">Submit</button>
									</div>
								</form>
							</div>
						</div>
					</div>
					<div className={"col-xl-8 col-lg-8 " + this.state.showTab}>
						<div className=" card">
							<div className="row card-body" style={{ padding: "10px 30px" }}>
								{(this.state.deptcode == 'LOG-TNP') ?
									<div className="row col-xl-12 col-lg-12">
										<div className="row col-xl-12 col-lg-12">
											<div className="form-group col-xl-3 col-lg-3">
												<label>Trip Type </label>
												<Select placeholder={"All"} value={this.state.triptype} className="border-radius-0" onChange={this.onSelectTrip.bind(this)} style={{ borderRadius: "0px" }} options={tripoptoins} required />

											</div>

											<div className="form-group col-xl-3 col-lg-3">
												<label>Select Consigner </label>
												<Select placeholder={"All"} closeMenuOnSelect={false} value={this.state.consigner} isMulti="true" className="border-radius-0" onChange={this.changeConsignerArrayHandler.bind(this)} style={{ borderRadius: "0px" }} options={this.selectConsignerOptionsItems()} required />

											</div>

											<div className="col-xl-3 col-lg-3 form-group">
												<label>From Date</label>
												<Datetime value={this.state.startDate}
													disableCloseOnClickOutside={false}
													closeOnSelect={true}
													inputProps={{ placeholder: 'From', name: 'startDate', autoComplete: 'off' }}
													dateFormat="YYYY-MM-DD"
													name="startDate"
													timeFormat={null}
													onChange={this.handlerStartDateTime.bind(this)} />

											</div>

											<div className="col-xl-3 col-lg-3 form-group">
												<label>To Date</label>
												<Datetime value={this.state.endDate}
													disableCloseOnClickOutside={false}
													closeOnSelect={true}
													inputProps={{ placeholder: 'To', name: 'endDate', autoComplete: 'off' }}
													dateFormat="YYYY-MM-DD"
													timeFormat={null}
													onChange={this.handlerEndDateTime.bind(this)} />

											</div>
											<div className="form-group col-xl-12 col-lg-12">
												<button type="button" className="btn btn-success" onClick={this.onClickTripButton.bind(this)}>Submit</button>
											</div>

										</div>
									</div>
									: ""}

								{(this.state.deptcode == 'LOG-PRT' || this.state.deptcode == 'SNDG') ?
									<div className="row col-xl-12 col-lg-12">
										<div className="form-group col-xl-3 col-lg-3">
											<label>Select Consigner </label>
											<Select placeholder={"All"} closeMenuOnSelect={false} value={this.state.consigner} isMulti="true" className="border-radius-0" onChange={this.changeConsignerArrayHandler.bind(this)} style={{ borderRadius: "0px" }} options={this.selectConsignerOptionsItems()} required />

										</div>
										<div className="form-group col-xl-3 col-lg-3">
											<label>Destination Yard </label>
											<Select placeholder={"All"} closeMenuOnSelect={false} value={this.state.destination_yard} isMulti="true" className="border-radius-0" onChange={this.changeDestinationYardArrayHandler.bind(this)} style={{ borderRadius: "0px" }} options={this.state.destinationOptions} required />

										</div>
										<div className="col-xl-3 col-lg-3 form-group">
											<label>From Date</label>
											<Datetime
												value={this.state.startDate}
												disableCloseOnClickOutside={false}
												closeOnSelect={true}
												inputProps={{ placeholder: 'From', name: 'startDate', autoComplete: 'off' }}
												dateFormat="YYYY-MM-DD"
												name="startDate"
												timeFormat={null}
												onChange={this.handlerStartDateTime.bind(this)} />
										</div>

										<div className="col-xl-3 col-lg-3 form-group">
											<label>To Date</label>
											<Datetime
												value={this.state.endDate}
												disableCloseOnClickOutside={false}
												closeOnSelect={true}
												inputProps={{ placeholder: 'To', name: 'endDate', autoComplete: 'off' }}
												dateFormat="YYYY-MM-DD"
												timeFormat={null}
												onChange={this.handlerEndDateTime.bind(this)} />
										</div>

									</div>
									: ""}
								<div className="row col-xl-12 col-lg-12" style={{ marginLeft: "0px", padding: "0px" }}>
									{(this.state.deptcode == 'LOG-PRT' || this.state.deptcode == 'SNDG') ?
										<div className="form-group col-xl-3 col-lg-3">
											<label>Consignment City</label>
											<Select
												className="border-radius-0"
												isMulti={false}
												id="selectedCity"
												style={{ borderRadius: "0px" }}
												value={this.state.flCity}
												onChange={this.newFilter.bind(this, "flCity")}
												options={this.state.filterCities}
											/>
										</div>
										: ""}
									{(this.state.deptcode == 'LOG-PRT' || this.state.deptcode == 'SNDG') ?
										<div className="form-group col-xl-3 col-lg-3">
											<label>Consignment State</label>
											<Select
												className="border-radius-0"
												isMulti={false}
												id="selectedState"
												style={{ borderRadius: "0px" }}
												value={this.state.flState}
												onChange={this.newFilter.bind(this, "flState")}
												options={this.state.filterStates}
											/>
										</div>
										: ""}
									{/* {(this.state.deptcode == 'SNDG')?
								<div className="form-group col-xl-3 col-lg-3">
									<label>Cluster :  </label>
									<Select 
									className="border-radius-0"
									isMulti={false}
									id="selectedCluster"
									style={{borderRadius:"0px"}}
									value={this.state.flCluster}
									onChange={this.newFilter.bind(this,"flCluster")}
									options={this.state.filterClusters}
									/>
								</div>
								:""} */}
									<div className="form-group col-xl-3 col-lg-3">
										<label>Transporter</label>
										<Select
											className="border-radius-0"
											isMulti={false}
											id="selectedTransporter"
											style={{ borderRadius: "0px" }}
											value={this.state.flTransporter}
											onChange={this.newFilter.bind(this, "flTransporter")}
											options={this.state.filterTransporters}
										/>
									</div>
									{/* <div className="form-group col-xl-12 col-lg-12" style={{textAlign:"left"}}>
									<button type="submit"  className="btn btn-success">Submit</button> &nbsp;&nbsp;
									<button type="button" onClick={this.ClearNewFilter.bind(this)} className="btn btn-danger">Clear</button>
								</div> */}
								</div>

								<div className="row col-xl-12 col-lg-12" >
									<div className="form-group col-xl-12 col-lg-12">
										<button type="button" className="btn btn-success" onClick={this.onGetConsigners.bind(this)}>Submit</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{this.props.match.path == "/railconsignments"&& this.state.consBtn  == 'is_missing' && is_tansporter_code_empty ? 
					<div className={"row"}>
					<div className={"col-xl-12 col-lg-12"}>
							<div className="row card-body" style={{ padding: "10px 30px" }}>
								<div className="row col-xl-12 col-lg-12" style={{ marginLeft: "0px", padding: "0px" }}>
									<div className="form-group col-xl-3 col-lg-3">
										<label>Transporter</label>
										<Select
											className="border-radius-0"
											isMulti={false}
											id="missingTransporter"
											style={{ borderRadius: "0px" }}
											value={this.state.missingTransporter}
											onChange={this.newFilter.bind(this, "missingTransporter")}
											options={[{ label: "ADANI LOGISTICS LTD", value: "T226" }, { label: "APL LOGISTICS", value: "T275" }, { label: "IVC LOGISTICS LIMITED", value: "T305" },{ label: "JAINEX PARIWAHAN PVT LTD", value: "T207" },{ label: "JOSHI KONOIKE TPT. & INFRA. PVT LTD", value: "T314" },{ label: "NYK ADANI", value: "T324" },{ label: "ORIENTAL CARRIERS PVT.LTD", value: "T025" },{ label: "R. SAI LOGISTICS INDIA PVT LTD", value: "T268" },]}
										/>
									</div>
									<div className="form-group col-xl-3 col-lg-3">
										<label>&nbsp;&nbsp;</label>
										<div className="form-group col-xl-12 col-lg-12">
										<button type="button" className="btn btn-success" onClick={this.onSubmitMissingData.bind(this)}>Submit</button>
									</div>
									</div>
									
								</div>
							</div>
						
					</div>
				</div>
					:""	
			}

				{(this.props.match.path == "/railconsignments" && this.state.consBtn == 'btn-danger') ?
					<Counters
						context={this}
						deptcode={this.state.deptcode}
						intransittoloadcnt={this.state.intransittoloadcnt}
						rakeattachcnt={this.state.rakeattachcnt}
						rakeasigncnt={this.state.rakeasigncnt}
						intransittodestinatecnt={this.state.intransittodestinatecnt}
						atdestinationcnt={this.state.atdestinationcnt}
						transittodealercnt={this.state.transittodealercnt}
						delivercnt={this.state.delivercnt}
						activeconsignemnt={this.state.activeconsignemnt}
						railtransitdelayscnt={this.state.railtransitdelayscnt}
						lastMileTruckCounters={this.state.lastMileTruckCounters.length}
						userType={this.state.userType}
					/>

					: ""}

			    {this.state.consBtn == 'is_missing' ? 
				 <div className="row">
				 <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
					 <div className="card">
						 <div className="card-body" id="counterCardBody">
							 <div className="crm-numbers pb-0">
								 <div className="row">
									  <div className="col cirlce-d cursorPointer"  onClick={this.onClickCounter.bind(this,"all")}>
										 <span className="f13"><i className="icofont icofont-ui-browser f24 greenfont"></i><br /> Total Consignments<br /><br /></span>
										 <h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.allmissing_Data != '')?this.state.allmissing_Data.length:0}/></span></h4>
									 </div>
									 <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"reached_dealer_yard")}>
										 <span className="f13"><i className="icofont icofont-clock-time f22 txt-secondary"></i><br />Reached Dealer Yard<br /><br /></span>
										 <h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.missing_rake_delivery_point != '')?this.state.missing_rake_delivery_point.length:0}/></span></h4>
										 
									 </div>
									 <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"last_mile_dispatch")}>
										 <span className="f13"><i className="icofont icofont-fast-delivery f22 txt-warning"></i><br />Last Mile Dispatch<br /><br /></span>
										 <h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.missing_last_mile_dispatch_date != '')?this.state.missing_last_mile_dispatch_date.length:0}/></span></h4>	 
									 </div>
									 <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"tpt_confirm_delivery")}>
										 <span className="f13"><i className="icofont icofont-truck-alt txt-danger f22"></i><br />Tpt Conform Delivery<br /><br /></span>
										 <h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.tptConformDelivery != '')?this.state.tptConformDelivery.length:0}/></span></h4>	 
									 </div>
								 </div>
							 </div>
						 </div>
					 </div>
				 </div>
				 </div>:""
				}

				{(this.props.match.path == "/deliveredrailconsignments") ?
					<DeliverCounters
						context={this}
						deptcode={this.state.deptcode}
						intransittoloadcnt={this.state.intransittoloadcnt}
						rakeattachcnt={this.state.rakeattachcnt}
						rakeasigncnt={this.state.rakeasigncnt}
						intransittodestinatecnt={this.state.intransittodestinatecnt}
						atdestinationcnt={this.state.atdestinationcnt}
						transittodealercnt={this.state.transittodealercnt}
						delivercnt={this.state.delivercnt}
						activeconsignemnt={this.state.activeconsignemnt}
						msilpodreceivecnt={this.state.msilpodreceivecnt}
						railtransitdelayscnt={this.state.railtransitdelayscnt}
						podtptdeliverycnt={this.state.podtptdeliverycnt}
						co2_emission_total= {this.state.co2_emission_total}
						co2_saved = {this.state.co2_saved}
					/>

					: ""}
				<div className="row">
					<div className="col-xl-12 col-lg-12">
						<div className="card">
							<div className="card-header">
								<h5>
									<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pagetitle} </span>

								</h5>
							</div>

							<div className="card-body pt-15px" >
								<div className={"row"}>
									{this.state.missingConsBtn == 'btn-danger' ? (
										<div className={"col-xl-12 col-lg-12 mb-10p "} >
											<button className="float-right f15 custom-btn btn-success" onClick={this.onClickShowBulkUploadForMissingMileDispatch}>
											Last mile <br /> bulk upload
											</button>
											<button className="float-right f14 custom-btn btn-danger white" onClick={this.onClickForceMoveDestinationYard.bind(this)} style={{ marginRight: "5px" }}>
												Update Reached <br/> Destination Yard
											</button>
											<button className="float-right custom-btn btn-warning f14" onClick={this.onClickShowBulkDeliverUpload.bind(this)} style={{ marginRight: "5px" }} >Bulk <br /> Mark Delivered</button>
										</div>
									) : ''}
									<div className={"col-xl-12 col-lg-12 mb-10p " + this.state.showTab}>
										<span className="layoutbtns float-right">
											<button className="float-right custom-btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
												<i className="icofont icofont-save"></i> Save <br /> Grid Layout
											</button>
											<button className="float-right custom-btn white btn-info" style={{ marginRight: "5px" }} onClick={this.resetState.bind(this)}>
												<i className="icofont icofont-refresh"></i> Reset <br /> Default Layout
											</button>
										</span>

										{((this.state.transporterURLPath == "'/consignments/transporterrailsndconsignments'") || (this.state.transporterURLPath == '/consignments/transporterrailsndconsignments'))
											? "" :
											<button className="float-right f14 custom-btn btn-primary white" onClick={this.onClickBulkForceClose.bind(this)} style={{ marginRight: "5px" }}>
												Bulk <br /> Force Close
											</button>
										}
										{(localStorage.getItem("user_type") == 'TRANSPORTER') || (localStorage.getItem("user_type") == 'TECHUSER')?
											<button className="float-right custom-btn btn-warning f14" onClick={this.onClickShowBulkDeliverUpload.bind(this)}>Bulk <br /> Mark Delivered</button>
											: ""}
										<a href={"/roadpickup"} className="float-right f14 custom-btn btn-secondary" style={{ marginRight: "5px", fontWeight: "normal", color: "#fff", textAlign: "center" }} >
											Assign <br /> Last Mile Truck
										</a>
										<button className="float-right f14 custom-btn btn-danger white" onClick={this.onClickForceMoveDestinationYard.bind(this)} style={{ marginRight: "5px" }}>
											Force Move to <br /> Destination Yard
										</button>
										<button className="float-right f14 custom-btn btn-warning" onClick={this.onClickShowRakeSidebar.bind(this)} style={{ marginRight: "5px" }}>
											Assign <br /> To Rake
										</button>
										<button className="float-right f14 custom-btn btn-success white" onClick={this.onClickForceMoveLoadingYard.bind(this)} style={{ marginRight: "5px" }}>
											Force Move to <br /> Loading Yard
										</button>
										<a href={firstmilurl} className="float-right f14 custom-btn btn-info" style={{ marginRight: "5px", fontWeight: "normal", color: "#fff", textAlign: "center" }} >
											Change <br /> First Mile Truck
										</a>
										{isDct ? (<button className="float-right f14 custom-btn btn-success white" onClick={this.onClickBulkEditDeliveredConsignments} style={{ marginRight: "5px" }}>
											Bulk Edit <br /> Delivered Consignments
										</button>) : ""}



										{/* <span className="float-right f12" style={{fontWeight:"100"}}>
											<i className="icofont icofont-clock-time f12" style={{color:"#ff0000"}}></i> - In Transit to Loading Yard
											&nbsp;<i className="icofont icofont-social-google-map txt-secondary f12"></i> - Rake Attached
											&nbsp;<i className="fa fa-tachometer txt-success f12"></i> - In Transit To Destination Yard
											&nbsp;<i className="icofont icofont-pause maroon f12"></i> - At Destination Yard
											&nbsp;<i className="icofont icofont-pause maroon f12"></i> - In Transit to Destination Dealer
											&nbsp;<i className="icofont icofont-pause maroon f12"></i> - Delivered
										</span> */}


										{/* <button className="float-left custom-btn btn-warning" onClick={this.onClickShowRakeSidebar.bind(this)}>
											 Assign To Rake
										</button> */}
									</div>
								</div>
								<div id="myGrid" style={{ height: "580px", width: "100%" }} className="ag-theme-balham">
									<AgGridReact
										modules={this.state.modules}
										columnDefs={columnwithDefs}
										defaultColDef={this.state.defaultColDef}
										rowData={this.state.rowData}
										onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
										enableCharts={true}
										enableRangeSelection={true}
										autoGroupColumnDef={this.state.autoGroupColumnDef}
										onGridReady={this.onGridReady}
										onGridState={this.onGridState}
										statusBar={this.state.statusBar}
										sideBar={this.state.sideBar}
										paginationPageSize={this.state.paginationPageSize}
										pagination={true}
										frameworkComponents={this.state.frameworkComponents}
										detailCellRendererParams={this.state.detailCellRendererParams}
										//editType={this.state.editType}
										stopEditingWhenGridLosesFocus={true}
										//floatingFilter={true}
										enableCellChangeFlash={true}
										suppressCellFlash={true}
										rowClassRules={this.state.rowClassRules}
										//onCellClicked={this.onRowClicked.bind(this)}
										gridOptions={{
											context: { componentParent: this }
										}}
										masterDetail={true}
										// onCellEditingStopped={this.onCellUpdateData.bind(this)
										onCellClicked = {this.onRowCellClick}
										rowSelection={this.state.rowSelection}
										onRowSelected={this.onRowSelection.bind(this)}
										suppressRowClickSelection={true}
										overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
									/>
									{/* 
									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
									</div> */}

								</div>
							</div>
						</div>
					</div>

				</div>
				</div>
				{this.state.sliderTranslate != '' ?
					<div className={"slide-r " + (this.state.sliderTranslate)} style={{ overflow: "auto" }}>
						<div className="slide-r-title">
							<h4>
								Timeline #{this.state.timelinedata[0].consignment_code}
								<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onCloseUploadDiv.bind(this)} >X</span>

							</h4>
						</div>
						<div className="slide-r-body" style={{ position: "relative" }}>

							<div className="row col-xl-12 col-lg-12">
								<VerticalTimeline>
									<VerticalTimelineElement
										className="vertical-timeline-element--work"
										contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff', borderTopLeftRadius: "20%", borderBottomRightRadius: "20%" }}
										contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
										date="Basic Information"
										iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}

									>
										<h3 className="vertical-timeline-element-title">Truck No</h3>
										<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].truck_no}</h4>
										<h3 className="vertical-timeline-element-title">Transporter Code</h3>
										<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].transporter_code}</h4>
										<h3 className="vertical-timeline-element-title">Department Code</h3>
										<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].dept_code}</h4>
										<h3 className="vertical-timeline-element-title">Vehicle Mode</h3>
										<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].vehicle_mode}</h4>
										<h3 className="vertical-timeline-element-title">Items</h3>
										<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].items}</h4>
										<h3 className="vertical-timeline-element-title">Distance (Kms)</h3>
										<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].distance_in_km}</h4>

									</VerticalTimelineElement>


									<VerticalTimelineElement
										className="vertical-timeline-element--work"
										date="Gate in time"
										contentStyle={{ background: 'rgb(234, 68, 99)', color: '#fff', borderTopRightRadius: "20%", borderBottomLeftRadius: "20%" }}
										contentArrowStyle={{ borderRight: '7px solid  rgb(243, 33, 33)' }}
										iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}

									>
										<h3 className="vertical-timeline-element-title">MSIL Gate intime</h3>
										<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].gate_in_time}</h4>

										<h3 className="vertical-timeline-element-title">GPS Gate intime</h3>
										<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].inside_fence}</h4>

										<h3 className="vertical-timeline-element-title">Invoice time</h3>
										<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].invoice_time}</h4>

									</VerticalTimelineElement>

									<VerticalTimelineElement
										className="vertical-timeline-element--work"
										date="MSIL Gate out time"
										contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff', borderTopLeftRadius: "20%", borderBottomRightRadius: "20%" }}
										contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
										iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}

									>
										<h3 className="vertical-timeline-element-title">MSIL Gate out time</h3>
										<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].gate_out_time}</h4>

										<h3 className="vertical-timeline-element-title">GPS Gate out time</h3>
										<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].outside_fence}</h4>

									</VerticalTimelineElement>

									<VerticalTimelineElement
										className="vertical-timeline-element--work"
										date="Consignee Code"
										contentStyle={{ background: 'rgb(234, 68, 99)', color: '#fff', borderTopRightRadius: "20%", borderBottomLeftRadius: "20%" }}
										contentArrowStyle={{ borderRight: '7px solid  rgb(243, 33, 33)' }}
										iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}

									>
										<h3 className="vertical-timeline-element-title">Consignee Code</h3>
										<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].consignee_code}</h4>

									</VerticalTimelineElement>

								</VerticalTimeline>

							</div>
						</div>
					</div>
					: ""
				}

				{this.state.sliderRouteTranslate != '' ?
					<div className={"slide-r " + (this.state.sliderRouteTranslate)} style={{ overflow: "auto" }}>
						<div className="slide-r-title">
							<h4>
								{(this.state.consignment_code)}
								<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onCloseRouteDiv.bind(this)} >X</span>

							</h4>
						</div>
						<div className="slide-r-body" style={{ position: "relative" }}>

							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
								<DrawMap context={this} rownode={this.state.rownode} mapFor={"railconsignments"} googleroutes={this.state.googelRoutes} truckno={this.state.maptruckno} dealer={this.state.dealer} mapinfo={this.state.mapinfo} defTransitCoords={this.state.defTransitCoords} />
								{/*
								<div id="map" className="" style={{width:'100%',height:"70vh"}}></div>
								*/}
								<div className="col-xl-12 col-lg-12 n-p-0">
									<div className="crm-numbers pb-0">
										<h3 className="subH">{this.state.sidebarSubHeader}</h3>
										<div className="col-xl-12 col-lg-12 row">
											<div className="col route-block">
												<label className="sidebar-label">Truck No</label>
												<div>{this.state.routeTruck.truck_no}</div>
											</div>
											{(maptransporter != '') ?
												<div className="col route-block">
													<label className="sidebar-label">Transporter</label>
													<div>{(!isEmpty(maptransporter)) ? maptransporter : ""}</div>
												</div>

												: ""}
											<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(this.state.routeTruck.startTime != '') ? getDDMMYYYYHHMMSS(this.state.routeTruck.startTime) : "NA"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(this.state.routeTruck.endTime != '') ? this.state.routeTruck.endTime : "NA"}</div>
											</div>

											<div className="col route-block">
												<label className="sidebar-label">GPS Distance</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details)) ? Math.round(this.state.mapinfo.route_details.distance / 1000) : "0"} Kms</div>
											</div>
											{(this.state.leg_no == 0 || this.state.leg_no == null) ? ""
												:
												<div className="col route-block">
													<label className="sidebar-label">Google Distance</label>
													<div>{(this.state.rownode.actual_google_leg_distance) ? parseInt(this.state.rownode.actual_google_leg_distance) : "0"} Kms</div>
												</div>
											}

											{/*<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.start_time):"-"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.end_time):"-"}</div>
											</div>
										*/}

										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
					: ""
				}
				<div className={"dataLoadpage " + (this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " + (this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>

				<Modal open={open} onClose={this.onCloseModal} styles={modalStyles} classNames={{
					overlay: 'customOverlay',
					modal: 'customModal',
				}}>
					<div className={"row"} style={{ padding: '5px 0px', width: '100%', overflow: 'hidden', zIndex: '9999', }} >
						{(this.state.maprowData != '') ?
							<ConsignmentDrawMap context={this} griddata={this.state.maprowData} consigner_coordinates={this.state.consigner_coordinates} consigneecoords={this.state.consigneecoords} rownode={this.state.rownode} mapFor={"consignment"} />
							: ""}

					</div>
				</Modal>


				<Modal open={openforceclose} onClose={this.onCloseForceCloseModal} styles={modalStyles}>
					<div className={"row"} style={{ padding: '5px 0px', width: '100%', overflow: 'hidden', zIndex: '9999', }} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formForceCloseHandler.bind(this)}>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Reached Dealer Time</label>
								<Datetime value={this.state.reached_dealer_time}
									disableCloseOnClickOutside={false}
									closeOnSelect={true}
									inputProps={{ placeholder: 'Reached Dealer Time', name: 'reached_dealer_time', autoComplete: 'off' }}
									dateFormat="YYYY-MM-DD"
									name="reached_dealer_time"
									onChange={this.handlerReachedDealerTime.bind(this)}
									className="col-xl-6 col-lg-6"
								/>




								<select name="reachedhh" onChange={this.changeHandler.bind(this)} className="col-xl-2 col-lg-2" value={this.state.reachedhh} required>
									<option value="0">HH</option>
									{(() => {
										const options = [];

										for (let h = 0; h < 24; h++) {
											if (h < 10) {
												var hh = "0" + h
											}
											else {
												var hh = h
											}
											options.push(<option value={hh}>{hh}</option>);
										}

										return options;
									})()}
								</select>
								<select name="reachedmm" onChange={this.changeHandler.bind(this)} className="col-xl-2 col-lg-2" value={this.state.reachedmm} required>
									<option value="0">mm</option>
									{(() => {
										const options = [];

										for (let i = 0; i < 60; i++) {
											if (i < 10) {
												var mm = "0" + i
											}
											else {
												var mm = i
											}
											options.push(<option value={mm}>{mm}</option>);
										}

										return options;
									})()}
								</select>
								{/* <select name="reachedss" onChange={this.changeHandler.bind(this)} className="col-xl-2 col-lg-2" value={this.state.reachedss} disabled={invoicedisable}>
								<option value="00">ss</option>
									{(() => {
									const options = [];

									for (let s2 = 0; s2 < 60; s2++) {
										if(s2<10)
										{
											var ss = "0"+s2
										}
										else{
											var ss = s2
										}
										options.push(<option value={ss}>{ss}</option>);
									}

									return options;
									})()}
								</select> */}
							</div>

							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Left Dealer Time</label>
								<Datetime value={this.state.left_dealer_time}
									disableCloseOnClickOutside={false}
									closeOnSelect={true}
									inputProps={{ placeholder: 'Left Dealer Time', name: 'left_dealer_time', autoComplete: 'off' }}
									dateFormat="YYYY-MM-DD"
									timeFormat={false}
									className="col-xl-6 col-lg-6"
									name="left_dealer_time"
									onChange={this.handlerLeftDealerTime.bind(this)}
								/>

								<select name="lefthh" onChange={this.changeHandler.bind(this)} className="col-xl-2 col-lg-2" value={this.state.lefthh} required>
									<option value="0">HH</option>
									{(() => {
										const options = [];

										for (let h = 0; h < 24; h++) {
											if (h < 10) {
												var hh = "0" + h
											}
											else {
												var hh = h
											}
											options.push(<option value={hh}>{hh}</option>);
										}

										return options;
									})()}
								</select>
								<select name="leftmm" onChange={this.changeHandler.bind(this)} className="col-xl-2 col-lg-2" value={this.state.leftmm} required>
									<option value="0">mm</option>
									{(() => {
										const options = [];

										for (let i = 0; i < 60; i++) {
											if (i < 10) {
												var mm = "0" + i
											}
											else {
												var mm = i
											}
											options.push(<option value={mm}>{mm}</option>);
										}

										return options;
									})()}
								</select>

							</div>

							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Reason for closure</label>
								<textarea name="reasonforceclose" onChange={this.changeHandler.bind(this)} rows="5" className="form-control col-xl-12 col-lg-12"></textarea>
							</div>
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>
					</div>
				</Modal>

				<div className={"slide-r " + (this.state.sliderForceCloseTranslate)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							Force Closure
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>

						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>

						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', overflow: 'hidden', zIndex: '9999', }} >
							<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formForceCloseHandler.bind(this)}>
								{/* <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Reached Dealer Time</label>
								
                                <input type="text" placeholder="Reached Dealer " id="reached_dealer_time" className="datetimepicker_mask form-control" required />
							
							</div>

							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Left Dealer Time</label>
								
                                <input type="text" placeholder="Left Dealer " id="left_dealer_time" className="datetimepicker_mask form-control" required />
							
							</div> */}

								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Reason for closure</label>
									<textarea name="reasonforceclose" onChange={this.changeHandler.bind(this)} rows="5" className="form-control col-xl-12 col-lg-12"></textarea>
								</div>
								<div className="col-xl-12 col-lg-12 form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div>
							</form>
						</div>
					</div>
				</div>


				<div className={"slide-r " + (this.state.sliderRakeTranslate)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							Assign To Rake
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>

						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>

						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
							<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formRakeHandler.bind(this)}>

								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Select Rake</label>

									<Select
										placeholder={"Select Rake"}
										closeMenuOnSelect={true}
										onChange={this.onChangeRakeItem.bind(this)}
										className={"col-xl-12 col-lg-12 border-radius-0"}
										style={{ borderRadius: "0px" }}
										options={this.selectrakeoptins()} />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Select Loading Yard</label>

									<Select
										value={this.state.loadpoint}
										placeholder={"Select Loading Yard"}
										closeMenuOnSelect={true}
										onChange={this.onChangeLoadingPointItem.bind(this)}
										className={"col-xl-12 col-lg-12 border-radius-0"}
										style={{ borderRadius: "0px" }}
										options={this.selectloadingpoints()} />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Select Destination Yard</label>

									<Select
										value={this.state.destinationpoint}
										placeholder={"Select Destination Yard"}
										closeMenuOnSelect={true}
										onChange={this.onChangeDestinationPointItem.bind(this)}
										className={"col-xl-12 col-lg-12 border-radius-0"}
										style={{ borderRadius: "0px" }}
										options={this.selectdestinationpoints()} />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Load Dispatch Date</label>

									<input type="text" placeholder="Load Dispatch Date " id="dispatch_Date" name="dispatch_Date" className="datetimepicker_mask form-control" required />

								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">FNR No</label>

									<input type="text" placeholder="FNR No" id="rr_number" name="rr_number" className="form-control" onChange={this.changeHandler} />

								</div>
								<div className="form-group mt-20p">
									<label className="">Upload File</label>
									<input type="file" name="uploadFile" onChange={this.changeFileHandler} className="form-control" required />
								</div>
								<div className="col-xl-12 col-lg-12 form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div>
							</form>

							<div className="col-xl-12 col-lg-12 form-group">
								<a href={require("../../assets/json/sample_rake_upload.csv")} target="_blank" className="btn btn-warning">Download Template</a>
							</div>
							<div className="col-xl-12 col-lg-12 form-group">
								<p style={{ color: "#ff0000", fontSize: "12px;" }}>Note: Dispatch Date should be greater than Gate out time.</p>
							</div>
						</div>
					</div>
				</div>

				<div className={"slide-r " + (this.state.sliderForceDestination)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							Force Move To Destination Yard
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>
						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
							<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formForceMoveDestinationYardHandler.bind(this)}>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Destination Yard Reached On</label>
									<input type="text" placeholder="Destination Yard Reached On " id="dest_yard_reachedon" name="dest_yard_reachedon" className="datetimepicker_mask form-control" required />
								</div>
								<div className="form-group mt-20p">
									<label className="">Upload File</label>
									<input type="file" name="uploadFile" onChange={this.changeFileHandler} className="form-control" required />
								</div>
								<div className="col-xl-12 col-lg-12 form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div>
							</form>

							<div className="col-xl-12 col-lg-12 form-group">
								<a href={require("../../assets/json/sample_rake_upload.csv")} target="_blank" className="btn btn-warning">Download Template</a>
							</div>
						</div>
					</div>
				</div>

				<div className={"slide-r " + (this.state.bulkslideForDispatch)} style={{ overflow: "hidden", width: "30%" }}>
					<h3 className="subH">Bulk Update 
					<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
					</h3>					
					<div className="slide-r-body" style={{ position: "relative" }}>

						<div className="container-fluid">
							<form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkFormHandlerForDispatchDate}>
								<div className="form-group col-xl-12 col-lg-12">
									<label >Select Yard</label>
									<Select
										placeholder={"Select "}
										closeMenuOnSelect={true}
										onChange={this.changeBulkUploadYard.bind(this)}
										className={"border-radius-0"}
										style={{ borderRadius: "0px" }}
										options={this.selectGeofence()} />
								</div>
								<div className={"form-group col-xl-12 col-lg-12"}>
									<label >Dispatch Date</label>
									<input type="text" autoComplete="off" className="col-xl-12 col-lg-12 form-control uploadDispatchDate datetimepicker_date col-xl-7" id="replaced_Date" style={{ height: "41px" }} required />
								</div>
								<div className={"form-group col-xl-12 col-lg-12"}>
									<label >Upload File</label>
									<input type="file" name="uploadFile" id="bulkUploadID" onChange={this.changeFileHandler1} className="form-control col-xl-12 col-lg-12" required />
								</div>
								<div className="form-group" style={{ textAlign: "center" }}>
									<button type="submit" className="btn btn-success">Submit</button>
									<button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
								</div>
							</form>
							<div className="form-group">
								<p style={{ color: "#ff0000" }}>*Note: All fields are mandatory and should not be empty.</p>
							</div>
							<div className="form-group">
								<a className="btn btn-primary" href={require('../../assets/json/sample_bulk_upload_last_mile.csv')} target="_blank">Sample Template</a>
							</div>
						</div>
					</div>
				</div>

				<div className={"slide-r " + (this.state.sliderForceLoadingYard)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							Force Move To Loading Yard
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>
						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
							<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formForceMoveLoadingYardHandler.bind(this)}>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Loading Yard Reached On</label>
									<input type="text" placeholder="Loading Yard Reached On " id="loading_yard_reached_on" name="loading_yard_reached_on" className="datetimepicker_mask form-control" required />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Select Loading Yard</label>

									<Select
										placeholder={"Select Loading Yard"}
										closeMenuOnSelect={true}
										onChange={this.onChangeLoadingYardItem.bind(this)}
										className={"col-xl-12 col-lg-12 border-radius-0"}
										style={{ borderRadius: "0px" }}
										options={this.selectloadingpoints()} />
								</div>
								<div className="form-group mt-20p">
									<label className="">Upload File</label>
									<input type="file" name="uploadFile" onChange={this.changeFileHandler} className="form-control" required />
								</div>
								<div className="col-xl-12 col-lg-12 form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div>
							</form>

							<div className="col-xl-12 col-lg-12 form-group">
								<a href={require("../../assets/json/sample_rake_upload.csv")} target="_blank" className="btn btn-warning">Download Template</a>
							</div>
						</div>
					</div>
				</div>

				{/* sliderBulkEditConsignments */}
				<div className={"slide-r " + (this.state.sliderBulkEditConsignments)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							Bulk Edit Delivered Consignments
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>
						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
							<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onClickBulkEditChanges}>								
								<div className="form-group mt-20p">
									<label className="">Upload File</label>
									<input type="file" name="uploadFile" onChange={this.changeFileHandlerBulkEdit} className="form-control" required />
								</div>								
										<div className='p-2' id="appednCSVData"></div>
								
								<div className="col-xl-12 col-lg-12 form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div>
							</form>
							<p className='pl-3' style={{color : 'red'}}> please format the dates in yyyy-mm-dd hh:mm:ss or dd-mm-yyyy hh:mm:ss before uploading</p>

							<div className="col-xl-12 col-lg-12 form-group">
								<a href={require("../../assets/json/sample_bulk_edit_rail_Deliveredconsignment.csv")} target="_blank" className="btn btn-warning">Download Template</a>
							</div>
						</div>
					</div>
				</div>

				<div className={"slide-r " + (this.state.sliderBulkForceClose)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							Bulk Force Closure
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>
						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
							<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formBulkForceCloseHandler.bind(this)}>
								<div className="form-group mt-20p">
									<label className="">Upload File</label>
									<input type="file" name="uploadFile" onChange={this.changeBulkForceFileHandler.bind(this)} className="form-control" required />
								</div>
								<div className="col-xl-12 col-lg-12 form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div>
							</form>

							<div className="col-xl-12 col-lg-12 form-group">
								<a href={require("../../assets/json/sample_rail_bulk_force_close.csv")} target="_blank" className="btn btn-warning">Download Template</a>
							</div>
						</div>
					</div>
				</div>

				<div className={"slide-r " + (this.state.sliderTranslatesidebar)} >
					{this.displayData}
				</div>

				{/* Bulk Assign Excel Upload */}
				<div className={"slide-r " + (this.state.bulkslide)} style={{ overflow: "hidden" }}>
					<h3 className="subH">Bulk Update</h3>

					<div className="slide-r-body" style={{ position: "relative" }}>

						<div className="container-fluid">
							<form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkFormHandler}>

								<div className="form-group mt-20p">
									<label className="">Upload File</label>
									<input type="file" name="uploadFile" onChange={this.changeFileHandler} className="form-control" required />
								</div>
								<div className="form-group">
									<button type="submit" className="btn btn-success">Submit</button>
									<button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
								</div>
							</form>

							<div className="form-group">
								<a className="btn btn-primary" href={require('../../assets/json/Bulk_tickets_update_file.csv')} target="_blank">Sample Template</a>

							</div>
						</div>
					</div>
				</div>

				<div className={"slide-r " + (this.state.bulkdeliverslide)} style={{ overflow: "hidden" }}>
					<h3 className="subH">Bulk Update</h3>

					<div className="slide-r-body" style={{ position: "relative" }}>

						<div className="container-fluid">
							<form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkDeliverFormHandler}>

								<div className="form-group mt-20p">
									<label className="">Upload File</label>
									<input type="file" name="uploadFile" onChange={this.changeSetDeliverFileHandler} className="form-control" required />
								</div>
								<div className="form-group">
									<button type="submit" className="btn btn-success">Submit</button>
									<button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
								</div>
							</form>

							<div className="form-group">
								<a className="btn btn-primary" href={require('../../assets/json/Bulk_deliver_update_file.csv')} target="_blank">Sample Template</a>
							</div>
							<div className="col-xl-12 col-lg-12 form-group">
								<p style={{ color: "#ff0000", fontSize: "12px;" }}>Note-: Date Format Should be in<br /> dd/mm/yyyy hh:mm (or) dd-mm-yyyy hh:mm</p>
							</div>
						</div>
					</div>
				</div>

				<Modal open={delivermodal} onClose={this.onCloseDeliverModal} styles={{ width: "300px" }}>
					<div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formSetDeliveredDate.bind(this)}>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-4 col-lg-4">Delivery Date</label>
								<Datetime
									value={this.state.deliverdate}
									disableCloseOnClickOutside={false}
									closeOnSelect={true}
									inputProps={{ placeholder: 'Delivery Date', name: 'deliverdate', autoComplete: 'off' }}
									dateFormat="YYYY-MM-DD"
									name="deliverdate"
									className="col-xl-6 col-lg-6"
									isValidDate={this.dateValidation.bind(this)}
									onChange={this.handlerDeliverDateTime.bind(this)}
								/>
							</div>

							<div className="form-group col-xl-12 col-lg-12 row">
								<label className="col-xl-4 col-lg-4">Select Reason</label>
								<Select
									onChange={this.selecetedReason.bind(this)}
									closeMenuOnSelect={true}
									className={"border-radius-0 col-xl-6 col-lg-6"}
									style={{ borderRadius: "0px" }}
									options={sdeliveredoptions} />
							</div>
							<div className={"form-group col-xl-12 col-lg-12 row " + (this.state.displayReasonField)}>
								<label className="col-xl-4 col-lg-4" style={{ float: "left" }}>Reason</label>
								<textarea name="isdeliverreason" value={this.state.isdeliverreason} onChange={this.changeHandler.bind(this)} rows="5" className="form-control col-xl-6 col-lg-6"></textarea>
							</div>

							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>
					</div>
				</Modal>

			</div>

		);
	}
}



function loadScript(url) {
	var index = window.document.getElementsByTagName("script")[0]
	var script = window.document.createElement("script")
	script.src = url
	script.async = true
	script.defer = true
	index.parentNode.insertBefore(script, index)
}
function isEmpty(obj) {
	for (var key in obj) {
		if (obj.hasOwnProperty(key))
			return false;
	}
	return true;
}
function timeConvert(n) {
	// var num = n/1000;
	// var hours = (num / (3600*24));
	// var rhours = Math.floor(hours);
	// var minutes = (n) / (60*60);
	// var rminutes = Math.round(minutes);

	var diffMs = n;
	var diffDays = Math.floor(diffMs / 86400000); // days
	var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
	var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes


	return diffHrs + " hour(s) and " + (diffMins / 60) + " minute(s).";
}
var currentinfowindow = null;
function getTimeInfo(marker, timedet, coords) {
	var timeinfo = new window.google.maps.InfoWindow({
		content: "Time at this location: " + timedet
	});

	marker.addListener('click', function () {

		if (currentinfowindow != null) {
			currentinfowindow.close();
			currentinfowindow = timeinfo;
			timeinfo.open(marker.get('map'), marker);
		}
		else {
			currentinfowindow = timeinfo;
			timeinfo.open(marker.get('map'), marker);
		}

	});
}


function secondsToString(seconds) {
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}
function CheckForHtmlTag() {
	var rs = document.getElementById("invalid_reason");
	var reg = /<(.|\n)*?>/g;
	if (reg.test(rs.value)) {
		var ErrorText = "Oops! HTML or Script is not allowed.";
		rs.value = ''
		//alert();
		return 1;
	}
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
		// console.log(date1,date2);
		var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
		date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
		var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
		date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
		// console.log(date1,date2);
		var date1Number = monthToComparableNumber(date1);
		var date2Number = monthToComparableNumber(date2);
		// console.log(date1Number,date2Number);
		if (date1Number === null && date2Number === null) {
			return 0;
		}
		if (date1Number === null) {
			return -1;
		}
		if (date2Number === null) {
			return 1;
		}
		return date1Number - date2Number;
	}
}
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber, monthNumber, dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName) {
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
	];
	var monthNum = months.indexOf(monthName) + 1;
	if (monthNum < 10) {
		monthNum = "0" + monthNum.toString();
	}
	return monthNum;
}


window.onload = function () {
	// $(".datepicker").datepicker({
	//     dateFormat: "dd-mm-yy",
	//     changeMonth: true,
	//     changeYear: true
	// });
	$('.datetimepicker_mask').datetimepicker({
		mask: '39-19-9999 29:59',
		format: 'd-m-Y H:i'
	});

	$(".styles_modal__gNwvD").css({ width: "450px" })
};
document.addEventListener('turbolinks:before-render', () => {
	loadDateTimeScript()
});

function loadDateTimeScript() {

	$('.datetimepicker_mask').datetimepicker({
		//mask:'39-19-9999 29:59',
		format: 'd-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		//mask:'39-19-9999',
		format: 'd-m-Y',
		timepicker: false
	});
	var index = window.document.getElementsByTagName("script")[1]
	var script = window.document.createElement("script")
	script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	script.async = true
	script.defer = true
	index.parentNode.insertBefore(script, index)
}


function GetSortDescOrder(prop) {
	return function (a, b) {
		if (a[prop] < b[prop]) {
			return 1;
		} else if (a[prop] > b[prop]) {
			return -1;
		}
		return 0;
	}
}


function GetSortAscOrder(prop) {
	return function (a, b) {
		if (a[prop] > b[prop]) {
			return 1;
		} else if (a[prop] < b[prop]) {
			return -1;
		}
		return 0;
	}
}

function secondsToDays(seconds) {
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays;
}

function onSetCritical(params, status) {
	//console.log("onSetCritical ", params);
	//console.log("onSetCritical value ", status);
	redirectURL.post('/consignments/setCriticalStatus', {
		params: params,
		is_critical: status,
		token: window.atob(localStorage.getItem('_t')),
		userId: localStorage.getItem("userid")
	})
		.then((response) => {

			//var records = JSON.parse(JSON.stringify(response)).data;
			console.log(response.data);
		});

}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    // console.log(params, "params")
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};


function formatDate(args){
	let date_f = args.replaceAll("/", "-").split(" ")
	return `${date_f[0]}T${date_f[1]}:00.000Z`
}
function convertDateToDesiredFormat(dateString) {
	const parsedDate = new Date(dateString);
	console.log(parsedDate, 'parsedDate', dateString);
	if (isNaN(parsedDate.getTime())) {
	  // Date is not valid, handle it here (e.g., return it as is or provide a default).
	  return dateString; // Return the original date string if it's not valid.
	} else {
	  const month = (parsedDate.getMonth() + 1).toString().padStart(2, '0');
	  const day = parsedDate.getDate().toString().padStart(2, '0');
	  const year = parsedDate.getFullYear();
	  const hours = parsedDate.getHours().toString().padStart(2, '0');
	  const minutes = parsedDate.getMinutes().toString().padStart(2, '0');
	  
	  if (parsedDate.getDate() < 12) {
		return `${month}-${day}-${year} ${hours}:${minutes}`;
	  } else {
		return `${day}-${month}-${year} ${hours}:${minutes}`;
	  }
	}
  }
  

  